import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import VerifyImg from "../../../asserts/IMAGES/verify-image.png"
import Twitter from "../../../asserts/IMAGES/twitter-color-icon.svg"
import Youtube from "../../../asserts/IMAGES/youtube-color-icon.svg"
import Discord from "../../../asserts/IMAGES/discord-color-icon.svg"
import Facebook from "../../../asserts/IMAGES/facebook-color-icon.svg"
import Instagram from "../../../asserts/IMAGES/instagram-color-icon.svg"
import Telegram from "../../../asserts/IMAGES/telegram-color-icon.svg"
import Website from "../../../asserts/IMAGES/website-icon.svg"
import Compress from "react-image-file-resizer";
import { ToastContainer, Zoom, toast} from 'react-toastify';
import { useEffect, useState } from "react";
import { putbgImagebywallet, putbgImagebywalletNew, getuserDetailsbywallet, getuserDetailsbywalletNew } from "../../../awsdatafile";
import { CircularProgress } from "@mui/material";
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { getuserDetailsbywalletNewFirebase, putbgImagebywalletNewFirebase } from "../../../FirebaseFunctions";

export default function ProfileSetting() {
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [mail, setMail] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [getProfile, setGetProfile] = useState([]);
    const [description, setDescription] = useState('');
    const [twitter, setTwitter] = useState('');
    const [youtube, setYoutube] = useState('');
    const [discord, setDiscord] = useState('');
    const [website, setWebsite] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [telegram, setTelegram] = useState('');
    const [profileDetails, setProfileDetails] = useState(false);
    const [img, setImg] = useState('');
    const [imgName, setImgName] = useState('');
    const [file, setFile] = useState('');
    const [coverImage, setCoverImage] = useState();

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleUserNameChange = (event) => {
        setUserName(event.target.value);
    };

    const handleMailChange = (event) => {
        setMail(event.target.value);
    };

    const handleWalletAddressChange = (event) => {
        setWalletAddress(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleTwitterChange = (event) => {
        setTwitter(event.target.value);
    };

    const handleYoutubeChange = (event) => {
        setYoutube(event.target.value);
    };

    const handleDiscordChange = (event) => {
        setDiscord(event.target.value);
    };

    const handleWebsiteChange = (event) => {
        setWebsite(event.target.value);
    };

    const handleFacebookChange = (event) => {
        setFacebook(event.target.value);
    };

    const handleInstagramChange = (event) => {
        setInstagram(event.target.value);
    };

    const handleTelegramChange = (event) => {
        setTelegram(event.target.value);
    };

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const editProfile = async () => {
        // let getProfileUpdate = await getuserDetailsbywalletNew(address);
        // let coverImage = getProfileUpdate?.data2[0]?.coverImagePath;
        // let profileImage = getProfileUpdate?.data2[0]?.profileImagePath;
        let getProfileUpdate = await getuserDetailsbywalletNewFirebase(address);
        let coverImage = getProfileUpdate[0]?.coverImagePath;
        let profileImage = getProfileUpdate[0]?.profileImagePath;
        // let coverImage = "https://gateway.pinata.cloud/ipfs/Qmdez1SZp2VvYfoJNVo6K8cNjer3HBUp51PivC7Cp5dLBU";
        // let profileImage = "https://gateway.pinata.cloud/ipfs/QmdLCS6mfnfxvhy9TKZ7h3MCNAJ6y3piz2M7MKMhp2zvej"
        // let apiProfileUpdate = await putbgImagebywalletNew(firstName, lastName, userName, walletAddress, description, twitter, discord, youtube, website, coverImage, profileImage);
        let apiProfileUpdate = await putbgImagebywalletNewFirebase(firstName, lastName, userName, walletAddress, description, twitter, discord, youtube, website, coverImage, profileImage);
        if(apiProfileUpdate)
        {
            toast.success("Profile Update Successful");
            await sleep(5000);
            setProfileDetails(false);
        }
        else
        {
            toast.error("Profile Update Unsuccessful");
        }
    }

    const getProfileDetails = async () => {
        // let getProfileUpdate = await getuserDetailsbywalletNew(address);
        // console.log("get", getProfileUpdate);
        // setFirstName(getProfileUpdate?.data2[0]?.firstName);
        // setLastName(getProfileUpdate?.data2[0]?.lastName);
        // setUserName(getProfileUpdate?.data2[0]?.userName);
        // setWalletAddress(getProfileUpdate?.data2[0]?.walletAddress);
        // setDescription(getProfileUpdate?.data2[0]?.description);
        // setTwitter(getProfileUpdate?.data2[0]?.twitter);
        // setDiscord(getProfileUpdate?.data2[0]?.discord);
        // setYoutube(getProfileUpdate?.data2[0]?.youtube);
        // setWebsite(getProfileUpdate?.data2[0]?.website);
        // setCoverImage(getProfileUpdate?.data2[0]?.coverImagePath);
        let getProfileUpdate = await getuserDetailsbywalletNewFirebase(address);
        console.log("get", getProfileUpdate);
        setFirstName(getProfileUpdate[0]?.firstName);
        setLastName(getProfileUpdate[0]?.lastName);
        setUserName(getProfileUpdate[0]?.userName);
        setWalletAddress(getProfileUpdate[0]?.walletAddress);
        setDescription(getProfileUpdate[0]?.description);
        setTwitter(getProfileUpdate[0]?.twitter);
        setDiscord(getProfileUpdate[0]?.discord);
        setYoutube(getProfileUpdate[0]?.youtube);
        setWebsite(getProfileUpdate[0]?.website);
        setCoverImage(getProfileUpdate[0]?.coverImagePath);
    }

    useEffect(() => {
        if(!(firstName && lastName && userName && walletAddress && description && twitter && discord && youtube && website))
            getProfileDetails();
    },[address, isConnected])

    const editProfileToggle = () => {
        setProfileDetails(true);
    }

    const cancelEditProfileToggle = () => {
        getProfileDetails();
        setProfileDetails(false);
    }

    return(
        <>
        <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
            <h4 className="text-20 text-500 mb-20">Profile Settings</h4>
            {!coverImage ? <>
                <center><CircularProgress color="inherit" /></center>
            </> : <>
                {profileDetails ? <>
                    <Row className="g-4">
                    <Col md={9}>
                        <Row className="g-md-3 mb-40 g-2">
                            <Col xs={6}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="First name"
                                >
                                    <Form.Control 
                                        type="text" 
                                        value={firstName} 
                                        placeholder=" " 
                                        onChange = {handleFirstNameChange}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Last name"
                                >
                                    <Form.Control 
                                        type="text" 
                                        value={lastName} 
                                        placeholder=" "
                                        onChange={handleLastNameChange}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Username"
                                >
                                    <Form.Control 
                                        type="text" 
                                        value={userName} 
                                        placeholder=" " 
                                        onChange={handleUserNameChange}
                                    />
                                </FloatingLabel>
                            </Col>
                            {/* <Col xs={12}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Email"
                                >
                                    <Form.Control 
                                        type="email" 
                                        value={mail} 
                                        placeholder=" "
                                        onChange={handleMailChange} 
                                    />
                                </FloatingLabel>
                            </Col> */}
                            <Col xs={12}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Wallet Address"
                                >
                                    <Form.Control 
                                        type="text" 
                                        value={walletAddress} 
                                        placeholder=" "
                                        disabled
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Tell about yourself in a few words"
                                >
                                    <Form.Control 
                                        as="textarea" rows={6} 
                                        placeholder=" " 
                                        onChange={handleDescriptionChange}
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <h4 className="text-20 text-500 mb-20">Social Links</h4>

                        <Row className="g-md-3 mb-40 g-2" xs={1} sm={2}>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Twitter</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Twitter} alt="Twitter" className="me-2 d-block" />
                                        </span>
                                        {/* <span className="opacity-50">@username</span> */}
                                        <input  className="opacity-50" type="text" value={twitter} placeholder="@username" onChange={handleTwitterChange}/>
                                    </div>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Youtube</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Youtube} alt="Youtube" className="me-2 d-block" />
                                        </span>
                                        <input  className="opacity-50" type="text" value={youtube} placeholder="@username" onChange={handleYoutubeChange}/>
                                    </div>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Discord</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Discord} alt="Discord" className="me-2 d-block" />
                                        </span>
                                        <input  className="opacity-50" type="text" value={discord} placeholder="@username" onChange={handleDiscordChange}/>
                                    </div>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Website</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Website} alt="Website" className="me-2 d-block" />
                                        </span>
                                        <input className="opacity-50" type="text" value={website} placeholder="https://divinedimension.io/" onChange={handleWebsiteChange}/>
                                    </div>
                                </Button>
                            </Col>
                            {/* <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Facebook</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Facebook} alt="Facebook" className="me-2 d-block" />
                                        </span>
                                        <input  className="opacity-50" type="text" value={facebook} placeholder="@username" onChange={handleFacebookChange}/>
                                    </div>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Instagram</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Instagram} alt="Instagram" className="me-2 d-block" />
                                        </span>
                                        <input  className="opacity-50" type="text" value={instagram} placeholder="@username" onChange={handleInstagramChange}/>
                                    </div>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Telegram</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Telegram} alt="Telegram" className="me-2 d-block" />
                                        </span>
                                        <input  className="opacity-50" type="text" value={telegram} placeholder="@username" onChange={handleTelegramChange}/>
                                    </div>
                                </Button>
                            </Col> */}
                        </Row>

                        <Row>
                            <Col xs={6} className="mb-3 pt-2">
                                <Button type="reset" className="px-md-5 b2 px-3" variant="white" onClick={cancelEditProfileToggle}>Cancel</Button>
                            </Col>
                            <Col xs={6} className="mb-3  pt-2">
                                <Button className="px-md-5 b2 px-4" variant="black" onClick={editProfile}>Save changes</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3} className="d-none d-md-block">
                        {/* <div className="border p-2">
                            <img src={VerifyImg} alt="VerifyImg" className="img-fluid rounded-md mb-2" />
                            <div className="p-2">
                                <h4 className="text-600 text-center">Get your account verified</h4>
                                <p className="b4 text-black opacity-50">Proceed with verification processto get more visibility and gaintrust on divinedimension</p>
                                <Button variant="white" className="w-100 border-black" size="sm">Get Verified!</Button>
                            </div>
                        </div> */}
                    </Col>
                </Row>
                </> : <>
                <Row className="g-4">
                    <Col md={9}>
                        <Row className="g-md-3 mb-40 g-2">
                            <Col xs={6}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="First name"
                                >
                                    <Form.Control 
                                        type="text" 
                                        value={firstName}
                                        placeholder=" "
                                        disabled 
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Last name"
                                >
                                    <Form.Control 
                                        type="text" 
                                        value={lastName} 
                                        placeholder=" "
                                        disabled
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Username"
                                >
                                    <Form.Control 
                                        type="text" 
                                        value={userName} 
                                        placeholder=" " 
                                        disabled
                                    />
                                </FloatingLabel>
                            </Col>
                            {/* <Col xs={12}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Email"
                                >
                                    <Form.Control 
                                        type="email" 
                                        value={mail} 
                                        placeholder=" "
                                        onChange={handleMailChange} 
                                    />
                                </FloatingLabel>
                            </Col> */}
                            <Col xs={12}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Wallet Address"
                                >
                                    <Form.Control 
                                        type="text" 
                                        value={walletAddress} 
                                        placeholder=" "
                                        disabled
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12}>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Tell about yourself in a few words"
                                >
                                    <Form.Control 
                                        as="textarea" rows={6} 
                                        placeholder=" " 
                                        value={description}
                                        disabled
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>

                        <h4 className="text-20 text-500 mb-20">Social Links</h4>

                        <Row className="g-md-3 mb-40 g-2" xs={1} sm={2}>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Twitter</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Twitter} alt="Twitter" className="me-2 d-block" />
                                        </span>
                                        {/* <span className="opacity-50">@username</span> */}
                                        <input disabled className="opacity-50" type="text" value={twitter} placeholder="@username"/>
                                    </div>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Youtube</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Youtube} alt="Youtube" className="me-2 d-block" />
                                        </span>
                                        <input disabled className="opacity-50" type="text" value={youtube} placeholder="@username" onChange={handleYoutubeChange}/>
                                    </div>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Discord</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Discord} alt="Discord" className="me-2 d-block" />
                                        </span>
                                        <input disabled className="opacity-50" type="text" value={discord} placeholder="@username"/>
                                    </div>
                                </Button>
                            </Col>
                             <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Website</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Website} alt="Website" className="me-2 d-block" />
                                        </span>
                                        <input disabled className="opacity-50" type="text" value={website} placeholder="https://divinedimension.io/" />
                                    </div>
                                </Button>
                            </Col>
                            {/* <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Instagram</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Instagram} alt="Instagram" className="me-2 d-block" />
                                        </span>
                                        <input  className="opacity-50" type="text" value={instagram} placeholder="@username" onChange={handleInstagramChange}/>
                                    </div>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="white" className="w-100 d-flex flex-column rounded-md py-2">
                                    <span className="b4 text-gray">Telegram</span>
                                    <div className="d-flex align-items-center b1 text-black">
                                        <span className="pt-1">
                                            <img src={Telegram} alt="Telegram" className="me-2 d-block" />
                                        </span>
                                        <input  className="opacity-50" type="text" value={telegram} placeholder="@username" onChange={handleTelegramChange}/>
                                    </div>
                                </Button>
                            </Col> */}
                        </Row>
                        {profileDetails ? <>
                            <Row>
                                <Col xs={6} className="mb-3 pt-2">
                                    <Button type="reset" className="px-md-5 b2 px-3" variant="white">Cancel</Button>
                                </Col>
                                <Col xs={6} className="mb-3  pt-2">
                                    <Button className="px-md-5 b2 px-4" variant="black" onClick={editProfile}>Save changes</Button>
                                </Col>
                            </Row>
                        </> : <>
                            <Row>
                                <Col xs={6} className="mb-3 pt-2">
                                    <Button className="px-md-5 b2 px-4" variant="black" onClick={editProfileToggle}>Edit</Button>
                                </Col>
                            </Row>
                        </>}
                    </Col>
                    <Col md={3} className="d-none d-md-block">
                        {/* <div className="border p-2">
                            <img src={VerifyImg} alt="VerifyImg" className="img-fluid rounded-md mb-2" />
                            <div className="p-2">
                                <h4 className="text-600 text-center">Get your account verified</h4>
                                <p className="b4 text-black opacity-50">Proceed with verification processto get more visibility and gaintrust on divinedimension</p>
                                <Button variant="white" className="w-100 border-black" size="sm">Get Verified!</Button>
                            </div>
                        </div> */}
                    </Col>
                </Row>
                </>}
            </>}
        </>
    )   
}