import { Col, Row } from "react-bootstrap";
import { CardViewHorizontal } from "../../Snippets/CardView";

function MarketReligiousHinduHolyPlaces() {
    return(
        <div className='mb-5 overflow-xl-hidden'>
            <Row className='gx-2 gx-md-3' xs={1} sm={2} md={3} lg={2} xl={3}>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewHorizontal size='sm' />
                </Col>
            </Row>
        </div>
    )
}

export default MarketReligiousHinduHolyPlaces;