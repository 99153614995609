import { Badge, Button, Col, Row, Stack } from 'react-bootstrap';
import Poster from '../asserts/IMAGES/event-poster.png'
import { Link } from 'react-router-dom';
import DotsConnect from '../asserts/IMAGES/dots-connect.svg'
import LinkIcon from '../asserts/IMAGES/link-icon.svg'
import CalendarSdd from '../asserts/IMAGES/calendar-add.svg'

function EventCard({type, size, approval, name, image, owner, contract, startDate, description, stake, time}) {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
    
      const date = new Date(startDate);
    return ( 
        <div className={`event event-${size}`}>
            <div className='position-relative'>
                <img src={(image === null || image === "" || image === undefined) ? Poster : `${image}`} alt="Poster" className='event-poster d-block w-100' />
                <div className='event-date d-inline-flex flex-column align-items-center'>
                    <p className='b2 text-block mb-0'>{months[date.getMonth()]}</p>
                    <strong>{date.getDate()}</strong>
                    <p>{date.getFullYear()}</p>
                </div>
            </div>

            <div className='event-info'>
                <Row>
                    <Col sm={size === 'sm' ? (approval || type === 'upcoming' ? 12 : 9) : 7}>
                        <Stack direction="horizontal" className='mb-2' gap={2}>
                            {type === 'live' && <Badge bg="danger">Live</Badge>}
                            {type === 'upcoming' && <Badge bg="primary">Upcoming</Badge>}
                            <Badge bg="success">Public Event</Badge>
                        </Stack>

                        <h2 className='text-500'><Link className='text-block' to='/event/metaverse-pride-2023'>{(name === null || name === "" || name === undefined) ? <><p>Metaverse Pride 2023: Diversity & Inclusivity in the Virtual World!</p></> : <>{name}</>}</Link></h2>
                        <p className='b2'>Organized by <span className='text-block'>{(owner === null || owner === "" || owner === undefined) ? <>PolygonalMind</> : <>{owner}</>}</span></p>
                    </Col>
                    {!approval && (
                        <Col sm={size === 'sm' ? 3 : 5} className={type === 'upcoming' ? 'd-sm-none' : ''}>
                            <div className={`event-btns d-flex align-items-${size === 'sm' ? 'end' : 'center'} justify-content-end flex-${size === 'sm' ? 'column' : 'row'}`}>
                                {size === 'lg' && <Link  to={{
          pathname: `/launchpad/custom`,
          search: `?name=${name}&contract=${contract}&image=${image}&description=${description}&time=${time}`,
        }} className='btn me-3 btn-white d-none d-sm-inline-flex align-items-center'><span className='px-xl-3'>Jump In</span> <img className='d-block ms-3' src={LinkIcon} alt="LinkIcon" /></Link>}
                                
            {/* {(name === "Yin Yang") ? 
            <> */}
            <Link to={{
                pathname: `/NFTstaking/custom`,
                search: `?name=${name}&contract=${contract}&image=${image}&description=${description}&stake=${stake}`, 
              }}className={type === 'upcoming' ? 'd-none' : 'btn mt-3 card-grad btn-white d-none d-sm-inline-flex align-items-center'}><img className='d-block' src={DotsConnect} alt="DotsConnect" /></Link>
              {/* </> : 
            <><Button variant='white' className={type === 'upcoming' ? 'd-sm-none' : ''}><img className='d-block' src={DotsConnect} alt="DotsConnect" /></Button></>} */}
                                

                                {size === 'sm' && <Link to={{
          pathname: `/launchpad/custom`,
          search: `?name=${name}&contract=${contract}&image=${image}&description=${description}&time=${time}`,
        }}className={type === 'upcoming' ? 'd-none' : 'btn mt-3 card-grad btn-white d-none d-sm-inline-flex align-items-center'}><img className='d-block' src={LinkIcon} alt="LinkIcon" /></Link>}
                            </div>
                        </Col>
                    )}
                </Row>
                {/* {type === 'upcoming' && (
                    <Stack direction="horizontal" className='mt-3 flex-wrap event-btnss' gap={2}>
                        <Link to={{
          pathname: `/launchpad/custom`,
          search: `?name=${name}&contract=${contract}&image=${image}&description=${description}`,
        }} className='btn btn-white btn-sm d-inline-flex align-items-center'><span>Want to go</span> <img className='d-block ms-3' src={LinkIcon} alt="LinkIcon" /></Link>  
                        <Link className='btn btn-white btn-sm d-inline-flex align-items-center'><span>Add to calendar</span> <img className='d-block ms-3' src={CalendarSdd} alt="LinkIcon" /></Link> 
                        <Button variant='white' size="sm" className='d-none d-sm-block'><img className='d-block' src={DotsConnect} alt="DotsConnect" /></Button> 
                    </Stack>
                )} */}

                {approval && (
                    <Row>
                        <Col md={9}>
                            <Stack direction="horizontal" className='mt-3 event-btnss' gap={2}>
                                <Button className='flex-grow-1' size="sm" variant='white'>Cancel</Button>
                                <Button className='flex-grow-1' size="sm" variant='black'>Approve</Button>
                            </Stack>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
     );
}

export default EventCard;