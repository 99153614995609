import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Avatar from "../../asserts/IMAGES/avatar-preview.png";
import User from "../../asserts/IMAGES/user-octagon.svg";
import Checked from '../../asserts/IMAGES/checked.png';

function MyAvatar() {
    return ( 
        <>
            <Row className="gx-xl-5">
                <Col md={{ span: 4, order: 1 }} className="mb-md-0 mb-3">
                    <div className="card-avatar-preview d-flex align-items-end">
                        <img src={Avatar} alt="Avatar" />
                    </div>
                </Col>

                <Col md={8}>
                    <div className="card-container h-100 d-flex flex-column">
                        <div className="w-100">
                            <Row className="mb-3 gx-3">
                                <Col xs={6} sm={4} xl={3} className="mb-3">
                                    <div className="card-avatar-view">
                                        <div className="d-flex align-items-center">
                                            <h4 className="mb-0 me-auto text-600">Custom</h4>
                                            <img src={Checked} alt="Checked" />
                                        </div>
                                        <img src={Avatar} className="card-avatar-view-image" alt="Avatar" />
                                    </div>
                                </Col>
                                <Col xs={6} sm={4} xl={3} className="mb-3">
                                    <Link to="/create" className="border d-flex h-100">
                                        <div className="m-auto text-center">
                                            <img src={User} className="mb-2" alt="Avatar" />
                                            <p className="b2">Get more avatar</p>
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        </div>

                        <div className="mt-auto p-2">
                            <p className="b2 mb-1 text-block">Own your unique identity</p>
                            <p className="b2">Choose a unique identity from our exclusive Avatar Collections and enjoy special benefits!</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default MyAvatar;