import { Link, NavLink } from 'react-router-dom';
import Banner from '../../asserts/IMAGES/explore-banner-image.png'
import { Col, Nav, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
//images
import Buddism from '../../asserts/IMAGES/Buddism.png';
import Hinduism from '../../asserts/IMAGES/Hinduism.png';
import Islam from '../../asserts/IMAGES/Islam.png';
import Scandinavia from '../../asserts/IMAGES/Scandinavia.png';
import Christianity from '../../asserts/IMAGES/Christianity.png';

import Buddism1 from '../../asserts/IMAGES/temp3.png';
import Hinduism1 from '../../asserts/IMAGES/temp4.png';
import Islam1 from '../../asserts/IMAGES/temple1.png';
import Scandinavia1 from '../../asserts/IMAGES/temp5.png';
import Christianity1 from '../../asserts/IMAGES/tem2.png';

import Buddism2 from '../../asserts/IMAGES/pl5.png';
import Hinduism2 from '../../asserts/IMAGES/ppl3.png';
import Islam2 from '../../asserts/IMAGES/l4.png';
import Scandinavia2 from '../../asserts/IMAGES/ppl2.png';
import Christianity2 from '../../asserts/IMAGES/people1.png';

import 'swiper/scss';
import 'swiper/scss/navigation';
import { CardViewHorizontal, CardViewHorizontalNft, CardViewHorizontalAuction,CardViewHorizontalRoyalty, CardViewVertical } from '../../Snippets/CardView';
import { getAlluserDetails, getAlluserDetailsNew } from '../../awsdatafile';
import { CircularProgress } from '@mui/material';
import { getAlluserDetailsNewFirebase } from '../../FirebaseFunctions';

function MarketExplore() {
    const [userProfile, setuserProfile] = useState([""]);

    const getnormalNFtdetails = async() =>{
        // let userprofiles = await getAlluserDetailsNew();
        // console.log("userprofile",userprofiles.data2)
        // console.log("reverse",(userprofiles.data2).reverse())
        // let filteredUserProfile = userprofiles?.data2.filter((x) => (x.firstName))
        // setuserProfile(filteredUserProfile)
        let userprofiles = await getAlluserDetailsNewFirebase();
        console.log("userprofile",userprofiles)
        console.log("reverse",(userprofiles).reverse())
        let filteredUserProfile = userprofiles?.filter((x) => (x.firstName))
        setuserProfile(filteredUserProfile)
    }
    useEffect(()=>{getnormalNFtdetails()},[]) 

    return ( 
        <>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">Marketplace</h1>
            </div>

            <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/market/explore">Explore</NavLink>
                    <NavLink className="nav-link" to="/market/create">Create</NavLink>
                    <NavLink className="nav-link" to="/market/mint">Mint</NavLink>
                    <NavLink className="nav-link" to="/market/crossmint">Crossmint</NavLink>
                    <NavLink className="nav-link" to="/market/explore/single">Trending Collection</NavLink>
                   
                    <NavLink className="nav-link" to="/market/explore/shop/heritage">Heritage</NavLink>
                    <NavLink className="nav-link" to="/market/explore/shop/royalty">Artifact</NavLink>
                  
                    <NavLink className="nav-link" to="/market/explore/shop/auction">Auction</NavLink>
                </div>
            </div>

            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-flex px-100">
                <img src={Banner} alt="image" />

                <div className='my-auto pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col md={8} xl={7} sm={10}>
                            <h1>Explorer of <br />Cultural Collections</h1>
                            <p>Dive into the Future: The Inaugural Immersive Metaverse of Digital Collectibles!</p>

                            {/* <Link to="#" className='btn b1 btn-white-outline'>Start Collecting</Link> */}
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='mb-5 overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Trending Profiles</h2>
                    <Swiper
                        modules={[Navigation, A11y]}
                        spaceBetween={16}
                        slidesPerView={'auto'}
                        autowidth="true"
                        navigation
                        className='swiper-card-5'
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}
                    >
                        {userProfile[0] === null || userProfile[0] === "" || userProfile[0] === "null" || userProfile[0] === undefined || userProfile.length === 0 ?
                        (<>
                            <center><CircularProgress color="inherit" /></center>
                        </>):
                        (<>
                        {userProfile.map((r,i) => {
                            return(<>
                            <SwiperSlide><CardViewVertical img={r.profileImagePath} x ={r}  /></SwiperSlide>
                            </>)
                        })}

                        </>) }
                    
                        {/* <SwiperSlide><CardViewVertical /></SwiperSlide>
                        <SwiperSlide><CardViewVertical /></SwiperSlide>
                        <SwiperSlide><CardViewVertical /></SwiperSlide>
                        <SwiperSlide><CardViewVertical /></SwiperSlide>
                        <SwiperSlide><CardViewVertical /></SwiperSlide> */}
                    </Swiper>
            </div>

            <div className='mb-5 overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Heritage Collections</h2>

                <Swiper
                    modules={[Navigation, A11y]}
                    spaceBetween={16}
                    slidesPerView={'auto'}
                    autowidth={true}
                    navigation
                    className='swiper-card-3'
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                    {userProfile[0] === null || userProfile[0] === "" || userProfile[0] === "null" || userProfile[0] === undefined || userProfile.length === 0 ? (
                      <center><CircularProgress color="inherit" /></center>
                    ) : (
                      <>
                        <SwiperSlide><CardViewHorizontalNft x={Buddism2} y={"Buddhism"} /></SwiperSlide>
                        <SwiperSlide><CardViewHorizontalNft x={Hinduism2} y={"Hinduism"} /></SwiperSlide>
                        <SwiperSlide><CardViewHorizontalNft x={Islam2} y={"Islam"} /></SwiperSlide>
                        <SwiperSlide><CardViewHorizontalNft x={Scandinavia2} y={"Scandinavia"} /></SwiperSlide>
                        <SwiperSlide><CardViewHorizontalNft x={Christianity2} y={"Christianity"} /></SwiperSlide>
                      </>
                    )}
                </Swiper>
            </div>

            <div className='mb-5 overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Artifact Collections</h2>

                <Swiper
                    modules={[Navigation, A11y]}
                    spaceBetween={16}
                    slidesPerView={'auto'}
                    autowidth={true}
                    navigation
                    className='swiper-card-3'
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                    {userProfile[0] === null || userProfile[0] === "" || userProfile[0] === "null" || userProfile[0] === undefined || userProfile.length === 0 ? (
                      <center><CircularProgress color="inherit" /></center>
                    ) : (
                      <>
                    <SwiperSlide><CardViewHorizontalRoyalty mode='light'  x ={Buddism} y={"Buddhism"}  /></SwiperSlide>
                    <SwiperSlide><CardViewHorizontalRoyalty x ={Hinduism} y ={"Hinduism"}/></SwiperSlide>
                    <SwiperSlide><CardViewHorizontalRoyalty x ={Islam} y ={"Islam"}/></SwiperSlide>
                    <SwiperSlide><CardViewHorizontalRoyalty x ={Scandinavia} y ={"Scandinavia"}/></SwiperSlide>
                    <SwiperSlide><CardViewHorizontalRoyalty x ={Christianity} y = {"Christianity"}/></SwiperSlide>
                    </>)}
                </Swiper>
            </div>

            <div className='mb-5 overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Royalty Auction</h2>

                <Swiper
                    modules={[Navigation, A11y]}
                    spaceBetween={16}
                    slidesPerView={'auto'}
                    autowidth={true}
                    navigation
                    className='swiper-card-3'
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                    {userProfile[0] === null || userProfile[0] === "" || userProfile[0] === "null" || userProfile[0] === undefined || userProfile.length === 0 ? (
                      <center><CircularProgress color="inherit" /></center>
                    ) : (
                      <>
                    <SwiperSlide><CardViewHorizontalAuction mode='light'  x ={Buddism1} y={"Buddhism"}  /></SwiperSlide>
                    <SwiperSlide><CardViewHorizontalAuction x ={Hinduism1} y ={"Hinduism"}/></SwiperSlide>
                    <SwiperSlide><CardViewHorizontalAuction x ={Islam1} y ={"Islam"}/></SwiperSlide>
                    <SwiperSlide><CardViewHorizontalAuction x ={Scandinavia1} y ={"Scandinavia"}/></SwiperSlide>
                    <SwiperSlide><CardViewHorizontalAuction x ={Christianity1} y = {"Christianity"}/></SwiperSlide>
                    </>)}
                </Swiper>
            </div>
        </>
     );
}

export default MarketExplore;