import { Button, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import Calendar from '../asserts/IMAGES/calendar.svg'
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import Clock from '../asserts/IMAGES/clock.svg'
import DatePicker from "react-multi-date-picker";
import { useState } from "react";

function DateTimePicker({label, type, ondate}) {
    const [value, setValue] = useState(new Date());

     // Handle date selection
  const handleDateChange = (date) => {
    setValue(value);
    // console.log(value)
    ondate(date);
  };

  // Handle time selection
  const handleTimeChange = (time) => {
    // const newDate = new Date(value); // Create a new date object with the current value
    // const [hours, minutes, seconds] = time.split(':');
    // newDate.setHours(parseInt(hours, 10));
    // newDate.setMinutes(parseInt(minutes, 10));
    // newDate.setSeconds(parseInt(seconds, 10));
    setValue(value);
    ondate(time);
  };

    return ( 
        <InputGroup className="datetime">
            <FloatingLabel
                controlId="floatingInput.Password"
                label={label}
            >   
                <Form.Control
                    type='text'
                    value=" "
                />
                {type === 'date' ? (
                    <DatePicker value={value} format='YYYY-DD-MM' onChange={handleDateChange} />
                ):(
                    <DatePicker 
                        disableDayPicker
                        value={value} 
                        format='HH:mm:ss A' 
                        plugins={[
                            <TimePicker />
                        ]} 
                        onChange={handleTimeChange} 
                    />
                )}
            </FloatingLabel>
            <Button variant="reset" onClick={() => {}} className="border-0" id="button-addon2">
                <img src={type === 'date' ? Calendar : Clock} alt={type === 'date' ? "Calendar" : "Clock"} />
            </Button>
        </InputGroup>
     );
}

export default DateTimePicker;