export const adminAddress = "0x3764d8Fde1b0222877FC5427A56C25EE3029CdB6";
export const adminAddressOld = "0xbd0659188b64ac119e6fe6bece72be1e8a01680d"
export const dimeAddressOld = "0xf35A6D2F0f08638e6f09473Dd5f40e69b4889a8C";
export const dimeAddress = "0x2b59D83deBaF3044E7835a5469266F262aB63a9B";
export const platformAddressAdmin = "0xeA3FCB45f8E27F4fc30675e760d1109f7911B579";

export const CulturalCompleted11 = "0xc0C8fD94c1666dF665142F253724FDF352b4C2E2";
export const CulturalCompleted22 = "0xc0C8fD94c1666dF665142F253724FDF352b4C2E2";
export const CulturalCompleted33 = "0xc0C8fD94c1666dF665142F253724FDF352b4C2E2";

export const DivineLive11 = "0x1BAFa91ec2Caa3523F1A147b4389865cbeCacc69";
export const DivineLive22 = "0x026Fd752101d3e93d2b5C092C1cc17f3E849764F";
export const DivineLive33 = "0xD7f448a4BCd718e48CC1343749F1Ba820710F27d";

export const WorldUpcoming11 = "0x1E3D21EB95f4D2B05ecE5e37debb56bc11b330C3";
export const WorldUpcoming22 = "0x1BAFa91ec2Caa3523F1A147b4389865cbeCacc69";
export const WorldUpcoming33 = "0x1BAFa91ec2Caa3523F1A147b4389865cbeCacc69";

export const tokenaddress1De='0x951883D97Ffd9998B82967837Cd6022458A0d38B'//(verified)'0xb1D694b169027E24aA3ff05bBae6CF37473D1A5B';//'0xF2D99fA00e42FE47F79b56C936ecd2BeEB5C0a26';//'0x3991b6BC5dC693ef257450b5756631Cf66609a87';
export const tokenaddress2De='0x3991b6BC5dC693ef257450b5756631Cf66609a87';
export const tokenaddress3De='0x3991b6BC5dC693ef257450b5756631Cf66609a87';

export const tokenaddress1Ca='0x8F75847e921795fE591257B5Ac6290B0646275A7'//(verified)'0xBb116cDb8e0cc640f1bea04A63029F6aca537Eb3';//'0x9F6450F1c38E60ec093bF467EF6a42DB2c7c2C69';//'0x4F316E77867Ad50b049cCBDEE563170f414C5304';
export const tokenaddress2Ca='0x4F316E77867Ad50b049cCBDEE563170f414C5304';
export const tokenaddress3Ca='0x4F316E77867Ad50b049cCBDEE563170f414C5304';

export const tokenaddress1Wv='0xCACEf3809F6728059b6179C760aAc294e5A72cdA'//(verified)'0x724804cc3B4505E8aC29877caEf3240407750e35';//'0xF5E7a8C1B0920378cfaAAb23882fc077f3ce4D1d';//'0x2056EBa83Da38bA8BfF463b8Fb2aF056E57705f8';
export const tokenaddress2Wv='0x2056EBa83Da38bA8BfF463b8Fb2aF056E57705f8';
export const tokenaddress3Wv='0x2056EBa83Da38bA8BfF463b8Fb2aF056E57705f8';

export const usdcToken='0x14196F08a4Fa0B66B7331bC40dd6bCd8A1dEeA9F';

export const nftCreatorOld = "0xFC32107b3153322F4055bf5Ba7de21978E9E3Dfc"

//from CustomStakeContract.js
export const customStakeaddress = "0x20Eb1e6503e783dC8d7DEc0EEB2073e3923806B6";
export const customNftaddress = "0xD72cD54cC4e473f7E8B1E80482ddA4d6F611F57f"

//from StakingContract.js
export const StakingAddress = '0xb5E21C9f4A560650ee1224Ffb0Cd01E070940A1D';//"0x930a27bA32DeB60cda667881a0Cd732642A30bB9" ; 

export const collectionIdDE = "8a3b63fd-7c83-4ba7-b11f-b62384cfc9d1";//"93f023d8-3d16-4371-a079-a8fb17d1aa98";
export const collectionIdCA = "8eb13480-9742-46ff-baf5-7451d47068a5";//"e2756ded-1e13-444a-bc72-21e62ae4cb31";
export const collectionIdWV = "cdc5e1c5-3db6-4ea5-b7ea-c0e645f67ef1";//"119f315a-bf01-4de5-bc04-063f87a0cc36";

export const ImageUrlDE = "https://gateway.pinata.cloud/ipfs/QmVKD3882yxmXRqBw5J82bnNLWvjL6XG3fJn8NVj6p49C3";
export const ImageUrlCA = "https://gateway.pinata.cloud/ipfs/QmPd8PnwHfVgeKfXJ9YJTauh5AAxixQvMtpg9XKYfDYupf";
export const ImageUrlWV = "https://gateway.pinata.cloud/ipfs/QmTXfPxrzej6T2xDGwhAkJbjRYaEBBJHsSKZTt4JL11cLn";

export const TajmahalImageCM = "https://gateway.pinata.cloud/ipfs/QmPwtxkDV9dLrE3AGKpa6Gfnog2wbfy7bjDU59QN2A5LP7";
export const TajmahalJSONCM = "https://gateway.pinata.cloud/ipfs/QmVVsW3qzuHokF3ytjdRbk5rYSwMZu7tviWG6myFYe8v7t";
export const TajMahalAddressCM = "0x524e6C4ea682a65FF04277929bF41D6F890F6A40"//"0x5D75D1854b7e321C13E0aD1c9A4eBbB2350F001c";
export const collectionIdTajMahal = "1ffaa5ae-0dc0-4da2-9b84-d0d6f170e1e7"//"85b62d3f-45cc-4f18-81de-175d7e946551";

export const PalaceOfArtsImageCM = "https://gateway.pinata.cloud/ipfs/QmWowJRub7ayaxkp9dBPNYySHii52LTSpHzTPdoB51aTdJ";
export const PalaceOfArtsJSONCM = "https://gateway.pinata.cloud/ipfs/QmUxaXi3raUYRNsVTULU2YSpGjtWSEfZJ4BcWyhrQZ3BPw";
export const PalaceOfArtsAddressCM = "0xdd2b090680243770c42eACBE5c833826c047eb50"//"0x3A646FE9bc95880CBbECF699FD83D559D6770f9b";
export const collectionIdPalaceofArts = "e0737b02-5b19-46e4-995c-59293f20425e"//"c6aa94e3-dd04-46ce-9900-b7855e3f2b69";