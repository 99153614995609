import {useEffect, useState } from 'react';
import { Badge, Button, ButtonGroup, Col, ProgressBar, Row, Stack, Tab, Tabs, Toast, Modal} from 'react-bootstrap';
// import ShopImage from '../../asserts/IMAGES/shope-image.png'
import CardImageInfoDE from '../../asserts/IMAGES/info-card-de.png';
import CardImageInfoCA from '../../asserts/IMAGES/info-card.png';
import CardImageInfoWV from '../../asserts/IMAGES/info-card-wv.png';
import usdLogo from '../../asserts/IMAGES/icons8-us-dollar-32.png'
import Loader from '../../asserts/IMAGES/loader-icon.svg'
import Refresh from '../../asserts/IMAGES/refresh-icon.svg'
import Ethereum from '../../asserts/IMAGES/ethereum-filled.svg.svg'
import { Overview, Transactions, MyTransactions} from './Components/TabsContent';
import Countdown from 'react-countdown';
import Web3 from 'web3';
// import  {abi1,tokenaddress1De,tokenaddress1Ca,tokenaddress1Wv} from './NFTLaunchpadcontracts';
import {nftLaunchpadUSDCABI as abi1, Usdcabi} from '../../abi';
import {tokenaddress1De,tokenaddress1Ca,tokenaddress1Wv, usdcToken, collectionIdDE, collectionIdCA,collectionIdWV,ImageUrlDE,ImageUrlCA, ImageUrlWV} from '../../address';
import { toast,ToastContainer  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import {createNftLaunchpadTxnTable, createActivityTable} from '../../awsdatafile';
import {Ethereum as ConnectPopup} from '../../component/tabs/TabContent';
import { ethers, ContractFactory  } from 'ethers';
import bigInt from 'big-integer';
import BackIcon from '../../asserts/IMAGES/back-icon.svg';
import web3Instance from '../../web3Inastance';
import { CircularProgress } from '@mui/material';
import { CrossmintPayButton, CrossmintPaymentElement } from '@crossmint/client-sdk-react-ui';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { createActivityTableFirebase, createNftLaunchpadTxnTableFirebase } from '../../FirebaseFunctions';
import { ChangeNetwork, ConnectWallet } from '../../GeneralFunctions';

const ETH_PRICE_API = "https://api.coingecko.com/api/v3/coins/ethereum";

function NFTShop({name, web3State}) {
    const navigate = useNavigate();

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const [like, setLike] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [count, setCounter] = useState(1);
    const [totalid, setTotalid]=useState(0);
    const [NFTCount,setNFTCount]=useState(0);
    const [price, setPrice]=useState(0);
    const [Totalprice,setTotalprice]=useState(0);
    const [tokenaddr,setTokenaddr]=useState("");
    const [ether,setEther]=useState(0);
    const [totalNFT,setTotalNFT]=useState(0);
    const [val,setVal]=useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const phase = queryParams.get('phase');
    const description = queryParams.get('description');
    const [currentTime, setCurrentTime] = useState('');
    const [NFTLaunchpadName,setNFTLaunchpadName]=useState("");
    const [img,setImg]=useState("");
    const [scroll, setScroll] = useState(false);
    const [loader, setLoader] = useState(false);
    const [ethPrice, setEthPrice] = useState(null);
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [crossmintCollection, setCrossmintCollection] = useState("");
    const [crossmintPrice, setCrossmintPrice] = useState(0.0000);
    const [crossmintImage, setCrossmintImage] = useState("");
    const [transChange, setTransChange] = useState(false);
    const [allowance, setAllowance] = useState("");
  
    useEffect(() => {
      fetch(ETH_PRICE_API)
        .then((response) => response.json())
        .then((data) => {
          setEthPrice(data.market_data.current_price.usd);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        if(!scroll)
        {
            window.scrollTo(0, 0);
            setScroll(true);
        }
    });
    
    const [contractAddress, setContractAddress] = useState('');
    const [checkAccount, setCheckAccount] = useState(null);
    const [ispopup, setIspopup] = useState(false);
    const handleClose = () => setIspopup(false);
    const handleClose1 = () => setShow1(false);

    // Function to update the current time and format it
      const updateCurrentTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
  
        const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        setCurrentTime(formattedTime);
      };

  
      
     useEffect(()=>{
        if(address !== "" && address !== undefined && address !== null){
                setCheckAccount(true);
                setLoader(true);
            }
            else{
                setCheckAccount(false);
                setLoader(true);
            }
         func();
     }, [address,isConnected]);
//      const func=async()=>{
//         const web3 = web3Instance;
//         if (!web3) return;
//         const provider = new ethers.providers.Web3Provider(window.ethereum);
//         const signer = provider.getSigner();
//         const accounts = await web3.eth.getAccounts()
//         const account = accounts[0];
//         console.log("Connected Successfully",account)
//         try{
//         if(phase==="1")
//         {let contract=name;
//         if(contract==="De")
//             {
//                 setTokenaddr(tokenaddress1De);
//                 setVal(2700000000000000);
//                 // setCrossmintPrice(0.0027);
//                 setCrossmintPrice(10);
//                 setNFTLaunchpadName("Divine Explorer NFT");
//                 setCrossmintCollection(collectionIdDE);
//                 setCrossmintImage(ImageUrlDE);
//                 setImg(CardImageInfoDE);
//             }
//         if(contract==="Ca")
//             {
//                 setTokenaddr(tokenaddress1Ca);
//                 setVal(500000000000000);
//                 // setCrossmintPrice(0.0005);
//                 setCrossmintPrice(5);
//             setNFTLaunchpadName("Cultural Adventurer NFT");
//             setCrossmintCollection(collectionIdCA);
//             setCrossmintImage(ImageUrlCA);
//             setImg(CardImageInfoCA);
//         }
//         if(contract==="Wv")
//             {
//                 setTokenaddr(tokenaddress1Wv);
//                 setVal(3000000000000000);
//                 // setCrossmintPrice(0.003);
//                 setCrossmintPrice(15);
//             setNFTLaunchpadName("World Voyager NFT");
//             setCrossmintCollection(collectionIdWV);
//             setCrossmintImage(ImageUrlWV);
//             setImg(CardImageInfoWV)
//         }
    
//        const instance = new ethers.Contract(tokenaddr,abi1, signer);
//        const usdc = new ethers.Contract(usdcToken,Usdcabi, signer);
//        const startDateContract = await instance.start_time(); 
//        const formattedStartDate = ethers.utils.formatUnits(startDateContract, 0);
//        setStartDate(formattedStartDate);

//         setTotalNFT(web3.utils.toNumber((await instance.MAX_NFT_COUNT())._hex));
//         let t=web3.utils.toNumber((await instance.MAX_NFT_SUPPLY())._hex);
//         // setEther(web3.utils.toNumber((await instance.PRICE_PER_NFT())._hex)/1000000000000000000);
//         setEther(web3.utils.toNumber((await instance.PRICE_PER_NFT())._hex)/1e6);
//         let obj=(await instance.myDeposits(account));
//         console.log(await instance.myDeposits(account));
//         setTotalprice(t*ether);
//         setNFTCount(web3.utils.toNumber(obj[0]._hex));
//         // setPrice(web3.utils.toNumber(obj[1]._hex)/1000000000000000000)
//         setPrice(web3.utils.toNumber(obj[1]._hex)/1e6)
//         setTotalid(t);
//         const endDateContract = await instance.end_time();
               
        
//         const formattedEndDate = ethers.utils.formatUnits(endDateContract, 0);
        
//         setEndDate(formattedEndDate);
//         const allowance1 = ethers.utils.formatUnits(await usdc.allowance(address, tokenaddr), 0);
//         setAllowance(allowance1);
//     }
//         else
//         {
//             // toast.info("Event Not Started Yet",{
//             //     position: toast.POSITION.BOTTOM_RIGHT
//             // });
//         }
//       }
//         catch (error){
//            console.log(error);
//         }
// }

const func = async () => {
    const web3 = web3Instance;
    if (!web3) return;
    
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];
    console.log("Connected Successfully", account);
  
    try {
      if (phase !== "1") {
        // Uncomment if you want to show a message when the event is not started
        // toast.info("Event Not Started Yet", { position: toast.POSITION.BOTTOM_RIGHT });
        return;
      }
  
      let contractData;
      switch (name) {
        case "De":
          contractData = {
            tokenAddr: tokenaddress1De,
            val: 2700000000000000,
            crossmintPrice: 10,
            nftLaunchpadName: "Divine Explorer NFT",
            crossmintCollection: collectionIdDE,
            crossmintImage: ImageUrlDE,
            img: CardImageInfoDE
          };
          break;
        case "Ca":
          contractData = {
            tokenAddr: tokenaddress1Ca,
            val: 500000000000000,
            crossmintPrice: 5,
            nftLaunchpadName: "Cultural Adventurer NFT",
            crossmintCollection: collectionIdCA,
            crossmintImage: ImageUrlCA,
            img: CardImageInfoCA
          };
          break;
        case "Wv":
          contractData = {
            tokenAddr: tokenaddress1Wv,
            val: 3000000000000000,
            crossmintPrice: 15,
            nftLaunchpadName: "World Voyager NFT",
            crossmintCollection: collectionIdWV,
            crossmintImage: ImageUrlWV,
            img: CardImageInfoWV
          };
          break;
        default:
          throw new Error("Invalid contract name");
      }
  
      setTokenaddr(contractData.tokenAddr);
      setVal(contractData.val);
      setCrossmintPrice(contractData.crossmintPrice);
      setNFTLaunchpadName(contractData.nftLaunchpadName);
      setCrossmintCollection(contractData.crossmintCollection);
      setCrossmintImage(contractData.crossmintImage);
      setImg(contractData.img);
  
      const instance = new ethers.Contract(contractData.tokenAddr, abi1, signer);
      const usdc = new ethers.Contract(usdcToken, Usdcabi, signer);
  
      const startDateContract = await instance.start_time();
      setStartDate(ethers.utils.formatUnits(startDateContract, 0));
  
      const maxNFTCount = await instance.MAX_NFT_COUNT();
      setTotalNFT(web3.utils.toNumber(maxNFTCount._hex));
  
      const maxNFTSupply = await instance.MAX_NFT_SUPPLY();
      const t = web3.utils.toNumber(maxNFTSupply._hex);
      const ethert = web3.utils.toNumber((await instance.PRICE_PER_NFT())._hex) / 1e6
      setEther(ethert);
      setTotalprice(t * ethert);
      console.log("checkEth:",t, ethert )
  
      const userDeposits = await instance.myDeposits(account);
      console.log(userDeposits);
      setNFTCount(web3.utils.toNumber(userDeposits[0]._hex));
      setPrice(web3.utils.toNumber(userDeposits[1]._hex) / 1e6);
      setTotalid(t);
  
      const endDateContract = await instance.end_time();
      const formattedEndDate = ethers.utils.formatUnits(endDateContract, 0);
      setEndDate(formattedEndDate);
  
      const allowance1 = await usdc.allowance(address, contractData.tokenAddr);
      setAllowance(ethers.utils.formatUnits(allowance1, 0));
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
// const func = async () => {
//     const url = "https://base-rpc.publicnode.com/";
//     const provider = new ethers.providers.JsonRpcProvider(url);

//     try {
//         if (phase === "1") {
//             let contract = name;
//             let tokenAddress, value, CrossmintPrice, nftLaunchpadName, img;

//             switch (contract) {
//                 case "De":
//                     tokenAddress = tokenaddress1De;
//                     value = 1000000000000000;
//                     CrossmintPrice = 0.0027;
//                     nftLaunchpadName = "Divine Explorer NFT";
//                     setCrossmintCollection(collectionIdDE);
//                     setCrossmintImage(ImageUrlDE);
//                     img = CardImageInfoDE;
//                     break;
//                 case "Ca":
//                     tokenAddress = tokenaddress1Ca;
//                     value = 500000000000000;
//                     CrossmintPrice = 0.0005;
//                     nftLaunchpadName = "Cultural Adventurer NFT";
//                     setCrossmintCollection(collectionIdCA);
//                     setCrossmintImage(ImageUrlCA);
//                     img = CardImageInfoCA;
//                     break;
//                 case "Wv":
//                     tokenAddress = tokenaddress1Wv;
//                     value = 3000000000000000;
//                     CrossmintPrice = 0.003;
//                     nftLaunchpadName = "World Voyager NFT";
//                     setCrossmintCollection(collectionIdWV);
//                     setCrossmintImage(ImageUrlWV);
//                     img = CardImageInfoWV;
//                     break;
//                 default:
//                     throw new Error("Invalid contract name");
//             }

//             setTokenaddr(tokenAddress);
//             setCrossmintPrice(CrossmintPrice)
//             setVal(value);
//             setNFTLaunchpadName(nftLaunchpadName);
//             setImg(img);

//             const instance = new ethers.Contract(tokenAddress, abi1, provider);

//             const [
//                 maxNFTCount,
//                 maxNFTSupply,
//                 pricePerNFT,
//                 endDateContract,
//                 startDateContract
//             ] = await Promise.all([
//                 instance.MAX_NFT_COUNT(),
//                 instance.MAX_NFT_SUPPLY(),
//                 instance.PRICE_PER_NFT(),
//                 instance.end_time(),
//                 instance.start_time()
//             ]);

//             const formattedMaxNFTCount = ethers.utils.formatUnits(maxNFTCount, 0);
//             const formattedMaxNFTSupply = ethers.utils.formatUnits(maxNFTSupply, 0);
//             const formattedPricePerNFT = ethers.utils.formatUnits(pricePerNFT, 18);
//             const totalprice = formattedMaxNFTSupply * formattedPricePerNFT;
//             const formattedStartDate = ethers.utils.formatUnits(startDateContract, 0);
//             const formattedEndDate = ethers.utils.formatUnits(endDateContract, 0);

//             setTotalNFT(formattedMaxNFTCount);
//             setEther(formattedPricePerNFT);
//             setTotalprice(totalprice);
//             setTotalid(formattedMaxNFTSupply);
//             setStartDate(formattedStartDate);
//             setEndDate(formattedEndDate);
//             if(isConnected) {
//                 const [myDeposits] = await Promise.all([instance.myDeposits(address)]);
//                 const formattedNFTCount = ethers.utils.formatUnits(myDeposits[0], 0);
//                 const formattedPrice = ethers.utils.formatUnits(myDeposits[1], 18);
//                 setNFTCount(formattedNFTCount);
//                 setPrice(formattedPrice);
//             }
//         } else {
//             // Uncomment to show toast notification when event has not started
//             // toast.info("Event Not Started Yet", {
//             //     position: toast.POSITION.BOTTOM_RIGHT
//             // });
//         }
//     } catch (error) {
//         console.error(error);
//     }
// };

    function EpochToDateTime( epochTime ) {
        const date = new Date(epochTime * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }
    // const connectToEthereum = async () => {
    //     try {
    //       if (window.ethereum) {
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         const web3= new Web3(window.ethereum);
    //         if(localStorage.getItem("walletAddress") !== "" && localStorage.getItem("walletAddress") !== undefined && localStorage.getItem("walletAddress") !== null){
    //             setCheckAccount(true);
    //         }
    //         else{
    //             setCheckAccount(false);
    //         }
    //         return web3;
    //       } else {
    //         throw new Error('No Ethereum wallet found.');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       setCheckAccount(false);
    //       return null;
    //     }
    //   };

    const approve =async() =>{
        try {
       const web3 = web3Instance;
       if (!web3) return;
       const provider = new ethers.providers.Web3Provider(window.ethereum);
       const signer = provider.getSigner();
       const accounts = await web3.eth.getAccounts()
       const account = accounts[0];
       console.log("Connected Successfully",account);

       const usdccontract = new ethers.Contract(usdcToken,Usdcabi, signer);
       let approveresult = await usdccontract.approve(tokenaddr, ether*1e6);
       let approvereceipt = await approveresult.wait();
       toast.success("Approved Successfully",{
        position: toast.POSITION.BOTTOM_RIGHT
    }); 
       await func();
        } catch (error) {
            console.error('Approve error:', error);
        }
    }

    
    const MintNFT =async() =>{
         try {
        const web3 = web3Instance;
        if (!web3) return;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const accounts = await web3.eth.getAccounts()
        const account = accounts[0];
        console.log("Connected Successfully",account)
        
        const NFTLaunchpadcontract = new ethers.Contract(tokenaddr,abi1, signer);
        console.log('Contract deployed at address:', NFTLaunchpadcontract.address);
         setContractAddress(NFTLaunchpadcontract.address);
        
             
        let mintresult = await NFTLaunchpadcontract.mintNFT(address, "https://gateway.pinata.cloud/ipfs/QmW4m8adsjP5J87zehVcYZvcohNPYG16n6FoK4Ex3c9SsS", {value:val})
        // .send({from:account,value:val});
        let mintreciept = await mintresult.wait();
        console.log(mintreciept);

        let tot=web3.utils.toNumber((await NFTLaunchpadcontract.MAX_NFT_SUPPLY())._hex);
        setTotalid(tot);

        updateCurrentTime();
        let txnTime = currentTime;
        let Mintamount = `${val}`;
        let mintphase = `${phase}`;
        console.log(mintresult.hash);

        // await createNftLaunchpadTxnTable(txnTime,mintphase,mintresult.hash ,address,Mintamount,tokenaddr,tot);
        await createNftLaunchpadTxnTableFirebase(txnTime,mintphase,mintresult.hash ,address,Mintamount,tokenaddr,tot);
        console.log("Data added to nftlaunchpadtxn database!!");

        // await createActivityTable(address,`NFTLaunchpad${name} Mint`,tokenaddr,mintresult.hash,`NFTLaunchpad${phase}`)
        await createActivityTableFirebase(address,`NFTLaunchpad${name} Mint`,tokenaddr,mintresult.hash,`NFTLaunchpad${phase}`)
            console.log("Data added to activity database!!");
            
            toast.success("Minted Successfully",{
                position: toast.POSITION.BOTTOM_RIGHT
            }); 
            func();
         } 
         catch(error) {
            console.error('Contract deployment error:', error);
        }
          
     }

     const handleSuccess = () => {
        // Update the state after the async operation completes successfully
        setTransChange(!transChange); // Replace with your actual state update
    };

     const refresh = () => {
        window.location.reload();
    }

    return ( 
        <>
            <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 1 }}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body className='b2 px-3 text-gray'>Copied Succesfully!</Toast.Body>
                </Toast>
            </ToastContainer>
            <Button variant="reset" className="btn-back me-md-3 me-2 " onClick={() => navigate(-1)}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={BackIcon} alt="back" className="d-block" />
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </Button>
            <div className="mb-lg-5 mb-4 mt-md-1 mt-4">
                <div className='d-md-none mb-3'>
                    <h1 className='text-36 text-black text-600'>{NFTLaunchpadName}</h1>

                    <Stack gap={3} direction='horizontal'>
                        <span className='btn btn-white-outline border p-2'><img style={{maxHeight: '24px'}} className='d-block' src={Ethereum} alt="Ethereum" /></span>
                        <span className='btn btn-white-outline text-gray b2 border'>Doxxed</span>
                        <span className='btn btn-white-outline text-gray b2 border'>Total items: &nbsp;{totalNFT}</span>
                    </Stack>
                </div>
                <div className='d-flex justify-content-end'>
                    <ButtonGroup aria-label="First group">
                        {/* <Button onClick={() => setLike(!like)} variant="white" className='d-flex align-items-center'>
                            {like ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                </svg>
                            ):(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                </svg>
                            )}
                            {like ? 1 : 0}
                        </Button>
                        <Button variant="white" className='d-flex align-items-center'>
                            <img src={Loader} className='d-block' alt="Loader" />
                        </Button> */}
                        <Button variant="white" className='d-flex align-items-center' onClick={refresh}>
                            <img src={Refresh} className='d-block' alt="Refresh" />
                        </Button>
                    </ButtonGroup>
                </div>
                {/* {Totalprice === 0 ? <> */}
                    {/* <center><CircularProgress color="inherit" /></center> */}
                {/* </> : <> */}
                {!startDate ? <>
                    <center><CircularProgress color="inherit" /></center>
                </> : <>
                    <Row className='gx-lg-5 gx-md-4'>
                        <Col md={5} xs={12} className='mb-md-0 mb-2'>
                            <div className='shop-frame d-flex border'>
                                <img src={img} alt="ShopImage" className='img-fluid m-auto' />
                            </div> 
                        </Col>
                        <Col md={7} xs={12} className='pt-md-3'>
                            <div className='d-none mb-3 d-md-block'>
                                <h1 className='text-36 text-black text-600'>{NFTLaunchpadName}</h1>

                                <Stack gap={3} direction='horizontal'>
                                    <span className='btn py-2 btn-white-outline border'><img style={{maxHeight: '24px'}} className='d-block' src={Ethereum} alt="Ethereum" /></span>
                                    <span className='btn py-2 btn-white-outline text-gray b2 border'>NFT</span>
                                    {/* <span className='btn py-2 btn-white-outline text-gray b2 border'>Total items: &nbsp;{totalNFT-totalid}/{totalNFT}</span> */}
                                    <span className='btn py-2 btn-white-outline text-gray b2 border'>Remaining items: &nbsp;{totalNFT == 0 ? 0 : totalNFT-totalid}</span>
                                    <span className='btn py-2 btn-white-outline text-gray b2 border'>Total items: &nbsp;{totalNFT}</span>
                                </Stack>
                            </div>

                            <div className='border p-lg-3 p-2'>
                                <div className='border mb-2 p-2'>
                                    <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                        <Stack gap={3} direction='horizontal'>
                                            <Badge bg="primary">My Participation</Badge>
                                        </Stack>
                                        {/* <Stack gap={3} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                            <span className='btn py-2 px-3 btn-white-outline text-gray b2 border'>Ended</span>
                                        </Stack> */}
                                    </div>
                                    <p className='b2 text-block'>Minted {NFTCount} <span className='mx-1'>•</span> Donated Amount 
                                    {/* <img src={Ethereum} style={{maxHeight: '16px'}} className='mx-1' alt="Ethereum" /> {price} USDC */}
                                    <span className='mx-1'>•</span> <img src={usdLogo} style={{maxHeight: '16px'}} alt="Ethereum" /> {(price).toFixed(2)} USD</p>
                                </div>
                                <div className='border mb-2 p-2'>
                                    <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                        <Stack gap={3} direction='horizontal'>
                                            <Badge bg="primary">Sale price</Badge>
                                        </Stack>
                                        {/* <Stack gap={3} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                            <span className='btn py-2 px-3 btn-white-outline text-gray b2 border'>Ended</span>
                                        </Stack> */}
                                    </div>
                                    <p className='b2 text-block'>1 NFT<span className='mx-1'>•</span> Price 
                                    {/* <img src={Ethereum} style={{maxHeight: '16px'}} className='mx-1' alt="Ethereum" /> {ether} USDC */}
                                    <span className='mx-1'>•</span> <img src={usdLogo} style={{maxHeight: '16px'}} alt="Ethereum" /> {(ether).toFixed(2)} USD</p>
                                </div>
                                <div className='border-dark bg-light mb-3 p-2'>
                                    <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                        <Stack gap={3} direction='horizontal'>
                                            <Badge bg="primary">Collected so far</Badge>
                                            <Badge bg="success">Live</Badge>
                                        </Stack>
                                        <Stack gap={2} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                            Ends in 
                                            <span style={{minWidth: '110px'}} className='btn bg-white py-2 px-3 btn-white-outline text-gray b2 border'>{endDate ? <Countdown date={EpochToDateTime(endDate)} /> : <Countdown date={'2024-08-01T01:01:01'} />}</span>
                                        </Stack>
                                    </div>
                                    <p className='b2 text-block'>Minted {totalid} <span className='mx-1'>•</span> Price 
                                    {/* <img src={Ethereum} style={{maxHeight: '16px'}} className='mx-1' alt="Ethereum" /> {Math.round(Totalprice*10000)/10000} ETH */}
                                    <span className='mx-1'>•</span> <img src={usdLogo} style={{maxHeight: '16px'}} alt="Ethereum" /> {((Totalprice)).toFixed(2)} USD</p>
                                </div>
                                <div className='mb-2'>
                                    <div className='d-flex b4 mb-1 text-gray align-items-center'>
                                        <span className='flex-grow-1'>Total Minted</span>
                                        <span className='b2 text-black me-2'>{isNaN((totalid / totalNFT) * 100) ? 0.00 : Math.round(((totalid/totalNFT)*100)*100)/100}%</span>
                                        <span>({totalid}/{totalNFT})</span>
                                    </div>
                                    <ProgressBar now={(totalid/totalNFT)*100} variant="dark" />
                                </div>
                                 <div className='border p-2'>
                                    <Row className='gx-3 align-items-center'>
                                        <Col xs="auto" style={{width: '100px'}}>
                                            <div className='border d-flex align-items-center'>
                                                {/* <Button onClick={() => setCounter(count < 2 ? 1 : count - 1)} variant='reset' className='b4 border-0 text-gray'>-</Button> */}
                                               <span style={{width: '22px'}} className='text-black mx-auto text-center'>{count}</span>
                                                {/* <Button onClick={() => setCounter(count + 1)} variant='reset' className='b4 border-0 text-gray'>+</Button> */}
                                            </div>
                                        </Col>
                                        {checkAccount && (chainId == 84532) ? 
                                        // ( (allowance >= (ether * 1e6)) ? 
                                        <><Col>
                                            <CrossmintPayButton
                                              projectId="360ff4c1-781d-4b45-af7c-ba6e08543e92"
                                            //   collectionId="19b99c0e-136d-4ff5-aae1-0c0fac158a3c"
                                              collectionId={crossmintCollection}
                                              environment="staging"
                                              mintConfig={{
                                                type: "erc-721",
                                                totalPrice: (crossmintPrice).toString(),
                                                receiver: address,
                                                // _imageUrl: "https://gateway.pinata.cloud/ipfs/QmW4m8adsjP5J87zehVcYZvcohNPYG16n6FoK4Ex3c9SsS"
                                                _imageUrl: {crossmintImage}
                                              }}
                                              mintTo={address}
                                            //   onEvent={handleSuccess}
                                            />
                                            {/* <Button variant="black" onClick={() => {setShow1(true)}}>Mint</Button> */}
                                            
                                            {/* <Button type="submit" variant="black" className="d-block w-100 b2" onClick={MintNFT}>Mint NFT</Button> */}
                                        </Col></> : 
                                        // <>
                                        // <Button type="submit" variant="black" className="d-block w-100 b2" onClick={approve}>Approve USDC</Button> 
                                        // </>) : 
                                        <><Col>
                                        {checkAccount  ? 
                                        <Button type="reset" className="d-block w-100 b2" variant="black"  onClick={() => ChangeNetwork(walletProvider)}> Change Network </Button>: 
                                        <Button type="reset" className="d-block w-100 b2" variant="black"  onClick={ConnectWallet}> Connect Wallet </Button> }
                                        {/* <Button type="submit" variant="black" className="d-block w-100 b2"  onClick={chainId !== 84532 ? ChangeNetwork : ConnectWallet}> {chainId !== 84532 ? "Change Network" : "Connect Wallet"} </Button> */}
                                        </Col>
                                        </>}
                                    </Row>
                                </div> 
                               <Modal show={ispopup} size="lg" centered="true" onHide={handleClose}>
                            <Modal.Body className='p-md-4 p-3'>
                                <div className='p-md-2'>
                                <div className='text-end position-relative' style={{marginBottom: '-20px', zIndex: 2}}>
                                    <Button variant='reset' onClick={handleClose} className='p-0 text-gray m-0'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="d-block" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    </Button>
                                </div>
                                <Row className='justify-content-center'>
                                    <Col md={9}>
                                     <div className='text-center mt-md-0 mt-3 mb-3'>
                                    <h2>Connect wallet</h2>
                                    <p className='b2 mx-md-4 px-md-5'>Choose how you want to connect. There are several wallet providers.</p>
                                    </div>

                                    <Tabs defaultActiveKey="ethereum"
                                          id="uncontrolled-tab-example"
                                          className='mb-md-4 mb-lg-4 mb-3 w-100'>
                                        <Tab eventKey="ethereum" title="Ethereum">
                                            <ConnectPopup/>
                                        </Tab>
                                    </Tabs>
                                    </Col>
                                </Row>
                                        
                                </div>
                            </Modal.Body>
                        </Modal>
                                        
                            </div>
                        </Col>
                    </Row>
                    </>}
                {/* </>} */}
                
            </div>

            <div className='mb-5'>
                <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                    className='mb-md-4 mb-lg-5 mb-3 gap-md-4'
                >
                    <Tab eventKey="overview" title="Overview">
                        <Overview name={NFTLaunchpadName} contractAddress={tokenaddr} description={description}/>
                    </Tab>
                    <Tab eventKey="transactions" title="Transactions">
                        <Transactions contract={tokenaddr} phase={phase} effectControl={transChange} price={ether}/>
                    </Tab>
                    <Tab eventKey="my-transactions" title="My Transactions">
                        <MyTransactions contract={tokenaddr} phase={phase} effectControl={transChange} price={ether} account={address}/>
                    </Tab>
                </Tabs>
            </div>
            
        </>
     );
}

export default NFTShop;