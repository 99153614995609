import { Button, Col, Dropdown, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import EventFilter from "../../Snippets/EventFilter";
import filterIconWhite from '../../asserts/IMAGES/filter-icon-white.svg'
import { Link } from "react-router-dom";
import EventCard from "../../Snippets/EventCard";

function EventApproval() {
    return ( 
        <>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">Events</h1>
            </div>
            <Row className="gx-xl-5">
                <Col lg="auto" className="mb-lg-0 col-filter mb-2">
                    <h4 className="d-none d-lg-block text-600 mb-20">Category</h4>

                    <Row className="d-none d-lg-block">
                        <Col xs={6} md={12} className="mb-lg-3">
                            <FloatingLabel
                                controlId="floatingInput.Email"
                                label="Search"
                            >
                                <Form.Control type="text" placeholder=" " />
                            </FloatingLabel>
                        </Col>
                        <Col xs={6} md={12}>
                            <EventFilter />
                        </Col>
                    </Row>

                    <Row className="mb-2 gx-2 d-lg-none">
                        <Col>
                            <InputGroup>
                                <FloatingLabel
                                    controlId="floatingInput.Password"
                                    label="Search avatar"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                    />
                                </FloatingLabel>
                                <Button variant="reset" className="border-0" id="button-addon2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                </svg>
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col xs={'auto'} style={{width: '70px'}}>
                            <Dropdown className="h-100">
                                <Dropdown.Toggle variant="black" className="btn-black-filter" id="dropdown-basic">
                                    <img src={filterIconWhite} alt="ico" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="p-3 dropdown-filter">
                                    <EventFilter />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <Row className="mt-3">
                        <Col sm={6} className="mb-4">
                            <EventCard approval={true} type="live" size="sm" />
                        </Col>
                        <Col sm={6} className="mb-4">
                            <EventCard approval={true} type="live" size="sm" />
                        </Col>
                        <Col sm={6} className="mb-4">
                            <EventCard approval={true} type="Upcoming" size="sm" />
                        </Col>
                        <Col sm={6} className="mb-4">
                            <EventCard approval={true} type="Upcoming" size="sm" />
                        </Col>
                        <Col sm={6} className="mb-4">
                            <EventCard approval={true} type="Upcoming" size="sm" />
                        </Col>
                        <Col sm={6} className="mb-4">
                            <EventCard approval={true} type="Upcoming" size="sm" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
     );
}

export default EventApproval;