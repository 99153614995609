import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Col, FloatingLabel, Form, InputGroup, Nav, OverlayTrigger, Row, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Tabs, Tab, Modal } from 'react-bootstrap';
import FixedIcon from '../../asserts/IMAGES/fixed-price-icon.svg'
import TimedAuction from '../../asserts/IMAGES/time-action.svg'
import Royalty from '../../asserts/IMAGES/royalty-icon.svg'
import BackIcon from '../../asserts/IMAGES/back-icon.svg';
import Ethereum from '../../asserts/IMAGES/ethereum-filled.svg.svg';
import ImageIcon from '../../asserts/IMAGES/image-icon.svg';
import Plus from '../../asserts/IMAGES/plus-icon.svg';
import Ricon from '../../asserts/IMAGES/r-icon.png';
import { ToastContainer, Zoom, toast} from 'react-toastify';
import Compress from "react-image-file-resizer";
import React,{useEffect,useState,useContext} from 'react';
// import {abibytecode} from "../abi/datasbytecodeNormalNFT";
import Web3 from 'web3';
// import { abi } from '../abi/Normalnftcontract';
// import {abibytecodeauction} from '../abi/datasbytecodeAuctionNFT';
// import {abiauction} from '../abi/Auctionnftcontract';
// import {abibytecoderoyalty} from '../abi/datasbytecodeRoyaltyNFT';
import { abibytecoderoyaltyNft as abibytecoderoyalty, abibytecodenormalNft as abibytecode, abibytecodeauctionNft as  abibytecodeauction} from '../../abiBytecode';
import { abiroylatyNFT as  abiroylaty, abinormalNFT as abi, abiauctionNFT as abiauction} from '../../abi';
import { adminAddress } from '../../address';
// import {abiroylaty} from '../abi/Royaltynftcontract';
import '../../toast-style-override.css';
import axios from 'axios';
import {createActivityTable, createNFTDetails, getuserDetailsbywalletNew} from '../../awsdatafile';
import { ClockLoader } from 'react-spinners';
import {Ethereum as ConnectPopup} from '../../component/tabs/TabContent';
import { ethers, ContractFactory  } from 'ethers';
import web3Instance from '../../web3Inastance';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { createActivityTableFirebase, createNFTDetailsFirebase, getuserDetailsbywalletNewFirebase } from '../../FirebaseFunctions';
import { ChangeNetwork, ConnectWallet } from '../../GeneralFunctions';

function MarketCreate({web3State}) {
    const navigate = useNavigate();

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const {walletProvider} = useWeb3ModalProvider();

    const[loader, setLoader] = useState(false);
    const handleShowLoad = () => setLoader(true);
    const handleHideLoad = () => setLoader(false);   
    const [getFile,setFile] = useState("") 
    const [Img,setImg] = useState("")
    const [Imgname,setImgname] = useState("")
    const[toggelValue,settoggelValue] = useState(1);
    const[price,setPrice] = useState("");
    const[month,setmonth] = useState("");
    const [selectValue2,setSelectValue2] = useState("Buddhism"); 
    const [Name,setName] = useState("");
    const [Description,setDescription] = useState("");
    const [selectValue311,setSelectValue311] = useState("1");   
    const [contractAddress, setContractAddress] = useState('');
    const [mintNftLoader, setMintNftLoader] = useState(false);
    const [checkAccount, setCheckAccount] = useState(null);
    const [ispopup, setIspopup] = useState(false);
    const handleClose = () => setIspopup(false);

    const handleMintNftLoad = () => setMintNftLoader(true);
    const handleMintNftHide = () => setMintNftLoader(false);

    const captureFile =async(event) => {
        event.stopPropagation()
        event.preventDefault()
        const file = event.target.files[0]
        setImgname(file.name)
        setFile(file)
        const MIN_FILE_SIZE = 1024 // 1KB
        const MAX_FILE_SIZE = 500120 // 500KB
        let fileSizeKiloBytes = file.size 
        let c=0;
        if(fileSizeKiloBytes < MIN_FILE_SIZE){
          toast.dismiss();
          toast.error("File size is less than minimum limit",{autoClose:3000});          
          c=c+1;
          handleHideLoad()                               
          await sleep(4000);
        //   window.location.reload(false)
        }
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
          toast.dismiss();
          toast.error("File size is greater than maximum limit",{autoClose:3000});      
          c=c+1;
          handleHideLoad()  
          await sleep(4000);                             
        //   window.location.reload(false)
        }        
        if(c===0){
        let reader = new window.FileReader()
        try{
        Compress.imageFileResizer(file, 500, 500, 'JPEG', 200, 0,
        uri => {          
            setImg(uri)          
        },
        'base64'
        );
        reader.readAsArrayBuffer(file)        
        }catch (err) {      
        }
        }else{
          toast.dismiss();
          toast.error("Support file size: 1 kb to 500 kb ",{autoClose:3000});                
          handleHideLoad()                               
          await sleep(4000);
        //   window.location.reload(false)
          
        }
        
    }; 

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    

    // const connectToEthereum = async () => {
    //     try {
    //       if (window.ethereum) {
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         const web3 = new Web3(window.ethereum);
    //         if(localStorage.getItem("walletAddress") !== "" && localStorage.getItem("walletAddress") !== undefined && localStorage.getItem("walletAddress") !== null){
    //             setCheckAccount(true);
    //         }
    //         else{
    //             setCheckAccount(false);
    //         }
    //         return web3;
    //       } else {
    //         throw new Error('No Ethereum wallet found.');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       setCheckAccount(false);
    //       return null;
    //     }
    //   };

    const createNft =async() =>{
        handleMintNftLoad();
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;          
        if(address === null || address === undefined || address === ''){                      
          toast.warning(`please connect your wallet`,{autoClose: 5000});            
          handleMintNftHide();                     
        }
        else if(Name === "" || Name  === undefined || Name === null){          
          toast.warning(`please enter NFT Name`,{autoClose: 5000}); 
          handleMintNftHide();                     
        }
        else if(!/\S/.test(Name)){          
          toast.warning(`only space not allowed`,{autoClose: 5000});            
          handleMintNftHide();                     
        }
        else if(format.test(Name)){          
          toast.warning(`please enter valid NFT Name special character not allowed`,{autoClose: 5000});            
          handleMintNftHide();                     
        }                
        else if(Img === "" || Img === undefined || Img === null){          
          toast.warning(`please Select Image`,{autoClose: 5000});            
          handleMintNftHide();                   
        }  
        else if(Description === "" || Description  === undefined || Description === null){          
            toast.warning(`please enter NFT Name`,{autoClose: 5000}); 
            handleMintNftHide();                     
        }  
        else{            
          toast.info("Minting Token",{autoClose: 5000});             
          toast.info("Image Uploading in IPFS",{autoClose: 5000}); 
          if (getFile) {
            try{
                const formData = new FormData();
                formData.append("file", getFile);
                const resFile = await axios({
                    method: "post",
                    url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
                    data: formData,
                    headers: {
                        'pinata_api_key': "a4454043d8776ac1d3b7",
                        'pinata_secret_api_key': '903a589e02512750f7cc3bb7b3fa9031c9428ac40bbfe9778c01394f65813aaf',                        
                        "Content-Type": "multipart/form-data"
                    },
                  });                
                  const ipfsurl = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;                                  
                  console.log("Pinata updated",ipfsurl)
            try {
                const web3 = web3Instance;
                if (!web3) return;
          
                const account = address;
          
               
                // let ipfsurl = "https://gateway.pinata.cloud/ipfs/QmdLCS6mfnfxvhy9TKZ7h3MCNAJ6y3piz2M7MKMhp2zvej?_gl=1*1p8csnr*rs_ga*OWNkNDU4MjktNDA4My00MzBlLTljMTktYmNkNjYyMDUyZDFk*rs_ga_5RMPXG14TE*MTY4MTg5MTIzMy41LjEuMTY4MTg5MTMwOC41Ny4wLjA.";
                let contractInstance;
                let type;
                let txhash;
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                console.log("toggle:", 1)
                if(toggelValue === 1){
                    //Normal NFT Mint
                    // const contract = new web3.eth.Contract(abi);
                    const factory = new ContractFactory(abi, abibytecode, signer);
                    // contractInstance = await factory.deploy(Name,"BASE",ipfsurl,address,'0xFC32107b3153322F4055bf5Ba7de21978E9E3Dfc');
                    contractInstance = await factory.deploy(Name,"BASE",ipfsurl,address,adminAddress);
                    // contractInstance = await contract
                    // .deploy({
                    //   data: abibytecode,
                    //   arguments: [Name,"BASE",ipfsurl,localStorage.getItem('walletAddress'),'0xFC32107b3153322F4055bf5Ba7de21978E9E3Dfc'] ,
                    // })
                    // .send({
                    //   from: account,
                    //   gas: 15571390,
                    // });
    
                    const tx = await contractInstance.mintWithTokenURI(address,ipfsurl,{value: web3.utils.toWei('0.0001', 'ether')})
                    // .send({
                    //     from: localStorage.getItem('walletAddress'), 
                    //     value: web3.utils.toWei('0.0001', 'ether')     
                    //     //gas: 21000            
                    //    }).on('transactionHash',function(hash){      
                    //     console.log("hashget",hash)
                    //     txhash = hash;
                    //  });
                     // Wait for the transaction to be mined
                    const receipt = await tx.wait();
                        
                    // Get the transaction hash
                    txhash = receipt.transactionHash;
                    console.log("Transaction Hash:", txhash);

                    type = "Normal";
                }
                else if(toggelValue === 2){
                      //Auction NFT Mint
                    //   const contract = new web3.eth.Contract(abiauction);
                    const factory = new ContractFactory(abiauction, abibytecodeauction, signer);
                    // contractInstance = await factory.deploy(Name,"BASE",ipfsurl,address,'0xFC32107b3153322F4055bf5Ba7de21978E9E3Dfc');
                    contractInstance = await factory.deploy(Name,"BASE",ipfsurl,address,adminAddress);
                    // contractInstance = await contract.deploy({                                                          
                    //     data: abibytecodeauction,
                    //     arguments: [Name,"BASE",ipfsurl,localStorage.getItem('walletAddress'),'0xFC32107b3153322F4055bf5Ba7de21978E9E3Dfc']        
                    // })
                    // .send({
                    //   from: localStorage.getItem('walletAddress'),
                    //   gas: 15671390
                    // })
                    console.log(contractInstance.address);
                    console.log("deployed");
                    const tx = await contractInstance.mintWithTokenURI(address,ipfsurl,'0',{value: web3.utils.toWei('0.0001', 'ether')})
                    // .send({
                    //     from: localStorage.getItem('walletAddress'), 
                    //     value: web3.utils.toWei('0.0001', 'ether')     
                    //     //gas: 21000            
                    //    }).on('transactionHash',function(hash){      
                    //     console.log("hashget",hash)    
                    //     txhash = hash;
                    //     })
                     // Wait for the transaction to be mined
                     const receipt = await tx.wait();
                        
                     // Get the transaction hash
                     txhash = receipt.transactionHash;
                     console.log("Transaction Hash:", txhash);
                        type = "Auction";
                        console.log("minted");
                }else{
                    const factory = new ContractFactory(abiroylaty, abibytecoderoyalty, signer);
                    // contractInstance = await factory.deploy(Name,"BASE",ipfsurl,address,'0xFC32107b3153322F4055bf5Ba7de21978E9E3Dfc');
                    contractInstance = await factory.deploy(Name,"BASE",ipfsurl,address,adminAddress);
                    // const contract = new web3.eth.Contract(abiroylaty);
                    // contractInstance = await contract.deploy({                                                          
                    //     data: abibytecoderoyalty,
                    //     arguments: [Name,"BASE",ipfsurl,localStorage.getItem('walletAddress'),'0xFC32107b3153322F4055bf5Ba7de21978E9E3Dfc']        
                    // })
                    // .send({
                    //   from: localStorage.getItem('walletAddress'),
                    //   gas: 15571390
                    // })
                    console.log("deployed royalty")
                    const tx = await contractInstance.mintWithTokenURI(address,ipfsurl,{value: web3.utils.toWei('0.0001', 'ether')})
                    // .send({
                    //     from: localStorage.getItem('walletAddress'),
                    //     value: web3.utils.toWei('0.0001', 'ether')      
                    //     //gas: 21000            
                    //    }).on('transactionHash',function(hash){      
                    //     console.log("hashget",hash)    
                    //     txhash = hash;  
                    // })
                     // Wait for the transaction to be mined
                     const receipt = await tx.wait();
                        
                     // Get the transaction hash
                     txhash = receipt.transactionHash;
                     console.log("Transaction Hash:", txhash);
                    type = "Royalty";
                }
                
                // let coverAndProfileImages = await getuserDetailsbywalletNew(address);
                let coverAndProfileImages = await getuserDetailsbywalletNewFirebase(address);

                // await createNFTDetails(address,contractInstance.address,"1",type,Description,Name,"",ipfsurl,selectValue2,coverAndProfileImages.data2[0].profileImagePath);
                await createNFTDetailsFirebase(address,contractInstance.address,"1",type,Description,Name,"",ipfsurl,selectValue2,coverAndProfileImages[0].profileImagePath);
                // await createActivityTable(address,"Mint NFT",contractInstance.address,txhash,selectValue2)
                await createActivityTableFirebase(address,"Mint NFT",contractInstance.address,txhash,selectValue2);
                console.log('Contract deployed at address:', contractInstance.address);
                setContractAddress(contractInstance.address);
                navigate('/profile/nfts');
                handleMintNftHide();
              } catch (error) {
                console.error('Contract deployment error:', error);
                handleMintNftHide();
              }
            } catch (error) {              
                console.log("Error sending File to IPFS: ")
                console.log(error)                                
                handleMintNftHide();
            }
        } else {
            console.log("No file");
        }
        }
       
    }
    
    useEffect(() => {
        if(address !== "" && address !== undefined && address !== null){
            setCheckAccount(true);
        }
        else{
            setCheckAccount(false);
        }
     }, [address, isConnected, chainId]);

    return ( 
        <>
            <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/market/explore">Explore</NavLink>
                    <NavLink className="nav-link" to="/market/create">Create</NavLink>
                    <NavLink className="nav-link" to="/market/mint">Mint</NavLink>
                    <NavLink className="nav-link" to="/market/crossmint">Crossmint</NavLink>
                    <NavLink className="nav-link" to="/market/explore/single">Trending Collection</NavLink>
                   
                    <NavLink className="nav-link" to="/market/explore/shop/heritage">Heritage</NavLink>
                    <NavLink className="nav-link" to="/market/explore/shop/royalty">Artifact</NavLink>
                  
                    <NavLink className="nav-link" to="/market/explore/shop/auction">Auction</NavLink>
                </div>
            </div>

            <Row className="my-md-4 d-none d-md-flex my-3">
                <Col lg={12}>
                    <h1 className="mb-2 text-600 d-flex align-items-start">
                        <Button variant="reset" className="p-0 border-0 btn-back me-md-3 me-2" onClick={() => navigate(-1)}>
                            <img src={BackIcon} alt="back" className="d-block" />
                        </Button>
                        Create New NFT
                    </h1>
                    <p className='b1'>Single edition on Ethereum</p>
                </Col>
            </Row>

            <Row className='mt-md-0 mt-4'>
            <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
                <Col md={7}>
                    <Row>
                        <Col sm={9} md={8}>
                            <div className='border-dark d-flex align-items-start justify-content-between p-md-3 p-2 mb-md-4 mb-3'>
                               {address ? 
                               (<>
                               <div className='d-flex align-items-center'>
                                    <span><img src={Ethereum} alt='Ethereum' /></span>
                                    <div className='ps-md-3 ps-2'>
                                        <h4 className='b1 mb-0'>{(address).substring(0, 4)}...{(address).substring((address).length -4, (address).length)}</h4>
                                        <p className='b4'>Base</p>
                                    </div>
                                </div>
                                <span className='b3 py-1 px-2' style={{backgroundColor:'rgba(3, 16, 29, 0.1)'}}>Connected</span>
                               </>):(<>
                                <div className='d-flex align-items-center'>
                                    <span><img src={Ethereum} alt='Ethereum' /></span>
                                    <div className='ps-md-3 ps-2'>
                                        <h4 className='b1 mb-0'>0x000...0000</h4>
                                        <p className='b4'>Base</p>
                                    </div>
                                </div>
                                <span className='b3 py-1 px-2' style={{backgroundColor:'rgba(3, 16, 29, 0.1)'}}>Not Connected</span>
                               </>)}
                                
                            </div>
                        </Col>
                    </Row>

                    <h4 className='text-500 mb-3'>Upload file</h4>
                    <input type='file' id='uploadFile' hidden onChange = {captureFile}/>
                    <label htmlFor='uploadFile' className='border-dark cursor-pointer mb-md-4 mb-3 text-center justify-content-center d-flex b1' style={{minHeight: '260px'}}>
                        <div className='m-auto'>
                            <span className='border d-inline-block p-3'><img src={ImageIcon} alt="image_icon" className='d-block' /></span>
                            <p className='text-black mt-3'>Browse your computer<br /><span className='text-gray'>PNG/Img. Max 500kb.</span></p>
                        </div>
                    </label>

                    <div className='d-flex mb-md-4 mb-3 align-items-center justify-content-between'>
                        <div className='pe-2'>
                            <h4 className='text-500 mb-0'>Put on marketplace</h4>
                            <p className='b1'>Single edition on Ethereum</p>
                        </div>
                        {/* <Form.Check
                            type="switch"
                            id="custom-switch"
                            label=""
                        /> */}
                    </div>

                    <ToggleButtonGroup type="radio" className='d-flex mb-md-4 mb-3 btn-toggles' name="options" defaultValue={1} >
                        <ToggleButton id="tbg-radio-1" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={1} onClick={()=>settoggelValue(1)}>
                            <img src={FixedIcon} alt='FixedIcon' />
                            <span>Fixed price</span>
                        </ToggleButton>
                        <ToggleButton id="tbg-radio-2" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={2} onClick={()=>settoggelValue(2)}>
                            <img src={TimedAuction} alt='TimedAuction' />
                            <span>Timed auction</span>
                        </ToggleButton>
                        <ToggleButton id="tbg-radio-3" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={3} onClick={()=>settoggelValue(3)}>
                            <img src={Royalty} alt='Royalty' />
                            <span>Royalty</span>
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <h4 className='text-500 text-20 text-black mb-md-4 mb-3'>Category</h4>

                    <Row>
                                        
                        <Col xs={4} className='mb-3'>
                            <Form.Select className='form-control h-100' aria-label="Floating label select example"
                             defaultValue={selectValue2} 
                             onChange={(e) => setSelectValue2(e.target.value)}>
                            <option value="Buddhism">Buddism</option>
                            <option value="Christianity">Christianity</option>
                            <option value="Hinduism">Hinduism</option>
                            <option value="Islam">Islam</option>
                            <option value="Scandinavia">Scandinavia</option>
                            </Form.Select>
                        </Col>
                                        
                                        
                                        
                    </Row>

                    {/* <h4 className='text-500 text-20 text-black mb-md-4 mb-3'>Price</h4>

                    <Row>
                        <Col xs={8} className='mb-3'>
                            <FloatingLabel
                                controlId="floatingInput.Email"
                                label="Enter price"
                            >
                                <Form.Control type="email" placeholder=" " onClick={()=>setPrice()}/>
                            </FloatingLabel>
                        </Col>
                        <Col xs={4} className='mb-3'>
                            <Form.Select className='form-control h-100' aria-label="Floating label select example">
                                <option>ETH</option> */}
                                {/* <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option> */}
                            {/* </Form.Select>
                        </Col>

                        {toggelValue === 2 ? (<>
                            
                        <Col sm={7} className='mb-3'>
                            <div className='form-control'>
                                <div className='b2'>
                                    Date of listing expiration
                                </div>

                                <Form.Select className='form-control text-14 px-2 form-control-sm my-1 bg-light' aria-label="Floating label select example">
                                    <option>3 months</option>
                                    <option value="1" onClick={()=>setmonth(1)}>1 month</option>
                                    <option value="2" onClick={()=>setmonth(2)}>2 months</option>
                                    <option value="3" onClick={()=>setmonth(3)}>3 months</option>
                                </Form.Select> */}

                                {/* <div className='b4 text-black-6'>
                                    Expiration at 03/09/2023, 18:51
                                </div> */}
                            {/* </div> */}
                        {/* </Col></>) :(<></>)} */}
                        {/* toggelValue === 3 ? (<>
                            <Col sm={5} className='mb-3'>
                            <div className='form-control'>
                                <div className='d-flex align-items-center justify-content-between b2'>
                                    <span>Price</span>
                                    <span>— ETH</span>
                                </div>
                                <div className='d-flex align-items-center justify-content-between b3 text-gray'>
                                    <span>divinedimension fee?</span>
                                    <span className='text-500 text-black'>1%</span>
                                </div>
                                <hr className='my-2' />
                                <div className='d-flex align-items-center justify-content-between text-black-6'>
                                    <span>You will receive</span>
                                    <span className='text-700 text-black'>— ETH</span>
                                </div>
                            </div>
                        </Col>
                        </>) */}
                       
                       
                    {/* </Row> */}

                    {/* <div className='d-flex mb-md-4 mb-3 align-items-center justify-content-between'>
                        <div className='pe-2'>
                            <h4 className='text-500 mb-0'>Unlock once purchased</h4>
                            <p className='b1'>Content will be unlocked after successful transaction</p>
                        </div>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label=""
                        />
                    </div> */}

                    {/* <Row>
                        <Col md={7}>
                            <ToggleButtonGroup type="radio" className='d-flex mb-md-4 mb-3 btn-toggles' name="options" defaultValue={0}>
                                <ToggleButton id="tbg-radio-4" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={1}>
                                    <img src={Plus} alt='Plus' />
                                    <span>Create ERC-721</span>
                                </ToggleButton>
                                <ToggleButton id="tbg-radio-5" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={2}>
                                    <img src={Ricon} alt='Ricon' />
                                    <span>divinedimension RARI</span>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Col>
                    </Row> */}

                    <div className='d-flex mb-md-4 mb-3 align-items-center justify-content-between'>
                        <div className='pe-2'>
                            <h4 className='text-500 mb-0 d-flex align-items-center'>Free minting
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                    You don't have to pay any platform fees. you'll only cover the transaction fees.
                                    </Tooltip>
                                }
                                >
                                <Button variant="reset" className='p-0 b-0 ms-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                    </svg>
                                </Button>
                                </OverlayTrigger>
                            </h4>
                            <p className='b1'>The buyer will cover the gas fees for creating the item.</p>
                        </div>
                        {/* <Form.Check
                            type="switch"
                            id="custom-switch"
                            label=""
                        /> */}
                    </div>

                    <FloatingLabel
                        controlId="floatingInput.Text"
                        label="Name"
                        className='mb-md-3 mb-2'
                    >
                        <Form.Control type="text" placeholder=""  onChange={event => setName( event.target.value)} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput.Text"
                        label="Description (Optional)"
                        className='mb-md-3 mb-2'
                    >
                        <Form.Control type="email" placeholder=""  onChange={event => setDescription( event.target.value)} />
                    </FloatingLabel>
{toggelValue === 3 ? (<>
    <InputGroup className='mb-md-3 mb-2'>
    <InputGroup.Text className='bg-transparent opacity-5 b1 border-0' id="basic-addon1">%</InputGroup.Text>
                        <Form.Select className='form-control h-100' aria-label="Floating label select example"defaultValue={selectValue311} 
                                            onChange={(e)=> setSelectValue311(e.target.value)}>
                                                <option value="1">1</option>                                                
                                                <option value="2">2</option>                                                
                                                <option value="3">3</option>                                                
                                                <option value="4">4</option>                                                
                                                <option value="5">5</option>   
                            </Form.Select>
                            {/*  */}
                    </InputGroup>
                    <p className='b1'>Suggested: 1%, 2%, 3%, 4%. Maximum is 5%</p>
</>):(<></>)}
                   
                    
                    <Stack direction='horizontal' gap="3" className='mt-md-5 mt-4 justify-content-md-end justify-content-start'>
                        <Button type="reset" className="px-md-4 col-3 btn-eq b2 px-3" variant="white">Cancel</Button>
                        {checkAccount && (chainId == 84532) ? <>
                        {mintNftLoader ? <>
                            <Button className="px-md-5 col-md-3 b2 px-4" variant="black"><center><ClockLoader color="#ffffff" size={25} /></center></Button>
                        </> : <>
                            <Button className="px-md-5 col-md-3 b2 px-4" variant="black" onClick={()=>createNft()}>Create & Mint NFT</Button>
                        </>}
                        </> : 
                        <>{checkAccount ? 
                            <Button type="reset" className="px-md-5 col-md-3 b2 px-4" variant="black"  onClick={() => ChangeNetwork(walletProvider)}> Change Network </Button>: 
                            <Button type="reset" className="px-md-5 col-md-3 b2 px-4" variant="black"  onClick={ConnectWallet}> Connect Wallet </Button> }</>}
                        <Modal show={ispopup} size="lg" centered="true" onHide={handleClose}>
                        <Modal.Body className='p-md-4 p-3'>
                            <div className='p-md-2'>
                            <div className='text-end position-relative' style={{marginBottom: '-20px', zIndex: 2}}>
                                <Button variant='reset' onClick={handleClose} className='p-0 text-gray m-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="d-block" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                                </Button>
                            </div>
                            <Row className='justify-content-center'>
                                <Col md={9}>
                                 <div className='text-center mt-md-0 mt-3 mb-3'>
                                <h2>Connect wallet</h2>
                                <p className='b2 mx-md-4 px-md-5'>Choose how you want to connect. There are several wallet providers.</p>
                                </div>

                                <Tabs defaultActiveKey="ethereum"
                                      id="uncontrolled-tab-example"
                                      className='mb-md-4 mb-lg-4 mb-3 w-100'>
                                    <Tab eventKey="ethereum" title="Ethereum">
                                        <ConnectPopup/>
                                    </Tab>
                                </Tabs>
                                </Col>
                            </Row>
            
                            </div>
                        </Modal.Body>
                    </Modal>
                    </Stack>
                </Col>
                <Col md={5} className='d-none d-md-block'>
                    <h4 className='text-500 mb-lg-4 mb-3'>Preview</h4>
                                {Img === null || Img === "" || Img === undefined || Img === "undefined" ?
                                (<>
                                  <div className='border-dark text-center d-flex b1' style={{minHeight: '600px'}}>
                        <p className='text-black m-auto'>Upload file and choose <br />collection to preview your <br />brand new NFT</p>
                        {/* <img src={Img} alt="Img" className='img-fluid w-100 rounded-16' />   */}
                    </div>
                                </>):(<>
                                    <div className='border-dark text-center d-flex b1'>
                        {/* <p className='text-black m-auto'>Upload file and choose <br />collection to preview your <br />brand new NFT</p> */}
                        <img src={Img} alt="Img" className='img-fluid w-100 rounded-16' />  
                    </div>
                                </>)} 
                  
                </Col>
            </Row>            
        </>
     );
}

export default MarketCreate;