import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Slider } from "rsuite";

function ExploreShopFilter({handleFilter,handleSort,getValue,getOrder}) {
    const [selectedOption,setSelectedOption]=useState("")
    const handleSelectChange=(e)=>{
       getOrder(e.target.value)
    }
    return ( 
        <div className='filter-sidebar'>
            <h4 className="text-600 d-lg-none mb-3">Category</h4>
            <div className="form">
                                <input
                                    type="radio"
                                    value="option1"
                                    checked={selectedOption === 'option1'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?handleFilter(""):handleFilter("")}}
                                    name="radioGroup" 
                                />
                <label className="form-check-label" htmlFor="flexCheck_1">
                    All 
                </label>
            </div>
            <div className="form">
                                <input
                                    type="radio"
                                    value="option2"
                                    checked={selectedOption === 'option2'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?handleFilter("Hinduism"):handleFilter("")}}
                                    name="radioGroup" 
                                />
                <label className="form-check-label" htmlFor="flexCheck_2">
                Art & Culture 
                </label>
            </div>
            <div className="form">
                                <input
                                    type="radio"
                                    value="option3"
                                    checked={selectedOption === 'option3'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?handleFilter("Buddhism"):handleFilter("")}}
                                    name="radioGroup" 
                                />
                <label className="form-check-label" htmlFor="flexCheck_3">
                Causes 
                </label>
            </div>
            <div className="form">
                                <input
                                    type="radio"
                                    value="option4"
                                    checked={selectedOption === 'option4'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?handleFilter("Christianity"):handleFilter("")}}
                                    name="radioGroup" 
                                />
                <label className="form-check-label" htmlFor="flexCheck_4">
                Competition 
                </label>
            </div>
            <div className="form">
                                <input
                                    type="radio"
                                    value="option5"
                                    checked={selectedOption === 'option5'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?handleFilter("Islam"):handleFilter("")}}
                                    name="radioGroup" 
                                />
                <label className="form-check-label" htmlFor="flexCheck_5">
                Education 
                </label>
            </div>
            <div className="form">
                                <input
                                    type="radio"
                                    value="option6"
                                    checked={selectedOption === 'option6'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?handleFilter("Scandinavia"):handleFilter("")}}
                                    name="radioGroup" 
                                />
                <label className="form-check-label" htmlFor="flexCheck_6">
                Artifact 
                </label>
            </div>
            {/* <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheck_7" />
                <label className="form-check-label" htmlFor="flexCheck_7">
                Gaming (400)
                </label>
            </div> */}

            <hr />

            <h4 className="text-600 mb-3">Sort by</h4>
            <div className="form">
                                <input
                                    type="radio"
                                    value="option7"
                                    checked={selectedOption === 'option7'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?handleSort(true):handleSort(false)}}
                                    name="radioGroup" 
                                />
                <label className="form-check-label" htmlFor="flexCheck_8">
                Newest 
                </label>
            </div>
            <div className="form">
                                <input
                                    type="radio"
                                    value="option8"
                                    checked={selectedOption === 'option8'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?handleSort(true):handleSort(false)}}
                                    name="radioGroup" 
                                />
                <label className="form-check-label" htmlFor="flexCheck_9">
                Trending 
                </label>
            </div>
            {/* <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheck_10" />
                <label className="form-check-label" htmlFor="flexCheck_10">
                Has Offer  <span>(80)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheck_11" />
                <label className="form-check-label" htmlFor="flexCheck_11">
                On Auction  <span>(80)</span>
                </label>
            </div>   */}

            <hr />

            <h4 className="text-600 mb-0">Price</h4>
            <p className="">Price Range</p>

            <div className="py-4 my-2">
                <Slider
                    progress
                    defaultValue={0}
                    step={0.1}
                    min={0}
                    max={1}    
                    renderMark={mark => {
                        if ([0, 1].includes(mark)) {
                          return <span>{mark} ETH</span>;
                        }
                        return null;
                    }}
                    onChange={value => {
                        getValue(value)
                    }}
                />
                <p className="d-flex text-14 text-500 align-items-center justify-content-between"><span>0 ETH</span><span>1 ETH</span></p>
            </div>

            <Form.Select aria-label="Default select example" onChange={handleSelectChange}>
        <option value="">Select Price Order</option>
        <option value="H2L">High to low Price</option>
        <option value="L2H">Low to high Price</option>
                {/* <option value="2">Two</option>
                <option value="3">Three</option> */}
            </Form.Select>

        </div>
     );
}

export default ExploreShopFilter;