import { Outlet} from "react-router-dom";
import LayoutAfterLogin from "../../component/LayoutAL";

function EventMain({ethBalance, dimeBalance}) {    
    return (
        <LayoutAfterLogin ethBalance = {ethBalance} dimeBalance = {dimeBalance}>
            <Outlet />
        </LayoutAfterLogin> 
    )
}

export default EventMain;