import { NavLink, Outlet } from "react-router-dom";
import LayoutAfterLogin from "../../component/LayoutAL";
import { Nav } from "react-bootstrap";

function AvatarMain() {
    
    return (
        <LayoutAfterLogin>
            <h4 className="mb-2 d-xl-none text-600">Avatar</h4>

            <div className="d-flex">
                <div className="inner-links navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/avatar">Avatar</NavLink>
                    <NavLink to="badges">Badges</NavLink>
                    <Nav.Link to="/avatar">Inventory</Nav.Link>
                    <Nav.Link to="/avatar">Staking  </Nav.Link>
                </div>
            </div>

            <div className="mt-4">
                <Outlet />
            </div>
        </LayoutAfterLogin> 

    )
}

export default AvatarMain;