import NotFound from '../../../asserts/IMAGES/not-found-bg.svg'

export default function NoFound() {
    return(
        <div className='position-relative text-center'>
            <img src={NotFound} alt="NotFound" className='img-fluid mx-auto d-block' />
            <div className='position-absolute top-0 left-0 w-100 h-100 d-flex'>
                <div className='m-auto'>
                    <h2 className='text-28 mb-2'>Nothing found</h2>
                    <p>We couldn't find anything with this criteria</p>
                </div>
            </div>
        </div>
    )
}