import { Col, Row } from "react-bootstrap";
import { CardViewNFTSale } from "../../Snippets/CardView";
import { getNFTDetailsByAddressTypeSale } from "../../awsdatafile";
import { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";
import ExploreFilter from "../../Snippets/ExploreFilters"
import { CircularProgress } from "@mui/material";
import { getNFTDetailsByAddressTypeSaleFirebase } from "../../FirebaseFunctions";
export default function Sales({web3State}) {
    const location = useLocation();
    const dataParam = new URLSearchParams(location.search).get('address');
    // const[nftList, setNftList] = useState([]);
    const[nftList, setNftList] = useState([]);
    const [valid,setValid]=useState("")
    const [name,setName]=useState("");
    const [nftType,setnftType]=useState("")
    const [sortby,setSortby]=useState("");


    const filter1=(val)=>{
        console.log(val)
        setValid(val)
        setnftType("")
        setSortby("")
        setName("")
        setNftList(nftList.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()));
    }

    const getName1=(val)=>{
        console.log(val)
        setName(val);
        setValid("")
        setnftType("")
        setSortby("")
        setNftList(nftList.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()));
}

    const getType1=(val)=>{
        console.log(val)
        setnftType(val)
        setSortby("")
        setValid("")
        setName("")
        setNftList(nftList.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()));
    }

    const sorting=(val)=>{
        console.log(val)
        setSortby(val)
        setnftType("")
        setName("")
        setValid("")
        if(val==="")
          setNftList(nftList.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()))
        
        if(val==="NtoO")
          setNftList(nftList.sort((a, b) => new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime()));
        
        if(val==="OtoN")
          setNftList(nftList.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()));
           
        if(val==="AtoZ")
          setNftList(nftList.sort((a, b) => a.nftName.localeCompare(b.nftName)));

        if(val==="ZtoA")
          setNftList(nftList.sort((a, b) => b.nftName.localeCompare(a.nftName)));
      
        if(val==="L2H")
          setNftList(nftList.sort((a, b) => a.nftPrice - b.nftPrice));
       
        if(val==="H2L")
          setNftList(nftList.sort((a, b) => b.nftPrice - a.nftPrice));
           
        
    }

  

    const getmyNft = async() =>{
        if(dataParam === null || dataParam === "" || dataParam === undefined || dataParam === "undefined"){

        }else{
            let allNfts = [];
            // let mynft = await getNFTDetailsByAddressTypeSale(dataParam,"Normal","yes");
            // console.log("mynft.data2[0]", mynft.data2[0]);
            // if(mynft.data2[0])
            //     (mynft.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            let mynft = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Normal","yes");
            console.log("mynft.data2[0]", mynft[0]);
            if(mynft[0])
                (mynft).map((r,i)=>{
                    allNfts.push(r);
                })
                

            console.log("mynft",allNfts)
            // let royalty = await getNFTDetailsByAddressTypeSale(dataParam,"Royalty","yes");
            // // console.log("royalty.data2[0]", royalty.data2[0]);
            // if(royalty.data2[0])
            //     (royalty.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            let royalty = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Royalty","yes");
            // console.log("royalty.data2[0]", royalty.data2[0]);
            if(royalty[0])
                (royalty).map((r,i)=>{
                    allNfts.push(r);
                })
                     
            // let auction = await getNFTDetailsByAddressTypeSale(dataParam,"Auction","yes");
            // console.log("auction.data2[0]", auction.data2[0]);
            // if(auction.data2[0])
            //     (auction.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     }) 
            let auction = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Auction","yes");
            console.log("auction.data2[0]", auction[0]);
            if(auction[0])
                (auction).map((r,i)=>{
                    allNfts.push(r);
                })    
            setNftList(allNfts.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()));
            console.log("allNFT", allNfts);
        }
       
    }

    useEffect(()=>{
        getmyNft();
    },[])  
    return(
        <>
        {nftList === "" || nftList === "undefined"|| nftList === null || nftList === undefined || nftList.length === 0 ?
        (<>
            <center><CircularProgress color="inherit" /></center>
        </>):(<>
        <div>
            <ExploreFilter filter={filter1} getnftName={getName1} getnftType={getType1} sort={sorting}/>
        <Row className="g-md-3 g-2" xs={2} sm={3} lg={4}>
            {name!==""?nftList.filter(obj=>obj.nftName.toLowerCase().startsWith(name.toLowerCase())).map((r,i) =>{
                return(<div>
                    <Col>
                        <CardViewNFTSale x={r} />
                    </Col>
                </div>)
            }):valid!==""?nftList.filter(obj=>obj.valid===valid).map((r,i) =>{
                return(<div>
                    <Col>
                        <CardViewNFTSale x={r} />
                    </Col>
                </div>)
            }):nftType!==""?nftList.filter(obj=>obj.nftType===nftType).map((r,i) =>{
                return(<div>
                    <Col>
                        <CardViewNFTSale x={r} />
                    </Col>
                </div>)
            }):sortby!==""?nftList.map((r,i) =>{
                return(<div>
                    <Col>
                        <CardViewNFTSale x={r} />
                    </Col>
                </div>)
            }):nftList.map((r,i) =>{
                return(<div>
                    <Col>
                        <CardViewNFTSale x={r} />
                    </Col>
                </div>)
            })}            
        </Row>
        </div>
        </>)}
        </>
    )  
}