import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Col, Dropdown, FloatingLabel, Form, InputGroup, Nav, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import heritage from '../../asserts/IMAGES/heritage.jpeg'
import unesco from '../../asserts/IMAGES/Unesco.png'
import Monuments from '../../asserts/IMAGES/Monuments.png'
import BannerBG from '../../asserts/IMAGES/divine-banner-bg.png'
import ExploreShopFilter from '../../Snippets/ExploreShopFilter';
import { CardViewShop } from '../../Snippets/CardView';
import { getNFTDetailsByTypeSale, getNFTDetailsByTypeSaleBycategory } from '../../awsdatafile';
import { useLocation } from 'react-router-dom';
import { getNFTDetailsByTypeSaleBycategoryFirebase, getNFTDetailsByTypeSaleFirebase } from '../../FirebaseFunctions';


function MarketExploreShop() {
    const location = useLocation();
    const [star, setStar] = useState(false);
    const [saleImage, setsaleImage] = useState([""]);
    

    const getNftImage = async() =>{
        const queryParams = new URLSearchParams(location.search);
        const name = queryParams.get('category');
        if(name){
            // console.log("name printing")
            // let SaleImage = await getNFTDetailsByTypeSaleBycategory("Normal","yes",name)
            // setsaleImage(SaleImage.data2)
            let SaleImage = await getNFTDetailsByTypeSaleBycategoryFirebase("Normal","yes",name)
            setsaleImage(SaleImage)
        }
        else{
            // console.log("name nor")
            // let SaleImage = await getNFTDetailsByTypeSale("Normal","yes")
            // setsaleImage(SaleImage.data2)
            let SaleImage = await getNFTDetailsByTypeSaleFirebase("Normal","yes")
            setsaleImage(SaleImage)
        }
       
       

    }
    console.log("saleimgae",saleImage)
    useEffect(()=>{getNftImage()},[])    
    return ( 
        <>
            {/* <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">Marketplace</h1>
            </div> */}

            {/* <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                <NavLink className="nav-link" to="/market/explore">Explore</NavLink>
                    <NavLink className="nav-link" to="/market/create">Create</NavLink>
                    <NavLink className="nav-link" to="/market/explore/single">Trending Collection</NavLink>
                   
                    <NavLink className="nav-link" to="/market/explore/shop/heritage">NFT</NavLink>
                    <NavLink className="nav-link" to="/market/explore/shop/royalty">Royalty</NavLink>
                  
                    <NavLink className="nav-link" to="/market/explore/shop/auction">Auction</NavLink>
                </div>
            </div> */}

            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-sm-flex py-sm-3 py-4 px-100">
                <img src={Monuments} className='page-banner-bg' alt="image" />    
                <div className='my-auto text-sm-start text-center pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col md={8} xl={6} sm={8}>
                            <h1>Cultural Monuments <br />
                            {/* New Year Special Collection */}
                            </h1>
                            <p className='mb-0'>
                            (upcoming)
                                {/* Collect all variants of these New Year's special vFlects to win a Legendary reward! */}
                                </p>
                        </Col>
                    </Row>
                </div>
               
            </div>

            
            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-sm-flex py-sm-3 py-4 px-100">
                <img src={unesco} className='page-banner-bg' alt="image" />    
                <div className='my-auto text-sm-start text-center pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col md={8} xl={6} sm={8}>
                            <h1>UNESCO <br />
                            {/* New Year Special Collection */}
                            </h1>
                            <p className='mb-0'>
                            (upcoming)
                                {/* Collect all variants of these New Year's special vFlects to win a Legendary reward! */}
                                </p>
                        </Col>
                    </Row>
                </div>
             
            </div>
            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-sm-flex py-sm-3 py-4 px-100">
                <img src={heritage} className='page-banner-bg' alt="image" />    
                <div className='my-auto text-sm-start text-center pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col md={8} xl={6} sm={8}>
                            <h1>Heritage Sites<br />
                           
                            </h1>
                            <p className='mb-0'>
                            (upcoming)
                                {/* Collect all variants of these New Year's special vFlects to win a Legendary reward! */}
                                </p>
                        </Col>
                    </Row>
                </div>
               
            </div>

          
            
        </>
     );
}

export default MarketExploreShop;