import { Container } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import { useEffect } from 'react';

function LayoutAfterLogin(props) {
    const cons = () => {
        console.log("props LayoutAL", props);
    }

    useEffect(() => {
        cons();
    }, [])
    
    return ( 
        <div className="app-dashboard">
            <Header profileImage = {props.profileImage} username = {props.username}/>

            <Container className='overflow-hidden'>
                {props.children}
            </Container>

            <Footer />
        </div>
    )
}

export default LayoutAfterLogin;