import { Button, Col, Dropdown, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import filterIconWhite from '../asserts/IMAGES/filter-icon-white.svg'
import { useState } from "react";

function ExploreFilter({filter,getnftName,getnftType,sort}) {
    const [selectedOption, setSelectedOption] = useState('');

    return(
        <div className="mb-lg-5 mb-4">
            <Row className="d-none d-lg-flex gx-xl-3 gx-2">
                <Col lg="auto">
                    <Dropdown>
                        <Dropdown.Toggle variant="white" className="btn-sm" id="dropdown-basic">
                            All categories
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-3 w-auto dropdown-filter">
                            <div className='filter-sidebar'>
                            <div className="form">
                                <input
                                    type="radio"
                                    value="option1"
                                    checked={selectedOption === 'option1'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?filter(""):filter("")}}
                                    name="radioGroup1" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_1">
                                        All
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="option2"
                                    checked={selectedOption === 'option2'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?filter("Buddhism"):filter("")}}
                                    name="radioGroup1" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_1">
                                        Buddhism
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="option3"
                                    checked={selectedOption === 'option3'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?filter("Christianity"):filter("")}}
                                    name="radioGroup1" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_2">
                                       Christianity
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="option4"
                                    checked={selectedOption === 'option4'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?filter("Hinduism"):filter("")}}
                                    name="radioGroup1" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_3">
                                       Hinduism
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="option5"
                                    checked={selectedOption === 'option5'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?filter("Islam"):filter("")}}
                                    name="radioGroup1" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_4">
                                        Islam 
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="option6"
                                    checked={selectedOption === 'option6'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?filter("Scandinavia"):filter("")}}
                                    name="radioGroup1" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_5">
                                       Scandinavia 
                                    </label>
                                </div>
                                {/* <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_6" />
                                    <label className="form-check-label" htmlFor="flexCheck_6">
                                        Gambling    
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_7" />
                                    <label className="form-check-label" htmlFor="flexCheck_7">
                                        Gaming     
                                    </label>
                                </div> */}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                {/* <Col lg="auto">
                    <Dropdown>
                        <Dropdown.Toggle variant="white" className="btn-sm" id="dropdown-basic">
                            Buy Now
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-3 w-auto dropdown-filter">
                            <div className='filter-sidebar'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_8" />
                                    <label className="form-check-label" htmlFor="flexCheck_8">
                                        All
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_9" />
                                    <label className="form-check-label" htmlFor="flexCheck_9">
                                        Art & Culture 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_10" />
                                    <label className="form-check-label" htmlFor="flexCheck_10">
                                        Causes 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_11" />
                                    <label className="form-check-label" htmlFor="flexCheck_11">
                                        Competition  
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_12" />
                                    <label className="form-check-label" htmlFor="flexCheck_12">
                                        Education   
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_13" />
                                    <label className="form-check-label" htmlFor="flexCheck_13">
                                        Gambling    
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_14" />
                                    <label className="form-check-label" htmlFor="flexCheck_14">
                                        Gaming     
                                    </label>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col> */}
                <Col lg="auto">
                    <Dropdown>
                        <Dropdown.Toggle variant="white" className="btn-sm" id="dropdown-basic">
                            All Types
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-3 w-auto dropdown-filter">
                            <div className='filter-sidebar'>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="option7"
                                    checked={selectedOption === 'option7'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?getnftType(""):getnftType("")}}
                                    name="radioGroup" 
                                />
                                <label className="form-check-label" htmlFor="flexCheck_15">
                                        All
                                </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="option8"
                                    checked={selectedOption === 'option8'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?getnftType("Normal"):getnftType("")}}
                                    name="radioGroup" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_16">
                                      Heritage 
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="option9"
                                    checked={selectedOption === 'option9'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?getnftType("Royalty"):getnftType("")}}
                                    name="radioGroup" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_17">
                                    Artifact
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="optionA"
                                    checked={selectedOption === 'optionA'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?getnftType("Auction"):getnftType("")}}
                                    name="radioGroup" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_18">
                                    Royalty Auction
                                    </label>
                              </div>
                                {/*<div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_19" />
                                    <label className="form-check-label" htmlFor="flexCheck_19">
                                        Education   
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_20" />
                                    <label className="form-check-label" htmlFor="flexCheck_20">
                                        Gambling    
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_21" />
                                    <label className="form-check-label" htmlFor="flexCheck_21">
                                        Gaming     
                                    </label>
                                </div> */}
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col className="flex-grow-1">
                    <InputGroup className="bg-light border-0">
                        <Button variant="reset" className="px-3 py-2 border-0" id="button-addon2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="d-block" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                        </Button>
                        <Form.Control
                            type="text"
                            className="form-sm ps-0 bg-transparent"
                            placeholder="Search by NFTs"
                            onChange={e=>getnftName(e.target.value)}
                        />
                    </InputGroup>
                </Col>
                {/* <Col lg="auto">
                    <Dropdown>
                        <Dropdown.Toggle variant="white" className="btn-sm" id="dropdown-basic">
                            All Artworks
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-3 w-auto dropdown-filter">
                            <div className='filter-sidebar'>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_21" />
                                    <label className="form-check-label" htmlFor="flexCheck_21">
                                        All
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_22" />
                                    <label className="form-check-label" htmlFor="flexCheck_22">
                                        Art & Culture 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_23" />
                                    <label className="form-check-label" htmlFor="flexCheck_23">
                                        Causes 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_24" />
                                    <label className="form-check-label" htmlFor="flexCheck_24">
                                        Competition  
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_25" />
                                    <label className="form-check-label" htmlFor="flexCheck_25">
                                        Education   
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_26" />
                                    <label className="form-check-label" htmlFor="flexCheck_26">
                                        Gambling    
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_27" />
                                    <label className="form-check-label" htmlFor="flexCheck_27">
                                        Gaming     
                                    </label>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col> */}
                <Col lg="auto">
                    <Dropdown>
                        <Dropdown.Toggle variant="white" className="btn-sm" id="dropdown-basic">
                            Sort by
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-3 w-auto dropdown-filter">
                            <div className='filter-sidebar'>
                            <div className="form">
                                <input
                                    type="radio"
                                    value="optionB"
                                    checked={selectedOption === 'optionB'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?sort(""):sort("")}}
                                    name="radioGroup2" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_28">
                                        All
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="optionC"
                                    checked={selectedOption === 'optionC'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?sort("NtoO"):sort("")}}
                                    name="radioGroup2" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_29">
                                        Newest To Oldest
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="optionD"
                                    checked={selectedOption === 'optionD'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?sort("OtoN"):sort("")}}
                                    name="radioGroup2" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_30">
                                        Oldest To Newest 
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="optionE"
                                    checked={selectedOption === 'optionE'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?sort("AtoZ"):sort("")}}
                                    name="radioGroup2" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_31">
                                        Order From A To Z  
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="optionF"
                                    checked={selectedOption === 'optionF'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?sort("ZtoA"):sort("")}}
                                    name="radioGroup2" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_32">
                                        Order From Z To A 
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="optionG"
                                    checked={selectedOption === 'optionG'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?sort("L2H"):sort("")}}
                                    name="radioGroup2" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_33">
                                    Price Low To High  
                                    </label>
                                </div>
                                <div className="form">
                                <input
                                    type="radio"
                                    value="optionH"
                                    checked={selectedOption === 'optionH'}
                                    onChange={e=>{ setSelectedOption(e.target.value);
                                        e.target.checked?sort("H2L"):sort("")}}
                                    name="radioGroup2" 
                                />
                                    <label className="form-check-label" htmlFor="flexCheck_34">
                                    Price High To Low   
                                    </label>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>

            {/* <Row className="mb-2 gx-2 d-lg-none">
                <Col>
                    <InputGroup>
                        <FloatingLabel
                            controlId="floatingInput.Password"
                            label="Search by NFTs"
                        >
                            <Form.Control
                                type="text"
                                placeholder=" "
                            />
                        </FloatingLabel>
                        <Button variant="reset" className="border-0" id="button-addon2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                        </Button>
                    </InputGroup>
                </Col>
                <Col xs={'auto'} style={{width: '70px'}}>
                    <Dropdown className="h-100">
                        <Dropdown.Toggle variant="black" className="btn-black-filter" id="dropdown-basic">
                            <img src={filterIconWhite} alt="ico" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-3 dropdown-filter">
                            <div className='filter-sidebar'>
                                <h4 className="text-600 mb-3">All categories</h4>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_35" />
                                    <label className="form-check-label" htmlFor="flexCheck_35">
                                        All
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_36" />
                                    <label className="form-check-label" htmlFor="flexCheck_36">
                                        Art & Culture 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_37" />
                                    <label className="form-check-label" htmlFor="flexCheck_37">
                                        Causes 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_38" />
                                    <label className="form-check-label" htmlFor="flexCheck_38">
                                        Competition  
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_39" />
                                    <label className="form-check-label" htmlFor="flexCheck_39">
                                        Education   
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_40" />
                                    <label className="form-check-label" htmlFor="flexCheck_40">
                                        Gambling    
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_41" />
                                    <label className="form-check-label" htmlFor="flexCheck_41">
                                        Gaming     
                                    </label>
                                </div>

                                <h4 />

                                <h4 className="text-600 mb-3">Buy Now</h4>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_42" />
                                    <label className="form-check-label" htmlFor="flexCheck_42">
                                        All
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_43" />
                                    <label className="form-check-label" htmlFor="flexCheck_43">
                                        Art & Culture 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_44" />
                                    <label className="form-check-label" htmlFor="flexCheck_44">
                                        Causes 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_45" />
                                    <label className="form-check-label" htmlFor="flexCheck_45">
                                        Competition  
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_46" />
                                    <label className="form-check-label" htmlFor="flexCheck_46">
                                        Education   
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_47" />
                                    <label className="form-check-label" htmlFor="flexCheck_47">
                                        Gambling    
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_48" />
                                    <label className="form-check-label" htmlFor="flexCheck_48">
                                        Gaming     
                                    </label>
                                </div>

                                <hr />
                                <h4 className="text-600 mb-3">All Items</h4>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_49" />
                                    <label className="form-check-label" htmlFor="flexCheck_49">
                                        All
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_50" />
                                    <label className="form-check-label" htmlFor="flexCheck_50">
                                        Art & Culture 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_51" />
                                    <label className="form-check-label" htmlFor="flexCheck_51">
                                        Causes 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_52" />
                                    <label className="form-check-label" htmlFor="flexCheck_52">
                                        Competition  
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_53" />
                                    <label className="form-check-label" htmlFor="flexCheck_53">
                                        Education   
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_54" />
                                    <label className="form-check-label" htmlFor="flexCheck_54">
                                        Gambling    
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_55" />
                                    <label className="form-check-label" htmlFor="flexCheck_55">
                                        Gaming     
                                    </label>
                                </div>

                                <hr />
                                <h4 className="text-600 mb-3">All Artworks</h4>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_56" />
                                    <label className="form-check-label" htmlFor="flexCheck_56">
                                        All
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_57" />
                                    <label className="form-check-label" htmlFor="flexCheck_57">
                                        Art & Culture 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_58" />
                                    <label className="form-check-label" htmlFor="flexCheck_58">
                                        Causes 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_59" />
                                    <label className="form-check-label" htmlFor="flexCheck_59">
                                        Competition  
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_60" />
                                    <label className="form-check-label" htmlFor="flexCheck_60">
                                        Education   
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_61" />
                                    <label className="form-check-label" htmlFor="flexCheck_61">
                                        Gambling    
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_62" />
                                    <label className="form-check-label" htmlFor="flexCheck_62">
                                        Gaming     
                                    </label>
                                </div>

                                <hr />
                                <h4 className="text-600 mb-3">Sort by</h4>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_63" />
                                    <label className="form-check-label" htmlFor="flexCheck_63">
                                        All
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_64" />
                                    <label className="form-check-label" htmlFor="flexCheck_64">
                                        Art & Culture 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_65" />
                                    <label className="form-check-label" htmlFor="flexCheck_65">
                                        Causes 
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_66" />
                                    <label className="form-check-label" htmlFor="flexCheck_66">
                                        Competition  
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_67" />
                                    <label className="form-check-label" htmlFor="flexCheck_67">
                                        Education   
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_68" />
                                    <label className="form-check-label" htmlFor="flexCheck_68">
                                        Gambling    
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="flexCheck_69" />
                                    <label className="form-check-label" htmlFor="flexCheck_69">
                                        Gaming     
                                    </label>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row> */}
        </div>
    )
}

export default ExploreFilter;