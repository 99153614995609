import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false
    },
  },
};

const labels = ['16/05', '17/05', '18/05', '19/05', '20/05', '21/05', '22/05', '23/05', '24/05', '25/05', '26/05', '27/05', '28/05', '29/05', '30/05', '31/05', '1/06', '2/06', '3/06', '4/06', '5/06', '6/06'];

const createBarGradient = (startColor, endColor) => {
    const gradient = document.createElement('canvas').getContext('2d');
    gradient.canvas.height = 1;
    gradient.canvas.width = 100;

    const barGradient = gradient.createLinearGradient(0, 0, 100, 0);
    barGradient.addColorStop(0, startColor);
    barGradient.addColorStop(1, endColor);

    return barGradient;
  };

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [154, '' , 146, 54, 97, 168, '', '', '', '', 85, 145, 165, 65, '', 35, '', 86, '', '', 94, 24],
      backgroundColor: createBarGradient('rgba(3, 16, 29, 0.12)', 'rgba(3, 16, 29, 0.35)'),

    }
  ],
};

export default function Bardata() {
  return <Bar options={options} data={data} />;
}
