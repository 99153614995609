import LoginIcon from '../asserts/IMAGES/logo-icon.svg'

function LayoutBeforeLogin(props) {
    return ( 
        <div className="app-blogin">
            <div className="header d-md-block d-none w-100 text-center text-md-start">
                <img src={LoginIcon} alt="logo icon" />
            </div>
            {props.children}
        </div>
    )
}

export default LayoutBeforeLogin;