import { Button, Col, Dropdown, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import EventFilter from "../../Snippets/EventFilter";
import filterIconWhite from '../../asserts/IMAGES/filter-icon-white.svg'
import { Link } from "react-router-dom";
import EventCard from "../../Snippets/EventCard";
import { useState, useEffect } from "react";
import { getAllEvents } from "../../awsdatafile";
import { Backdrop, CircularProgress } from "@mui/material";
import { getAllEventsFirebase } from "../../FirebaseFunctions";

function EventAvailable() {
    const [getData, setGetData] = useState([]);
    const [eventName, setEventName] = useState("");
    const [filterName, setFilterName] = useState("");
    const [isLoading, setIsLoading] = useState(true); // For loading state

    const dbcallEvents = async () => {
        try {
            // let activitys = await getAllEvents();
            // const events = activitys?.data2;
            let activitys = await getAllEventsFirebase();
            const events = activitys;
            if (Array.isArray(events)) {
                setGetData(events);
                console.log("Fetched data:", events);
            } else {
                console.error("Fetched data is not an array:", events);
                setGetData([]);
            }
        } catch (error) {
            console.error("Error fetching events:", error);
            setGetData([]);
        } finally {
            setIsLoading(false); // Set loading to false after fetching data
        }
    }

    useEffect(() => {
        dbcallEvents();
    }, []);

    const handleEventNameChange = (event) => {
        setEventName(event.target.value);
    }

    const handleFilterNameChange = (data) => {
        setFilterName(data);
    }

    const filteredData = () => {
        if (eventName !== "") {
            return getData.filter((x) => x.name.toLowerCase().startsWith(eventName.toLowerCase()) && x.approvalStatus === "Yes" && new Date(x.startTime).getTime() <= Date.now());
        } else if (filterName !== "") {
            return getData.filter((x) => x.status === filterName && x.approvalStatus === "Yes" && new Date(x.startTime).getTime() <= Date.now()).sort((a, b) => b.name.localeCompare(a.name));
        } else {
            return getData.filter((x) => new Date(x.startTime).getTime() <= Date.now() && x.approvalStatus === "Yes").sort((a, b) => b.name.localeCompare(a.name));
        }
    }

    const upcomingData = () => {
        if (eventName !== "") {
            return getData.filter((x) => x.name.toLowerCase().startsWith(eventName.toLowerCase()) && x.approvalStatus === "Yes" && new Date(x.startTime).getTime() > Date.now());
        } else if (filterName !== "") {
            return getData.filter((x) => x.status === filterName && x.approvalStatus === "Yes" && new Date(x.startTime).getTime() > Date.now());
        } else {
            return getData.filter((x) => new Date(x.startTime).getTime() > Date.now() && x.approvalStatus === "Yes");
        }
    }

    if (isLoading) {
        return <center><CircularProgress color="inherit" /></center>;
    }

    return (
        <>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">Fundraising</h1>
                <Link to="/launchpad/create" className="btn b2 btn-black btn-md">Create Fundraising</Link>
            </div>
            <Row className="gx-xl-5">
                <Col lg="auto" className="mb-lg-0 col-filter mb-2">
                    <h4 className="d-none d-lg-block text-600 mb-20">Category</h4>
                    <Row className="d-none d-lg-block">
                        <Col xs={6} md={12} className="mb-lg-3">
                            <FloatingLabel controlId="floatingInput.Email" label="Search">
                                <Form.Control type="text" placeholder=" " value={eventName} onChange={handleEventNameChange} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={6} md={12}>
                            <EventFilter filterfun={handleFilterNameChange} dbData={getData.filter((x) => x.approvalStatus === "Yes")} />
                        </Col>
                    </Row>
                    <Row className="mb-2 gx-2 d-lg-none">
                        <Col>
                            <InputGroup>
                                <FloatingLabel controlId="floatingInput.Password" label="Search avatar">
                                    <Form.Control type="text" placeholder=" " />
                                </FloatingLabel>
                                <Button variant="reset" className="border-0" id="button-addon2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col xs={'auto'} style={{ width: '70px' }}>
                            <Dropdown className="h-100">
                                <Dropdown.Toggle variant="black" className="btn-black-filter" id="dropdown-basic">
                                    <img src={filterIconWhite} alt="ico" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="p-3 dropdown-filter">
                                    <EventFilter />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <div className="mb-20 d-none d-md-flex align-items-center justify-content-between">
                        <h1 className="text-600 mb-0">Available now</h1>
                        <Link to="/launchpad/create" className="btn b2 btn-black btn-md">Create Fundraising</Link>
                    </div>
                    <Row>
                        {filteredData().map((x) => (
                            <Col sm={6} className="mb-3" key={x.id}>
                                <div>
                                    <EventCard approval={false} type="live" size="sm" name={x.name} image={x.imagePath} owner={x.walletAddress} contract={x.email} startDate={x.startTime} description={x.description} stake={x.stakeContract} time={x.createdTime} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <div className="mb-4 pt-3">
                        <h1 className="text-600 mb-2">Upcoming Next</h1>
                        <p className="b2">June 2023</p>
                    </div>
                    <Row>
                        {upcomingData().map((x) => (
                            <Col sm={6} className="mb-3" key={x.id}>
                                <div>
                                    <EventCard approval={false} type="upcoming" size="sm" name={x.name} image={x.imagePath} owner={x.walletAddress} contract={x.email} startDate={x.startTime} description={x.description} stake={x.stakeContract} time={x.createdTime} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default EventAvailable;
