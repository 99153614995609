import { Button, Col, Dropdown, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AvatarFilter from "../../Snippets/AvatarFilter";
import AvatarCard from "../../Snippets/AvatarCard";
import FilterIcon from "../../asserts/IMAGES/filter-icon.svg";

function Avatar() {
    return ( 
        <>
            <div className="float-end d-none d-lg-block" style={{marginTop: '-75px'}}>
                <Link to="/avatars/my-avatars" className="btn b2 btn-black btn-md">Create avatar</Link>
            </div>

            <Row>
                <Col lg="auto" className="mb-lg-0 col-filter mb-2">
                    <h4 className="d-none d-lg-block text-600 mb-20">Type</h4>

                    <Row className="d-none d-lg-block">
                        <Col xs={6} md={12} className="mb-lg-3">
                            <FloatingLabel
                                controlId="floatingInput.Email"
                                label="Search"
                            >
                                <Form.Control type="text" placeholder=" " />
                            </FloatingLabel>
                        </Col>
                        <Col xs={6} md={12}>
                            <AvatarFilter />
                        </Col>
                    </Row>

                    <Row className="mb-2 gx-2 d-lg-none">
                        <Col xs={6}>
                            <InputGroup>
                                <FloatingLabel
                                    controlId="floatingInput.Password"
                                    label="Search avatar"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                    />
                                </FloatingLabel>
                                <Button variant="reset" className="border-0" id="button-addon2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                </svg>
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col xs={6}>
                            <Dropdown>
                                <Dropdown.Toggle variant="filter" className="d-flex py-1 align-items-center" id="dropdown-basic">
                                    <div className="flex-grow-1 text-start">
                                        <span className="text-12 text-500">Filter</span>
                                        <strong className="text-16 mt-2 d-block text-500">Apply Filter</strong>
                                    </div>

                                    <img src={FilterIcon} alt="ico" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="p-3 dropdown-filter">
                                    <AvatarFilter />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <Row className="gx-2" xs={2} md={3} xl={4}>
                        <Col className="mb-md-4 mb-2 d-lg-none">
                            <Link className="card-avatar-create d-flex" to="/my-avatars">
                                <h4 className="m-auto text-15 text-500">Create avatar</h4>
                            </Link>
                        </Col>
                        <Col className="mb-md-4 mb-2">
                            <AvatarCard bgColor="#372CA8" />
                        </Col>
                        <Col className="mb-md-4 mb-2">
                            <AvatarCard bgColor="#FF72BD" />
                        </Col>
                        <Col className="mb-md-4 mb-2">
                            <AvatarCard bgColor="#14ACED" />
                        </Col>
                        <Col className="mb-md-4 mb-2">
                            <AvatarCard bgColor="#85EDEE" />
                        </Col>
                        <Col className="mb-md-4 mb-2">
                            <AvatarCard bgColor="#1F0138" />
                        </Col>
                        <Col className="mb-md-4 mb-2">
                            <AvatarCard bgColor="#FFDEE8" />
                        </Col>
                        <Col className="mb-md-4 mb-2">
                            <AvatarCard bgColor="#4A7E79" />
                        </Col>
                        <Col className="mb-md-4 mb-2">
                            <AvatarCard bgColor="#FEBF80" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Avatar;