import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ButtonGroup, Card, Col, Dropdown, FloatingLabel, Form, InputGroup, Nav, Row, Stack, Tab, Tabs, Toast, ToastContainer, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import ShopImage from '../../asserts/IMAGES/shope-image.png'
import Loader from '../../asserts/IMAGES/loader-icon.svg'
import Refresh from '../../asserts/IMAGES/refresh-icon.svg'
import Verified from '../../asserts/IMAGES/verified-icon.svg'
import CopyIcon from '../../asserts/IMAGES/copy-icon.svg'
import FixedIcon from '../../asserts/IMAGES/fixed-price-icon.svg'
import TimedAuction from '../../asserts/IMAGES/time-action.svg'
import Royalty from '../../asserts/IMAGES/royalty-icon.svg'
import { PriceHistory, Properties, TradingHistory } from './Components/TabsContent';
import { CardViewShop } from '../../Snippets/CardView';

import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';


function MarketExploreCheckout() {
    const [like, setLike] = useState(false);
    const [show, setShow] = useState(false);

    const refresh = () => {
        window.location.reload();
    }

    return ( 
        <>
            <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 1 }}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body className='b2 px-3 text-gray'>Copied Succesfully!</Toast.Body>
                </Toast>
            </ToastContainer>
            <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                <NavLink className="nav-link" to="/market/explore">Explore</NavLink>
                    <NavLink className="nav-link" to="/market/create">Create</NavLink>
                    <NavLink className="nav-link" to="/market/explore/single">Trending Collection</NavLink>
                   
                    <NavLink className="nav-link" to="/market/explore/shop/heritage">Heritage</NavLink>
                    <NavLink className="nav-link" to="/market/explore/shop/royalty">Artifact</NavLink>
                  
                    <NavLink className="nav-link" to="/market/explore/shop/auction">Auction</NavLink>
                </div>
            </div>

            <div className="mb-lg-5 mb-4 mt-md-1 mt-4">
                <div className='d-none d-md-flex justify-content-end'>
                    <ButtonGroup aria-label="First group">
                        {/* <Button onClick={() => setLike(!like)} variant="white" className='d-flex align-items-center'>
                            {like ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                </svg>
                            ):(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                </svg>
                            )}
                            {like ? 1 : 0}
                        </Button>
                        <Button variant="white" className='d-flex align-items-center'>
                            <img src={Loader} className='d-block' alt="Loader" />
                        </Button> */}
                        <Button variant="white" className='d-flex align-items-center' onClick={refresh}>
                            <img src={Refresh} className='d-block' alt="Refresh" />
                        </Button>
                    </ButtonGroup>
                </div>
                <Row className='gx-lg-5 gx-md-4'>
                    <Col md={6} xs={12} className='mb-md-0 mb-2'>
                        <div className='d-block mb-md-3 mb-2'>
                            <p className='b2 mb-2 text-black'>Divine Dimension Island <span className='ps-2'>Condos</span> <img src={Verified} className='ms-1' alt="Verified" /></p>

                            <h1 className='text-36 text-black text-600'>Divine Dimension Spire <br />Condo - DST0130</h1>

                            <p className='b2 mb-20'>Owned by <span className='ms-1 text-block'>addr1..tarx6</span></p>
                        </div>

                        <div className='d-flex d-md-none justify-content-end'>
                            <ButtonGroup aria-label="First group">
                                {/* <Button onClick={() => setLike(!like)} variant="white" className='d-flex align-items-center'>
                                    {like ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                        </svg>
                                    ):(
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                        </svg>
                                    )}
                                    {like ? 1 : 0}
                                </Button>
                                <Button variant="white" className='d-flex align-items-center'>
                                    <img src={Loader} className='d-block' alt="Loader" />
                                </Button> */}
                                <Button variant="white" className='d-flex align-items-center' onClick={refresh}>
                                    <img src={Refresh} className='d-block' alt="Refresh" />
                                </Button>
                            </ButtonGroup>
                        </div>

                        <div className='shop-frame d-flex border'>
                            <img src={ShopImage} alt="ShopImage" className='img-fluid m-auto' />
                        </div> 
                    </Col>
                    <Col md={6} xs={12} className='pt-md-3'>
                        <div className='mt-md-5 pt-md-3 mb-md-4 mb-3'>
                            <Button variant='white' onClick={() =>  {navigator.clipboard.writeText('9114...9937'); setShow(true)}} className='px-md-3 px-2 me-md-3 me-2 mb-md-3 mb-2' size='sm'>Policy ID: 9114...9937 <img src={CopyIcon} className='ms-1' style={{verticalAlign: '-5px'}} alt='Copy Icon' /></Button>
                            <Button variant='white' onClick={() =>  {navigator.clipboard.writeText('9114...3330'); setShow(true)}} className='px-md-3 px-2 mb-md-3 mb-2' size='sm'>Asset ID: 9114...3330 <img src={CopyIcon} className='ms-1' style={{verticalAlign: '-5px'}} alt='Copy Icon' /></Button>
                            
                            <p className='b2 mb-20'>A luxurious condo in Dream Spire Tower on Divine Island</p>
                        </div>

                        <div className='d-flex mb-md-4 mb-3 align-items-center justify-content-between'>
                            <div className='pe-2'>
                                <h4 className='text-500 mb-0'>Put on marketplace</h4>
                                <p className='b1'>Single edition on Ethereum</p>
                            </div>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label=""
                            />
                        </div>

                        <ToggleButtonGroup type="radio" className='d-flex mb-md-4 mb-3 btn-toggles' name="options" defaultValue={1}>
                            <ToggleButton id="tbg-radio-1" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={1}>
                                <img src={FixedIcon} alt='FixedIcon' />
                                <span>Fixed price</span>
                            </ToggleButton>
                            <ToggleButton id="tbg-radio-2" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={2}>
                                <img src={TimedAuction} alt='TimedAuction' />
                                <span>Timed auction</span>
                            </ToggleButton>
                            <ToggleButton id="tbg-radio-3" className='mx-md-2 mx-1 bg-white justify-content-center flex-1 align-items-center d-flex flex-column' value={3}>
                                <img src={Royalty} alt='Royalty' />
                                <span>Royalty</span>
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <h4 className='text-500 text-20 text-black mb-md-4 mb-3'>Price</h4>

                        <Row>
                            <Col xs={8} className='mb-3'>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Enter price"
                                >
                                    <Form.Control type="email" placeholder=" " />
                                </FloatingLabel>
                            </Col>
                            <Col xs={4} className='mb-3'>
                                <Form.Select className='form-control h-100' aria-label="Floating label select example">
                                    <option>ETH</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </Form.Select>
                            </Col>
                            <Col sm={5} className='mb-3'>
                                <div className='form-control'>
                                    <div className='d-flex align-items-center justify-content-between b2'>
                                        <span>Price</span>
                                        <span>— ETH</span>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between b3 text-gray'>
                                        <span>divinedimension fee?</span>
                                        <span className='text-500 text-black'>1%</span>
                                    </div>
                                    <hr className='my-2' />
                                    <div className='d-flex align-items-center justify-content-between text-black-6'>
                                        <span>You will receive</span>
                                        <span className='text-700 text-black'>— ETH</span>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={7} className='mb-3'>
                                <div className='form-control'>
                                    <div className='b2'>
                                        Date of listing expiration
                                    </div>

                                    <Form.Select className='form-control text-14 px-2 form-control-sm my-1 bg-light' aria-label="Floating label select example">
                                        <option>3 months</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>

                                    <div className='b4 text-black-6'>
                                        Expiration at 03/09/2023, 18:51
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} className='mb-3'>
                                <InputGroup className='mb-2'>
                                    <InputGroup.Text className='bg-transparent opacity-5 b1 border-0' id="basic-addon1">%</InputGroup.Text>
                                    <Form.Control
                                        value={10}
                                        className='ps-1'
                                        placeholder="Enter value"
                                    />
                                </InputGroup>
                                <p className='b1'>Suggested: 0%, 10%, 20%, 30%. Maximum is 50%</p>
                            </Col>
                            <Col xs={12}>
                                <Button type="submit" variant="black" className="d-block w-100 mb-2" size='md'>Create NFT</Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div>

            <div className='mb-5'>
                <Tabs
                    defaultActiveKey="properties"
                    id="uncontrolled-tab-example"
                    className='mb-md-4 mb-3'
                >
                    <Tab eventKey="properties" title="Properties">
                        <Properties />
                    </Tab>
                    <Tab eventKey="price" title="Price History">
                        <PriceHistory />
                    </Tab>
                    <Tab eventKey="trading" title="Trading History">
                        <TradingHistory />
                    </Tab>
                </Tabs>
            </div>


            <div className='overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Explore the Collection</h2>

                <Swiper
                    modules={[Navigation, A11y]}
                    spaceBetween={16}
                    slidesPerView={'auto'}
                    autowidth="true"
                    navigation
                    className='swiper-card-4'
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                    <SwiperSlide><CardViewShop /></SwiperSlide>
                    <SwiperSlide><CardViewShop /></SwiperSlide>
                    <SwiperSlide><CardViewShop /></SwiperSlide>
                    <SwiperSlide><CardViewShop /></SwiperSlide>
                </Swiper>
            </div>
            
        </>
     );
}

export default MarketExploreCheckout;