import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDyyzjEqlJJhT3TSeYcgJmYCb1UhdynMY8",
  authDomain: "divinedimension-d1a96.firebaseapp.com",
  projectId: "divinedimension-d1a96",
  storageBucket: "divinedimension-d1a96.appspot.com",
  messagingSenderId: "131113053834",
  appId: "1:131113053834:web:10c4dcd0690925b8690773",
  measurementId: "G-PJJZJMXEBL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {db};