import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import LayoutBeforeLogin from "../component/LayoutBL";
import Preview from "../asserts/IMAGES/loginImage.jpg";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginIcon from '../asserts/IMAGES/logo-icon.svg';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { SignInApi, createprofileNew, getSignUp, getuserDetailsbywallet, signStatus } from "../awsdatafile";
import { ClockLoader } from 'react-spinners';
import { InjectedConnector } from "@web3-react/injected-connector";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import Web3 from 'web3'
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { SignInApiFirebase, createprofileNewFirebase, getSignUpFirebase, getuserDetailsbywalletNewFirebase, signStatusFirebase } from "../FirebaseFunctions";
import { ChangeNetwork, ConnectWallet } from "../GeneralFunctions";
// import {useWeb3React} from '@web3-react/core'

function LogIn() {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
//own code
    // const { chainId, activate } = useWeb3React();
    const [show, setShow] = useState(false);
    const [emailId, setEmailId] = useState('');
    const [password, setPassword] = useState('');
    const [signInLoader, setSignInLoader] = useState(false);
    const [coinBaseLoader, setCoinBase] = useState(false);
    const [metamaskLoader, setMetamask] = useState(false);
 
    const handleSignInLoad = () => setSignInLoader(true);
    const handleSignInHide = () => setSignInLoader(false);

    const handleCoinBaseLoad = () => setCoinBase(true);
    const handleCoinBaseHide = () => setCoinBase(false);

    const handleMetamaskLoad = () => setMetamask(true);
    const handleMetamaskHide = () => setMetamask(false);

    let navigate = useNavigate();

    // useEffect(() => {
    //     if(address)
    //         navigate("/event");
    // }, [address, isConnected])
    const fun = async() => {
        let newUserCheck = await getuserDetailsbywalletNewFirebase(address);
            if (newUserCheck.length === 0) {
                await createprofileNewFirebase(address);
            }
    
            console.log("account", address);
            localStorage.setItem('walletAddress', address);
    
            // Display success toast and wait for it to complete
            await new Promise(resolve => {
                toast.success("Login Successful", {
                    onClose: resolve,
                    autoClose: 3000 // Duration of the toast in milliseconds
                });
            });
            
            navigate("/event");

    }
    useEffect(() => {
        if(address !== "" && address !== undefined && address !== null){
            fun();
        }
        console.log(address);
    },[address, isConnected, chainId]);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const signInSubmit = async () => {
        handleSignInLoad();
        // let [value, emailExist] = await getSignUp(emailId);
        let [value, emailExist] = await getSignUpFirebase(emailId);
        if (emailId === null || emailId === "" || emailId === undefined) {
            toast.error("Please enter Email ID!");
            handleSignInHide();
        }
        else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId))) {
            toast.error("Please enter a valid Email ID!");
            handleSignInHide();
        }      
        else if (password === null || password === "" || password === undefined) {
            toast.error("Please enter Password!");
            handleSignInHide();
        }         
        else{
            if (emailExist !== undefined) {
                // let status = await SignInApi(emailId, password);
                // if(status.data.result === "Y")
                // {
                //     localStorage.setItem("emailId", emailId);
                //     await signStatus(emailId, true);
                //     handleSignInHide();
                //     navigate("/avatar");
                // }
                // else if(status.data.result === "N")
                // {
                //     toast.error(`Sign In Unsuccessful`);
                //     handleSignInHide();
                // }
                let status = await SignInApiFirebase(emailId, password);
                if(status === "Y")
                {
                    localStorage.setItem("emailId", emailId);
                    // await signStatus(emailId, true);
                    await signStatusFirebase(emailId, true);
                    handleSignInHide();
                    navigate("/avatar");
                }
                else if(status === "N")
                {
                    toast.error(`Sign In Unsuccessful`);
                    handleSignInHide();
                }
            }
              else
              {
                toast.error("Please Sign Up to continue!");
                handleSignInHide();
                await sleep(5000);
                navigate("/signup");
              }
          }
    }

    const handleEmailIdChange = (e) => {
        setEmailId(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
   
    const loginViaCoinBase=async()=>{
        if (typeof window.ethereum !== "undefined") {
            handleCoinBaseLoad();
            console.log("Coinbase is installed!");
            localStorage.setItem("walletName","Coinbase");
      
            const APP_NAME = 'Coinbase';
            const APP_LOGO_URL = 'https://example.com/logo.png';
            const DEFAULT_ETH_JSONRPC_URL =  'https://base-sepolia.public.blastapi.io';
            const DEFAULT_CHAIN_ID = 84532;
      
            const coinbaseWallet = new CoinbaseWalletSDK({
                  appName: APP_NAME,
                  appLogoUrl: APP_LOGO_URL,
                  darkMode: false
                });
            
                // Initialize a Web3 Provider object
                const ethereum = coinbaseWallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID);
                const web3 = new Web3(ethereum);
  
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const account = accounts[0];
        
                console.log(`User's address is ${account}`);
        // setUserAddress(userAddress);
        web3.eth.defaultAccount = account;
        
          localStorage.setItem("walletAddress", accounts[0]);
        //   await createprofileNew(accounts[0]);
          await createprofileNewFirebase(accounts[0]);
          console.log("account", account);
          toast.success("Login Successful");
          await sleep(3000);
          navigate("/event");
          } 
          else {
            handleCoinBaseHide();
            toast.error("Please Connect your Wallet to continue");
            console.log("Coinbase is not installed.");
            await sleep(3000);
            window.open('https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad', '_blank','noreferrer');

          }
        const { ethereum } = window;
        return Boolean(ethereum && ethereum.Coinbase);

    }
    // const disconnectWallet=async()=>{
    //     if(localStorage.getItem("walletName")==="Coinbase")
    // {       const APP_NAME = 'Coinbase';
    //         const APP_LOGO_URL = 'https://example.com/logo.png';
    //         const DEFAULT_ETH_JSONRPC_URL =  'https://base-sepolia.public.blastapi.io';
    //         const DEFAULT_CHAIN_ID = 84531;
    
    //       const coinbaseWallet = new CoinbaseWalletSDK({
    //             appName: APP_NAME,
    //             appLogoUrl: APP_LOGO_URL,
    //             darkMode: false
    //           });
    //           coinbaseWallet.disconnect();
    //         }
    //           localStorage.setItem("walletAddress","");
    //           localStorage.setItem("walletName","");
    // }

    const loginViaMetaMask=async()=>{
        // disconnectWallet();
        // await sleep(1000);
        if (typeof window.ethereum !== "undefined") {
            handleMetamaskLoad();
            console.log("MetaMask is installed!");
            localStorage.setItem("walletName","Metamask");
            
            const injectedConnector = new InjectedConnector({ supportedChainIds: [84532] });
            // activate(injectedConnector);
            // <><Header active = {active}/></>
            // <AvatarDropDown deactivate = {deactivate} />
            console.log("injectedConnector", injectedConnector);
            const chainId = await window.ethereum.request({ method: 'net_version' });
            console.log(chainId);
            if(chainId!==84532)
            {  
                const request = {
                  method: 'wallet_switchEthereumChain',
                  params: ["0x14a34"],
                };
                
                try {
                  await window.ethereum.send(request);
                } catch (error) {
                  console.log("switchWalletError", error);
                }
            }
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
              .catch((err) => {
                if (err.code === 4001) {
                    handleMetamaskHide();
                  // EIP-1193 userRejectedRequest error
                  // If this happens, the user rejected the connection request.
                  console.log('Please connect to Wallet.');
                } else {
                  console.error(err);
                  handleMetamaskHide();
                }
              });
            const account = accounts[0];
            localStorage.setItem("walletAddress", accounts[0]);
            // let newUserCheck = await getuserDetailsbywallet(accounts[0]);
            let newUserCheck = await getuserDetailsbywalletNewFirebase(accounts[0]);
            if(newUserCheck.length == 0){
                // await createprofileNew(accounts[0]);
                await createprofileNewFirebase(accounts[0]);
            }
            console.log("account", account);
            toast.success("Login Successful");
            await sleep(3000);
            await ConnectWallet();
            navigate("/event");
          } else {
            handleMetamaskHide();
            toast.error("Please Connect your Wallet to continue");
            console.log("MetaMask is not installed.");
            await sleep(3000);
            window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn', '_blank','noreferrer');
          }
    }

    const loginwithWallet = async () => {
    
        try {
            // const accounts = await window.ethereum.request({method:'eth_accounts'});
            console.log("addres2:", address);

            await ConnectWallet();
    
            // if (chainId !== 84532) {
            //     await ChangeNetwork(walletProvider);
            // }
            
    
            // let newUserCheck = await getuserDetailsbywalletNewFirebase(address);
            // if (newUserCheck.length === 0) {
            //     await createprofileNewFirebase(address);
            // }
    
            console.log("account", address);
    
            // Display success toast and wait for it to complete
            // await new Promise(resolve => {
            //     toast.success("Login Successful", {
            //         onClose: resolve,
            //         autoClose: 3000 // Duration of the toast in milliseconds
            //     });
            // });
    
            // navigate("/event");
        } catch (e) {
    
            // Display error toast and wait for it to complete
            await new Promise(resolve => {
                toast.error("Please Connect your Wallet to continue", {
                    onClose: resolve,
                    autoClose: 3000 // Duration of the toast in milliseconds
                });
            });
    
            console.error(e);
        }
    };

    

            return ( 
                <LayoutBeforeLogin>
                <Container>
                <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
                    <Row className="app-blogin-row align-items-center">
                        <Col md={6}>
                            <div className="app-blogin-inner">
                                <div className="d-md-none text-center mb-4">
                                    <img src={LoginIcon} alt="logo icon" />
                                </div>
                                <div className="mb-4 text-center text-md-start">
                                    <h1 className="text-28 text-600 mb-1">Welcome to Divine Dimension!</h1>
                                    {/* <p className="text-500">Enter your registered email & password to log in.</p> */}
                                </div>
    
                                <Form>
                                    {/* <FloatingLabel
                                        controlId="floatingInput.Email"
                                        label="Email"
                                        className="mb-3" 
                                    >
                                        <Form.Control 
                                            type="email" placeholder=" " 
                                            onChange={handleEmailIdChange}
                                        />
                                    </FloatingLabel>
                                    <InputGroup className="mb-3">
                                        <FloatingLabel
                                            controlId="floatingInput.Password"
                                            label="Password"
                                        >
                                            <Form.Control
                                                type={show ? 'text' : "password"}
                                                placeholder=" "
                                                onChange={handlePasswordChange}
                                            />
                                        </FloatingLabel>
                                        <Button variant="reset" onClick={() => setShow(!show)} className="border-0" id="button-addon2">
                                            {show ? 'Hide' : 'Show'}
                                        </Button>
                                    </InputGroup>
                                    <div className="mb-3 d-flex align-items-center justi justify-content-between">
                                        <Form.Check
                                            type='checkbox'
                                            id={`default-checkbox`}
                                            label={`Keep me logged in`}
                                        />
    
                                        <Link className="btn-link" to="forgot-password">Forgot password?</Link>
                                    </div>
                                    {signInLoader ? <>
                                        <Button variant="black" className="d-block w-100 mb-2" ><center><ClockLoader color="#ffffff" size={25} /></center></Button>
                                    </> : <>
                                        <Button variant="black" className="d-block w-100 mb-2" onClick={signInSubmit}>Sign In</Button>
                                    </>}
                                    <p className="px-md-5 px-4 text-center mb-3">By clicking on log in you agreed with the divine dimension’s terms of service & privacy guidelines</p> */}
    
                                    <div className="divider d-flex align-items-center text-nowrap"><span className="px-3">Log in using Wallet</span></div>
    
                                    {/* {coinBaseLoader ? <>
                                        <Button variant="black" className="d-block w-100 mb-2" ><center><ClockLoader color="#ffffff" size={25} /></center></Button>
                                    </> : <>
                                        <Button variant="white" className="btn-icon icon-coinbase w-100 mb-3" onClick={loginViaCoinBase}>Login via Coinbase</Button>
                                    </>}
                                    
                                    {metamaskLoader ? <>
                                        <Button variant="black" className="d-block w-100 mb-2" ><center><ClockLoader color="#ffffff" size={25} /></center></Button>
                                    </> : <>
                                        <Button variant="white" className="btn-icon icon-metamask w-100 mb-3" onClick={loginViaMetaMask}>Login via Metamask</Button>
                                    </>} */}

                                    <Button variant="black" className="btn-icon w-100 mb-3" onClick={loginwithWallet}>Login with Wallet</Button>
    
                                    {/* <div className="text-center">
                                        <Link className="btn-link" to="signup">New here? Create an account</Link>
                                    </div> */}
                                </Form>
    
                            </div>
                        </Col>
                        <Col md={6} className="d-none d-md-block text-center text-md-end">
                            <img src={Preview} alt="Preview" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </LayoutBeforeLogin>
             );
}

export default LogIn;