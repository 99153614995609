import NoFound from "./NoFound";
import { getActivityByWallet } from '../../../awsdatafile';
import { useEffect,useState } from "react";
import { Link} from "react-router-dom";
import { Card,Row } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { getActivityByWalletFirebase } from "../../../FirebaseFunctions";

        
       
export default function Activity() {
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    
    const[getImgreffalgoActivity,setgetImgreffalgoActivity]=useState([]);   
    
    const dbcallalgoActivity=async()=>{        
        if(address  === null || address  === "" || address  === " " || address === undefined || address === ''){
        }
        else{            
        let getalgo=address;   
        // let activitys = await getActivityByWallet(getalgo)   ;
        // setgetImgreffalgoActivity(activitys.data2);
        // console.log("activity",activitys.data2)
        let activitys = await getActivityByWalletFirebase(getalgo)   ;
        setgetImgreffalgoActivity(activitys);
        console.log("activity",activitys)
    }        
}      
useEffect(()=>{
    if(getImgreffalgoActivity.length === 0)
        dbcallalgoActivity()
},[address])
    return(
        // <NoFound />
        <div>
       <Row>
    {getImgreffalgoActivity === null || getImgreffalgoActivity === "" || getImgreffalgoActivity === undefined || getImgreffalgoActivity[0] === null || getImgreffalgoActivity[0] === "" || getImgreffalgoActivity[0] === undefined ?
     (
        <div className="no-found py-5p text-center">
        {/* <h2>No Data Found</h2>                            
       <Link to="/market/explore" className='btn btn-primary'>Browse marketplace</Link> */}
            <center><CircularProgress color="inherit" /></center>
        </div>
    ) : ( 
    <>
    {getImgreffalgoActivity.map((x,index)=>{                
     return(                                     
 <div className="card-dash p-3 d-block border-1 card">
    <div className="activity-item d-flex align-items-center mb-3">        
        <div className="activity-content">                                                
            <p>  {x.txnType}</p>
            <div class='mint-nft-container'>
              <p class="b1 cursor-pointer" >
                <a href={`https://sepolia.basescan.org/tx/${x.txnHash}`} target='_blank'> {x.txnHash}</a></p>                
              <p>{x.txnTime}</p>
            </div>
        </div>
     </div>                                                                                                        
 </div> )
    })}  
    </>
    )}                                                              
    </Row>
  </div>
    )   
}