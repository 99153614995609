import { useEffect, useState } from "react";
import { getEventbywallet } from "../../../awsdatafile"
import { TabFundraising } from "./TabFundraising";
import { CircularProgress } from "@mui/material";
// import { abiLaunchpad2 } from "../../Launchpad/LaunchpadBytesCode";
import { abiLaunchpad2 } from "../../../abi";
// import { abiLaunchpad4 } from "../../Launchpad/LaunchpadBytesCodeOld";
import { ethers } from "ethers";
import web3Instance from "../../../web3Inastance";
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { getEventbywalletFirebase } from "../../../FirebaseFunctions";

export default function Collections() {
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const [eventDetails, setEventDetails] = useState([]);
    const [Details, setDetails] = useState([]);

    const getEventsDetails = async() =>{
        let filterDetails = [];
        // let eventDetailsApi = await getEventbywallet(address);
        // console.log("eventDetails",eventDetailsApi?.data2)
        // filterDetails = (eventDetailsApi?.data2);
        let eventDetailsApi = await getEventbywalletFirebase(address);
        console.log("eventDetails",eventDetailsApi)
        filterDetails = (eventDetailsApi);
        let filteredDetails = filterDetails.sort((a, b) => {
            const order = { "No": 0, "Yes": 1, "Reject": 2 };
            return order[a.approvalStatus] - order[b.approvalStatus];
          });
        setEventDetails(filteredDetails)
        console.log(filterDetails);
    }

    function EpochToDateTime( epochTime ) {
        const date = new Date(epochTime * 1000); // Convert seconds to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      }

    const fun = async() => {
        let counts = [];
        // Use map() with Promise.all() to iterate through eventDetails and perform async operations
        // let eventDetailsApi = await getEventbywallet(address);
        // let filterDetails = (eventDetailsApi?.data2);
        let eventDetailsApi = await getEventbywalletFirebase(address);
        let filterDetails = (eventDetailsApi);
        let filteredDetails = filterDetails.sort((a, b) => {
            const order = { "No": 0, "Yes": 1, "Reject": 2 };
            return order[a.approvalStatus] - order[b.approvalStatus];
          });
    await Promise.all(
        filteredDetails.map(async (r, i) => {
        let contract = "";
      const web3 = web3Instance;
      if (!web3) return;
      let launchpad;
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
  
    //   if (r.email === "0x4930A272dFfcee5706825D698a43514DB4c33989") {
        contract = r.email;
        launchpad = new ethers.Contract(contract, abiLaunchpad2, signer); 
    //   } else {
    //     contract = r.email;
    //     launchpad = new ethers.Contract(contract, abiLaunchpad4, signer);
    //   }
  
      // Retrieve the token count
      const tokenCount1 = web3.utils.toNumber((await launchpad.tokenCount())._hex);
      console.log("count", tokenCount1);

      let endTime1 = web3.utils.toNumber(await launchpad.end_time());
      console.log(endTime1);
      let endTime = EpochToDateTime(endTime1);
      console.log(endTime);
  
      // Update the tokenCount property in the eventDetails array
    //   eventDetails[i].tokenCount = tokenCount1;
    counts.push(tokenCount1);
    filteredDetails[i].tokenCount = tokenCount1;
    filteredDetails[i].counterTime = endTime;
    })
  );
  
  // Now, eventDetails contains the updated tokenCount property for each element
  setDetails(filteredDetails);
  console.log(filteredDetails);
  
    }

    const updateCurrentTime = (dateTime) => {
        const now = new Date(dateTime);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[now.getMonth()];
        const day = now.getDate();
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
    
        const formattedTime = `${month} ${day} ${year} ${hours}:${minutes}:${seconds}`;
        console.log("Formatted Time:", formattedTime);
        return formattedTime;
        // Optionally, you can return formattedTime if you want to use it elsewhere
    };
    

    useEffect(()=>{
        getEventsDetails();
        fun();
    }, [address, isConnected])
    return(
        <>
        <div className="table-stats px-2 py-3 d-none d-lg-flex align-items-center text-gray text-uppercase text-500 text-14">
            <div className="table-stats-count d-flex">#</div>
            <div className="table-stats-collect flex-grow-1">Fundraising Name</div>
            <div className="table-stats-cell d-flex">Type</div>
            <div className="table-stats-cell d-flex">Contract</div>
            <div className="table-stats-cell d-flex">Approval Status</div>
            <div className="table-stats-cell d-flex">Address</div>
            <div className="table-stats-cell d-flex">Details</div>
        </div>
        <div className="border d-none d-lg-block p-2">
        {eventDetails[0] === null || eventDetails[0] === "" || eventDetails[0] === "null" || eventDetails[0] === undefined ?
            (<>
                <center><CircularProgress color="inherit" /></center>
            </>):
            (<>
            {/* {eventDetails.map((r,i) => {
                return(<>
                    <TabFundraising count={i+1} status="up" x={r}/>
                </>)
            })} */}
            {Details.map((r,i) => {
                r.createdTime = updateCurrentTime(r.createdTime);
                r.startTime = updateCurrentTime(r.startTime);
                r.endTime = updateCurrentTime(r.endTime);
                return(<>
                    <TabFundraising count={i+1} status="up" x={r}/>
                </>)
            })}
            
            </>) }
                {/* <div className="text-center py-md-4 py-3">
                <Button variant="white" className="b2 col-md-2 col-5 text-black">Load More</Button>
            </div> */}
        </div>
        </>
    )   
}