import { Col, Row } from "react-bootstrap";
import Ethereum from '../../../asserts/IMAGES/Ethereum-icon.svg'
import Polygon from '../../../asserts/IMAGES/polygon-network-icon.svg'
import Hexa from '../../../asserts/IMAGES/hexa-icon.svg'
import Dime from '../../../asserts/IMAGES/dime-outline.png'

export default function Wallet({ethBalance, dimeBalance}) {

    return(
        <Row className="g-md-4 g-3">
            <Col xs={12} md={6} lg={4}>
                <div className="wallet-card text-white wallet-ethereum">
                    <h4 className="b1">Ethereum</h4>

                    <Row xs={2}>
                        <Col className="d-flex">
                            <span className="me-3"><img src={Ethereum} alt="Ethereum" /></span>
                            <div>
                                <h4 className="mb-0 text-600 text-white">{ethBalance}</h4>
                                <h4 className="mb-0 b3 text-600 text-white">BaseETH</h4>
                                <p className="b3 text-white text-500 opacity-50">{parseInt(parseFloat(ethBalance) * 1834.64)} USD</p>
                            </div>
                        </Col>
                        <Col className="d-flex">
                            <span className="me-3"><img src={Dime} width="35px" alt="Ethereum" /></span>
                            <div>
                                <h4 className="mb-0 text-600 text-white">{dimeBalance}</h4>
                                <h4 className="mb-0 b3 text-600 text-white">DIME</h4>
                                <p className="b3 text-white text-500 opacity-50">{parseInt(dimeBalance)} USD</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
            {/* <Col xs={12} md={6} lg={4}>
                <div className="wallet-card text-white wallet-polygon">
                    <h4 className="b1">Polygon Network</h4>

                    <Row xs={2}>
                        <Col className="d-flex">
                            <span className="me-3"><img src={Polygon} alt="Ethereum" /></span>
                            <div>
                                <h4 className="mb-0 text-600 text-white">0</h4>
                                <h4 className="mb-0 b3 text-600 text-white">POLYGON</h4>
                                <p className="b3 text-white text-500 opacity-50">0.00 USD</p>
                            </div>
                        </Col>
                        <Col className="d-flex">
                            <span className="me-3"><img src={Hexa} alt="Ethereum" /></span>
                            <div>
                                <h4 className="mb-0 text-600 text-white">0</h4>
                                <h4 className="mb-0 b3 text-600 text-white">SAND</h4>
                                <p className="b3 text-white text-500 opacity-50">0.00 USD</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col> */}
        </Row>
    )   
}