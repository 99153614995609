import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import LayoutBeforeLogin from "../component/LayoutBL";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginIcon from '../asserts/IMAGES/logo-icon.svg';
import { SignUpApi, createprofile, createprofileSignUpNew, getSignUp } from '../awsdatafile';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { ClockLoader } from "react-spinners";
import { SignUpApiFirebase, createprofileSignUpNewFirebase, getSignUpFirebase } from "../FirebaseFunctions";

function Signup() {
    const [show, setShow] = useState(false);
    const [showCon, setShowCon] = useState(false);
    const [username, setUsername] = useState('');
    const [emailId, setEmailId] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [signUpLoader, setSignUpLoader] = useState(false);

    const handleSignUpLoad = () => setSignUpLoader(true);
    const handleSignUpHide = () => setSignUpLoader(false);

    let navigate = useNavigate();

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const signUpSubmit = async () => {
        handleSignUpLoad();
        //console.log(username, emailId, walletAddress, password, confirm);
        // let [value, emailExist] = await getSignUp(emailId);
        let [value, emailExist] = await getSignUpFirebase(emailId);
        //console.log("emailExist", emailExist);
        if (username === "" || username === null || username === undefined) {
            toast.error("Please enter your Username!");
            handleSignUpHide();
          } 
          else if (emailId === null || emailId === "" || emailId === undefined) {
            toast.error("Please enter Email ID!");
            handleSignUpHide();
          }
           else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId))) {
            toast.error("Please enter a valid Email ID!");
            handleSignUpHide();
          }     
          else if (walletAddress === null || walletAddress === "" || walletAddress === undefined) {
            toast.error("Please enter Wallet Address!");
            handleSignUpHide();
          }      
          else if (password === null || password === "" || password === undefined) {
            toast.error("Please enter Password!");
            handleSignUpHide();
          }         
          else{
            if (emailExist === undefined) {
                //console.log("validcheck");
                  if (password !== confirm) {
                    toast.error("Password mismatch!");
                    handleSignUpHide();
                  } else {
                    // let status = await SignUpApi(emailId, password, walletAddress, username);
                    let status = await SignUpApiFirebase(emailId, password, walletAddress, username);
                    if(status)
                    {
                        // await createprofileSignUpNew(walletAddress, username);
                        await createprofileSignUpNewFirebase(walletAddress, username);
                        toast.success(
                            `Sign Up Successful.`
                        );
                        await sleep(5000);
                        handleSignUpHide();
                        navigate("/");
                    }
                    else
                    {
                        toast.error(`Sign Up Unsuccessful`);
                        handleSignUpHide();
                    }
                }
              }
              else
              {
                toast.error("Email ID already exist!");
                handleSignUpHide();
              }
          }
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleEmailIdChange = (e) => {
        setEmailId(e.target.value);
    };

    const handleWalletAddressChange = (e) => {
        setWalletAddress(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    
    const handleConfirmPassword = (e) => {
        setConfirm(e.target.value);
    };
    
    return ( 
        <LayoutBeforeLogin>
            <Container fluid>
                <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
                <Row className="app-blogin-row justify-content-center align-items-center">
                    <Col md={6}>
                        <div className="app-blogin-inner">
                            <div className="d-md-none text-center mb-4">
                                <img src={LoginIcon} alt="logo icon" />
                            </div>
                            <div className="mb-4 text-center">
                                <h1 className="text-28 text-600 mb-1">Let set up your account</h1>
                                <p className="text-500">Add up details to help you create an account.</p>
                            </div>

                            <Form>
                                {/* <Row>
                                    <Col xs={6} className="mb-3"> */}
                                        <FloatingLabel
                                            controlId="floatingInput.First"
                                            label="Username"
                                            className="mb-3"
                                        >
                                            <Form.Control 
                                                type="text" placeholder=" "
                                                onChange={handleUsernameChange}
                                            />
                                        </FloatingLabel>
                                    {/* </Col>
                                    <Col xs={6} className="mb-3">
                                        <FloatingLabel
                                            controlId="floatingInput.Last"
                                            label="Last name"
                                        >
                                            <Form.Control type="text" placeholder=" " />
                                        </FloatingLabel>
                                    </Col>
                                </Row> */}
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Email"
                                    className="mb-3"
                                >
                                    <Form.Control 
                                        type="email" placeholder=" "
                                        onChange={handleEmailIdChange}
                                    />
                                    {emailId.length > 0 && (
                                        <div className="form-error">
                                            <ul className="list-unstyled m-0">
                                                <li style={{color: (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) ? '#00BB6C' : '#FB4954'}}>Valid E-mail ID</li>
                                            </ul>
                                        </div>
                                    )}
                                </FloatingLabel>

                                <FloatingLabel
                                    controlId="floatingInput.WalletAddress"
                                    label="Wallet Address"
                                    className="mb-3"
                                >
                                    <Form.Control 
                                        type="text" placeholder=" "
                                        onChange={handleWalletAddressChange}
                                    />
                                </FloatingLabel>

                                <div className="position-relative mb-3">
                                    <InputGroup>
                                        <FloatingLabel
                                            controlId="floatingInput.Password"
                                            label="Password"
                                        >
                                            <Form.Control
                                                type={show ? 'text' : "password"}
                                                placeholder=" "
                                                onChange={handlePasswordChange}
                                            />
                                        </FloatingLabel>
                                        <Button variant="reset" onClick={() => setShow(!show)} className="border-0" id="button-addon2">
                                            {show ? 'Hide' : 'Show'}
                                        </Button>
                                    </InputGroup>
                                    {password.length > 0 && (
                                        <div className="form-error">
                                            <ul className="list-unstyled m-0">
                                                <li style={{color: password?.length > 9 ? '#00BB6C' : '#FB4954'}}>Minimum 10 characters</li>
                                                <li style={{color: /[A-Z]/.test(password) ? '#00BB6C' : '#FB4954'}}>Use at least one UPPERCASE letter</li>
                                                <li style={{color: /[0-9]/.test(password) ? '#00BB6C' : '#FB4954'}}>Add numbers (0,1,2...9)</li>
                                                <li style={{color: /[@#!$%^&*]/.test(password) ? '#00BB6C' : '#FB4954'}}>Use at least one special character (@,#,! etc)</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="position-relative mb-3">
                                    <InputGroup>
                                        <FloatingLabel
                                            controlId="floatingInput.Confirm"
                                            label="Confirm password"
                                        >
                                            <Form.Control
                                                type={showCon ? 'text' : "password"}
                                                placeholder=" "
                                                onChange={handleConfirmPassword}
                                            />
                                        </FloatingLabel>
                                        <Button variant="reset" onClick={() => setShowCon(!showCon)} className="border-0" id="button-addon2">
                                            {showCon ? 'Hide' : 'Show'}
                                        </Button>
                                    </InputGroup>
                                    {confirm.length > 0 && password.toLocaleLowerCase() !== confirm.toLocaleLowerCase() && (
                                        <div className="form-error">
                                            <ul className="list-unstyled m-0">
                                                <li style={{color: '#FB4954'}}>Password doesn't match.</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                
                                {signUpLoader ? <>
                                    <Button variant="black" className="d-block w-100 mb-3"><center><ClockLoader color="#ffffff" size={25} /></center></Button>
                                </> : <>
                                    <Button variant="black" className="d-block w-100 mb-3" onClick={signUpSubmit}>Continue</Button>
                                </>}
                                <p className="px-md-4 text-14 text-500 text-center mb-3">By clicking on Continue you agreed with the divine dimension’s <br /><Link to="/">terms of service</Link> & <Link to="/">privacy guidelines</Link></p>
                                <p style={{textDecoration: "underline"}} className="px-md-4 text-14 text-500 text-center mb-3"><Link to="/">Go Back</Link></p>
                            </Form>

                        </div>
                    </Col>
                </Row>
            </Container>
        </LayoutBeforeLogin>
    )
}

export default Signup;