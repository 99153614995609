import { Form } from "react-bootstrap";
import { Slider } from "rsuite";

function ReligiousFilter() {
    return ( 
        <div className='filter-sidebar'>
            <h4 className="text-600 d-lg-none mb-3">Category</h4>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckChristians" />
                <label className="form-check-label" htmlFor="flexCheckChristians">
                    All <span>(4200)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckHindu" />
                <label className="form-check-label" htmlFor="flexCheckHindu">
                Hindu <span>(2000)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckIslam" />
                <label className="form-check-label" htmlFor="flexCheckIslam">
                Islam <span>(400)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckShinto" />
                <label className="form-check-label" htmlFor="flexCheckShinto">
                Christians <span>(200)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckBuddhism" />
                <label className="form-check-label" htmlFor="flexCheckBuddhism">
                Buddhism <span>(4200)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckSikhism" />
                <label className="form-check-label" htmlFor="flexCheckSikhism">
                Sikhism <span>(2000)</span>
                </label>
            </div>

            <hr />


            <h4 className="text-600 mb-3">Type</h4>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckHorns" />
                <label className="form-check-label" htmlFor="flexCheckHorns">
                People  <span>(200)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckSibire" />
                <label className="form-check-label" htmlFor="flexCheckSibire">
                Holy Places  <span>(80)</span>
                </label>
            </div>

            <hr />

            <h4 className="text-600 mb-3">Sort by</h4>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckHorns" />
                <label className="form-check-label" htmlFor="flexCheckHorns">
                Newest  <span>(200)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckSibire" />
                <label className="form-check-label" htmlFor="flexCheckSibire">
                Trending  <span>(80)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckSibire" />
                <label className="form-check-label" htmlFor="flexCheckSibire">
                Has Offer  <span>(80)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckSibire" />
                <label className="form-check-label" htmlFor="flexCheckSibire">
                On Auction  <span>(80)</span>
                </label>
            </div>  

            <hr />

            <h4 className="text-600 mb-0">Price</h4>
            <p className="">Price Range</p>

            <div className="py-4 my-2">
                <Slider
                    progress
                    defaultValue={5.8}
                    step={0.5}
                    min={0}
                    max={10}    
                    renderMark={mark => {
                        if ([0, 10].includes(mark)) {
                          return <span>{mark} ETH</span>;
                        }
                        return null;
                    }}
                    onChange={value => {
                        console.log(value);
                    }}
                />
                <p className="d-flex text-14 text-500 align-items-center justify-content-between"><span>0 ETH</span><span>10 ETH</span></p>
            </div>

            <Form.Select aria-label="Default select example">
                <option>Low to high Price</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </Form.Select>

        </div>
     );
}

export default ReligiousFilter;