import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Col, Dropdown, FloatingLabel, Form, InputGroup, Nav, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import heritage from '../../asserts/IMAGES/heritage.jpeg'
import unesco from '../../asserts/IMAGES/Unesco.png'
import Monuments from '../../asserts/IMAGES/Monuments.png'
import Pangea from '../../asserts/IMAGES/pangea_4k.png'
import ExploreShopFilter from '../../Snippets/ExploreShopFilter';
import { CardViewShop } from '../../Snippets/CardView';
import { getNFTDetailsByTypeSale, getNFTDetailsByTypeSaleBycategory } from '../../awsdatafile';
import { useLocation } from 'react-router-dom';
import './Map.css';

function Map() {
    const location = useLocation();
    const [star, setStar] = useState(false);
    const [saleImage, setsaleImage] = useState([""]);
    

    // const zoomin = async() => {
    //     var GFG = document.getElementById("geeks");
    //     var currHeight = GFG.clientHeight;
    //     var currwidth = GFG.clientWidth;
    //     console.log("currnhe",currHeight)
    //         GFG.style.height = (currHeight + 40) + "px";
    //         GFG.style.width = (currwidth + 40) + "px";
    // }
    // const zoomout = async() => {
    //     var GFG = document.getElementById("geeks");
    //     var currHeight = GFG.clientHeight;
    //     var currwidth = GFG.clientWidth;
    //         GFG.style.height = (currHeight - 40) + "px";
    //         GFG.style.width = (currwidth - 40) + "px";
    // }
    const [zoom, setZoom] = useState(100); // Initial zoom level is 100%
    const [pan, setPan] = useState({ x: 0, y: 0 }); // Initial pan position is (0, 0)
  
    const handleMouseMove = (e) => {
        if (e.buttons !== 1) return; // Check if left mouse button is pressed (for panning)
      
        // Define a pan speed factor (adjust this value as needed)
        const panSpeed = 50;
      
        // Calculate the cursor movement relative to the previous position and apply the pan speed
        const dx = e.movementX * panSpeed;
        const dy = e.movementY * panSpeed;
      
        // Update pan position based on cursor movement
        setPan({ x: pan.x + dx, y: pan.y + dy });
      };
  
      const handleZoomIn = () => {
        if (zoom < 1000) {
          setZoom(zoom + 20); // Increase zoom factor
        }
      };
    
      const handleZoomOut = () => {
        if (zoom > 20) {
          setZoom(zoom - 20); // Decrease zoom factor
        }
      };

    return ( 
        <>
        {/* <img
        src={Pangea}
        id="geeks"
        style={{
            maxWidth: '100%',
            maxHeight: '100vh', // Set maximum height of the image to the viewport height
            width: 'auto',
            height: 'auto',
            display: 'block', // To remove any extra spacing
            margin: '0 auto' // To horizontally center the image
        }}
        alt="Pangea Image"
    /> */}
     {/* <div className='map-controls d-flex flex-column align-items-center'>
        <button onClick={() => zoomin()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
            </svg>
        </button>
        <button onClick={() => zoomout()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
            </svg>
        </button>
    </div> */}
      <div
        className="image-container"
        onMouseMove={handleMouseMove}
        style={{
          overflow: 'hidden', // Hide overflowing content
          width: '100vw',
          height: '100vh',
        }}
      >
        <img
          src={Pangea} // Make sure to import or provide the image URL here
          id="geeks"
          style={{
            width: `${zoom}%`,
            height: 'auto',
            display: 'block',
            margin: '0 auto',
            transform: `translate(${pan.x}px, ${pan.y}px)`, // Apply pan translation
            transition: 'transform 0.2s ease-out', // Add a transition for smoother panning
          }}
          alt="Pangea Image"
        />
      </div>
      <div className="button-container justify-content-end">
        <button className="button me-2" onClick={handleZoomIn}>+</button>
        <button className="button" onClick={handleZoomOut}>-</button>
      </div>

         

           

          
            
        </>
     );
}

export default Map;