import { Badge, Button, Col, Row, Stack } from "react-bootstrap";
import BackIcon from '../../asserts/IMAGES/back-icon.svg';
import { Link, useNavigate } from "react-router-dom";
import EventCard from "../../Snippets/EventCard";
import DotsConnect from '../../asserts/IMAGES/dots-connect.svg'
import LinkIcon from '../../asserts/IMAGES/link-icon.svg'
import CalendarAdd from '../../asserts/IMAGES/calendar-add.svg'
import Avatar from '../../asserts/IMAGES/avatar-pic.png'
import Poster from '../../asserts/IMAGES/event-poster.png'
import Location from '../../asserts/IMAGES/location-minus.svg'
import Calendar from '../../asserts/IMAGES/calendar.svg'

function EventSingle() {
    const navigate = useNavigate();

    return ( 
        <>
            <Row className="mb-2">
                <Col lg={6} className="mb-lg-2 mb-3">
                    <h1 className="mb-2 text-600 d-flex align-items-start">
                        <Button variant="reset" className="p-0 border-0 btn-back me-md-3 me-2" onClick={() => navigate(-1)}>
                            <img src={BackIcon} alt="back" className="d-block" />
                        </Button>
                        Metaverse Pride 2023: Diversity & Inclusivity in the Virtual World!
                    </h1>
                    <p className='b2'>Organized by <span className='text-block'>PolygonalMind</span></p>
                    <Stack direction="horizontal" gap={2}>
                        <Badge bg="danger">Live</Badge>
                        <Badge bg="success">Public Event</Badge>
                    </Stack>
                </Col>
                <Col lg={6} className="mb-2 d-flex flex-lg-column align-items-lg-end justify-content-between">
                    <ul className="list-unstyled user-list m-0 p-0 d-flex">
                        <li><img src={Avatar} alt="user" /></li>
                        <li><img src={Avatar} alt="user" /></li>
                        <li><img src={Avatar} alt="user" /></li>
                        <li><img src={Avatar} alt="user" /></li>
                        <li><img src={Avatar} alt="user" /><span className="text-14 text-600">+39</span></li>
                    </ul>

                    <div className={`event-btns position-static d-flex align-items-center justify-content-end`}>
                        <Link className='btn me-3 btn-sm btn-white d-none d-sm-inline-flex align-items-center'><span className='px-xl-3'>Jump In</span> <img className='d-block ms-3' src={LinkIcon} alt="LinkIcon" /></Link>
                        <Button variant='white' size="sm"><img className='d-block' src={DotsConnect} alt="DotsConnect" /></Button>
                    </div>
                </Col>
            </Row>

            <img src={Poster} alt="Poster" className='event-poster mb-md-5 mb-sm-4 mb-2 d-block w-100' />

            <Link className='btn me-3 btn-sm btn-white d-flex w-100 mb-4 d-sm-none align-items-center'><span className='mx-auto'>Jump In</span> <img className='d-block ms-3' src={LinkIcon} alt="LinkIcon" /></Link>

            <Row className="text-500 mb-lg-5 mb-4 text-block">
                <Col md={8} className="mb-md-0 mb-4 pe-lg-4 pe-xl-5">
                    <p>Join us for Metaverse Pride 2023, returning to Divine Dimension for a 3rd consecutive year in collaboration with creative studio, 3D Polygonal Mind and Vegas City. Set to take place from June 27-29, this event unites communities in celebration of diversity and inclusion in the metaverse.</p>

                    <p>Prepare to embark on a three-day experience filled with captivating content and engaging activities that highlight the metaverse's vibrant LGTBQIA+ community. Enjoy live musical performances and immersive games, share unforgettable moments with the DCL community, and claim fabulously exclusive wearables.</p>

                    <p>More info to come soon!</p>
                </Col>
                <Col md={4} className="border-md-start">
                    <div className="px-xl-4">
                        <p className="d-flex align-items-center"><img src={Location} alt="Location" className="me-2" /> Genesis Plaza</p>
                        
                        <div className="d-flex align-items-center mt-lg-4 mt-3">
                            <div className="d-flex pe-1 flex-grow-1 align-items-start">
                                <img src={Calendar} className="me-2" alt="calender" />
                                <div className="flex-grow-1">
                                    <span className="text-gray">From:</span> Tuesday, 27 Jun - 05:00am <br />
                                    <span className="text-gray">To:</span> Wednesday, 28 Jun - 05:00am
                                </div>
                            </div>
                            <Button variant='white' size="sm"><img className='d-block' src={CalendarAdd} alt="CalendarAdd   " /></Button>
                        </div>
                        {/*  */}
                        <div className="d-flex align-items-center mt-lg-4 mt-3">
                            <div className="d-flex pe-1 flex-grow-1 align-items-start">
                                <img src={Calendar} className="me-2" alt="calender" />
                                <div className="flex-grow-1">
                                    <span className="text-gray">From:</span> Tuesday, 27 Jun - 05:00am <br />
                                    <span className="text-gray">To:</span> Wednesday, 28 Jun - 05:00am
                                </div>
                            </div>
                            <Button variant='white' size="sm"><img className='d-block' src={CalendarAdd} alt="CalendarAdd   " /></Button>
                        </div>
                        {/*  */}
                        <div className="d-flex align-items-center mt-lg-4 mt-3">
                            <div className="d-flex pe-1 flex-grow-1 align-items-start">
                                <img src={Calendar} className="me-2" alt="calender" />
                                <div className="flex-grow-1">
                                    <span className="text-gray">From:</span> Tuesday, 27 Jun - 05:00am <br />
                                    <span className="text-gray">To:</span> Wednesday, 28 Jun - 05:00am
                                </div>
                            </div>
                            <Button variant='white' size="sm"><img className='d-block' src={CalendarAdd} alt="CalendarAdd   " /></Button>
                        </div>
                        {/*  */}
                    </div>
                </Col>
            </Row>

            <div className="mb-4 pt-3">
                <h1 className="text-600 mb-2">Similar Events</h1>
                <p className="b2">June 2023</p>
            </div>

            <Row>
                <Col sm={6} lg={4} className="mb-3">
                    <EventCard approval={false} type="upcoming" size="sm" />
                </Col>
                <Col sm={6} lg={4} className="mb-3">
                    <EventCard approval={false} type="upcoming" size="sm" />
                </Col>
            </Row>
        </>
     );
}

export default EventSingle;