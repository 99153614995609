function AvatarFilter() {
    return ( 
        <div className='filter-sidebar'>
            <h4 className="text-600 d-lg-none mb-3">Type</h4>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckChristians" />
                <label className="form-check-label" htmlFor="flexCheckChristians">
                    Christians <span>(4200)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckHindu" />
                <label className="form-check-label" htmlFor="flexCheckHindu">
                    Hindu <span>(2000)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckIslam" />
                <label className="form-check-label" htmlFor="flexCheckIslam">
                    Islam <span>(400)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckShinto" />
                <label className="form-check-label" htmlFor="flexCheckShinto">
                    Shinto <span>(200)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckBuddhism" />
                <label className="form-check-label" htmlFor="flexCheckBuddhism">
                    Buddhism <span>(400)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckSikhism" />
                <label className="form-check-label" htmlFor="flexCheckSikhism">
                    Sikhism <span>(200)</span>
                </label>
            </div>


            <hr />


            <h4 className="text-600 mb-3">Materials</h4>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckHorns" />
                <label className="form-check-label" htmlFor="flexCheckHorns">
                    Horns <span>(4200)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckSibire" />
                <label className="form-check-label" htmlFor="flexCheckSibire">
                    Sibire <span>(2000)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckPointed" />
                <label className="form-check-label" htmlFor="flexCheckPointed">
                    Pointed <span>(400)</span>
                </label>
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="flexCheckBlunt" />
                <label className="form-check-label" htmlFor="flexCheckBlunt">
                    Blunt <span>(200)</span>
                </label>
            </div>

            
            <hr />

            <h4 className="text-600 mb-3">Special</h4>

            <hr />
            <h4 className="text-600 mb-3">Clothing</h4>

            <hr />
            <h4 className="text-600 mb-3">Offband</h4>
        </div>
     );
}

export default AvatarFilter;