import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Grid, Typography, Paper, CircularProgress } from '@mui/material';
import { styled } from '@mui/material';
import { ethers } from 'ethers';
import { FanTokenABI } from '../../abi';
import { FanTokenByteCode } from '../../abiBytecode';
// import FanTokenABI from './FanToken.json';
// import { FanTokenByteCode } from './FanTokenByteCode';
import { ClockLoader } from 'react-spinners';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const FanTokenForm = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [name, setName] = useState('');
  const [symbol, setSymbol] = useState('');
  const [totalSupply, setTotalSupply] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [deploying, setDeploying] = useState(false);
  const [minting, setMinting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mintLoading, setMintLoading] = useState(false);
  const [account, setAccount] = useState(address? address : '');
  const [amount, setAmount] = useState('');

  const deployContract = async () => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const bytecode = FanTokenByteCode;
      const abi = FanTokenABI;
      const factory = new ethers.ContractFactory(abi, bytecode, signer);
      const contract = await factory.deploy(name, symbol, totalSupply);
      await contract.deployed();
      const deployedContractAddress = contract.address;
      setContractAddress(deployedContractAddress);
      setDeploying(false);
      toast.success("Fan token created");
    } catch (error) {
      console.error('Error deploying contract:', error);
      toast.error("Error creating Fan tokens")
      setDeploying(false);
    } finally {
        setLoading(false);
    }
}

  const handleMint = async () => {
    try {
        setMintLoading(true);
        if (window.ethereum) {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
        } else {
          throw new Error('Ethereum provider (MetaMask) not detected.');
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, FanTokenABI, signer);
        await contract.mint(account, amount);
        setAmount('');
        setMinting(false);
        toast.success("Fan token minted");
      } catch (error) {
        console.error('Error minting tokens:', error);
        setMinting(false);
        toast.success("Error minting Fan tokens");
      } finally {
        setMintLoading(false);
      }
  };

  useEffect(() => {
    setAccount(address);
  }, [address, isConnected])

  return (
    <Container component="main" maxWidth="lg">
    <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
    <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledPaper elevation={3} style={{ flex: 1, marginRight: '16px' }}>
        <Typography component="h1" variant="h5">
          Create Your Fan Token
        </Typography>
        <StyledForm>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label="Name"
            name="name"
            type='text'
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="symbol"
            label="Symbol"
            type='text'
            name="symbol"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
            required
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="totalSupply"
            label="Total Supply"
            name="totalSupply"
            value={totalSupply}
            type='number'
            onChange={(e) => setTotalSupply(e.target.value)}
            required
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              setDeploying(true);
              deployContract();
            }}
            disabled={deploying || loading} // Disable the button if deploying or loading
            startIcon={loading && <ClockLoader size={20} />} // Display loader while loading
          >
            {loading ? 'Creating Fan Token...' : 'Create my Fan Token'}
          </Button>
          {/* {contractAddress && (
            <p className='mt-4'>Token Address: <a href={`https://sepolia.basescan.org/address/${contractAddress}`} target="_blank" rel='noreferrer' style={{color:"blueviolet"}}>{contractAddress}</a></p>
          )} */}
        </StyledForm>
        </StyledPaper>
        <StyledPaper elevation={3} style={{ flex: 1 }}>
            <Typography component="h2" variant="h5">
              Mint Tokens
            </Typography>
        <StyledForm onSubmit={handleMint}>
          <div>
            <TextField
              margin="normal"
              fullWidth
              id="contractAddress"
              name="contractAddress"
              autoFocus
              label="Contract Address"
              variant="outlined"
              value={contractAddress}
              onChange={(e) => setContractAddress(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              margin="normal"
              fullWidth
              id="accountAddress"
              name="accountAddress"
              autoFocus
              label="Account Address"
              variant="outlined"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
              required
            />
          </div>
          <div>
            <TextField
              margin="normal"
              fullWidth
              id="amount"
              name="amount"
              autoFocus
              label="Amount"
              variant="outlined"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              setMinting(true);
              handleMint();
            }}
            disabled={minting || mintLoading} // Disable the button if deploying or loading
            startIcon={mintLoading && <ClockLoader size={20} />} // Display loader while loading
          >
            {mintLoading ? 'Minting Token...' : 'Mint Token'}
          </Button>
        </StyledForm>
        </StyledPaper>
    </div>
    </Container>
  );
};

export default FanTokenForm;