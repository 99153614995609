import { Web3 } from 'web3';
// import { abierc20, contracterc20 } from './pages/Launchpad/LaunchpadContract';
import { abierc20 } from './abi';
import { dimeAddress as contracterc20 } from './address';
import { ethers } from 'ethers';
import { useWeb3ModalAccount, useWeb3ModalProvider, useWeb3Modal } from '@web3modal/ethers5/react';


export const config = {
  chainId: 84532,
  chainIdHex: '0x14a34',
  name: 'Base Sepolia',
  currency: 'ETH',
  explorerUrl: 'https://sepolia.basescan.org/',
  rpcUrl: 'https://sepolia.base.org'
}
const API_KEY ="DGZJ4YRDXJCJ4MDKBWR8MSRHZ3VC75DKYH"
const Network_Name ="Base Sepolia"
// export const provider = ethers.getDefaultProvider(Network_Name, {
//   etherscan: API_KEY});
export const provider = ethers.getDefaultProvider('https://sepolia.base.org');


export const dimeWalletBalance = async(web3) => {

    if (!web3) return web3;
    const erc20token = new web3.eth.Contract(abierc20, contracterc20);
    if(localStorage.getItem("walletAddress")!== null && localStorage.getItem("walletAddress") !== "" ){
        let userBalance = web3.utils.toNumber(await erc20token.methods.balanceOf(localStorage.getItem("walletAddress")).call())
        return userBalance;
    }
    else{
        return 0;
    }
}

export const ethWalletBalance = async(web3) => {
  if(localStorage.getItem("walletAddress") && localStorage.getItem("walletAddress")!== null && localStorage.getItem("walletAddress") !== "" ){
  const balanceWei = await web3.eth.getBalance(localStorage.getItem("walletAddress"));
  const balanceEther = web3.utils.fromWei(balanceWei, "ether");
  let ethBalance = parseFloat(balanceEther).toFixed(5);
  return ethBalance;
  }
  else
  {
    return 0;
  }
}

export const ConnectWallet = async () => {
  const { open } = useWeb3Modal();
  await open();
};

export const ChangeNetwork = async (walletProvider) => {
  try {
    await walletProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: config.chainIdHex }],
    });
  } catch (switchError) {
    if (switchError.code === config.chainId) {
      try {
        await walletProvider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: config.chainIdHex,
              chainName: config.chainName,
              rpcUrls: [config.rpcUrl],
              blockExplorerUrls: [config.explorerUrl],
            },
          ],
        });
      } catch (addError) {
        throw addError;
      }
    }
  }
};