import Avatar from '../asserts/IMAGES/av-card.png';

function AvatarCard(props) {
    return ( 
        <div className="card-avatar">
            <div className="card-avatar-header d-flex align-items-center justify-content-between">
                <h4 className="text-600 m-0">Malebit</h4>
                <h5 className="text-18 text-500 m-0">#4983</h5>
            </div>
            <div className="card-avatar-body text-center">
                <div className='card-avatar-color' style={{backgroundColor: props.bgColor}}></div>
                <img src={Avatar} alt='avatar' className='img-fluid' />
            </div>
        </div>
     );
}

export default AvatarCard;