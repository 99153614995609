import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from "react-bootstrap";
import BackIcon from '../../asserts/IMAGES/back-icon.svg';
import { Link, useNavigate } from "react-router-dom";
import ImageIcon from '../../asserts/IMAGES/image-icon.svg'
import DateTimePicker from "../../Snippets/DateTimePicker";

function CreateEvent() {
    const navigate = useNavigate();

    return ( 
        <>
            <Row className="mb-40">
                <Col lg={12}>
                    <h1 className="mb-2 text-600 d-flex align-items-start">
                        <Button variant="reset" className="p-0 border-0 btn-back me-md-3 me-2" onClick={() => navigate(-1)}>
                            <img src={BackIcon} alt="back" className="d-block" />
                        </Button>
                        Create Event
                    </h1>
                    <p className='b1'>Be sure to fill in as many details as possible to generate interest in your event.</p>
                </Col>
            </Row>

            <Container fluid={"sm"} className="px-0 mb-5">
                <input type="file" id="upload" hidden />
                <label htmlhtmlFor="upload" className="upload-area mb-4 form-control d-flex">
                    <div className="m-auto text-center">
                        <Button variant='white' className="mb-3" size="sm"><img className='d-block' src={ImageIcon} alt="DotsConnect" /></Button> 
                        <p><span className="text-block">Browse your computer</span> <br />(recommended size: 1340 x 670)</p>
                    </div>
                </label>

                <Row>
                    <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Event Title"
                        >
                            <Form.Control
                                type='text'
                                placeholder=" "
                            />
                        </FloatingLabel>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Category"
                            label="Event Category"
                        >
                            <Form.Select aria-label="Floating label select example">
                                <option>Select category</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col sm={12} className="mb-3">
                        <FloatingLabel controlId="floatingTextarea2" label="Description">
                            <Form.Control
                                as="textarea"
                                placeholder=" "
                                style={{ height: '140px' }}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <DateTimePicker label="Start date" type={'date'} />
                    </Col>
                    <Col xs={6} className="mb-3">
                        <DateTimePicker label="Start time" type={'time'} />
                    </Col>
                    <Col xs={6} className="mb-3">
                        <DateTimePicker label="End date" type={'date'} />
                    </Col>
                    <Col xs={6} className="mb-3">
                        <DateTimePicker label="End time" type={'time'} />
                    </Col>
                    <Col sm={4} xs={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Latitude (X)"
                        >
                            <Form.Control
                                type='text'
                                value="0"
                                placeholder=" "
                            />
                        </FloatingLabel>
                    </Col>
                    <Col sm={4} xs={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Longitude (Y)"
                        >
                            <Form.Control
                                type='text'
                                value="0"
                                placeholder=" "
                            />
                        </FloatingLabel>
                    </Col>
                    <Col sm={4} xs={12} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Category"
                            label="Server"
                        >
                            <Form.Select aria-label="Floating label select example">
                                <option>Any Server</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Email or discord name"
                        >
                            <Form.Control
                                type='text'
                                disabled={true}
                                placeholder=" "
                                value="hello@divinedimension.io"
                            />
                        </FloatingLabel>
                    </Col>
                    <Col sm={6} className="mb-3">
                        <FloatingLabel
                            controlId="floatingInput.Title"
                            label="Additional info"
                        >
                            <Form.Control
                                type='text'
                                placeholder=" "
                            />
                        </FloatingLabel>
                    </Col>
                    <Col xs={6} className="mb-3 pt-2">
                        <Button type="reset" className="px-md-4 b2 px-3" variant="white">Cancel</Button>
                    </Col>
                    <Col xs={6} className="mb-3 text-end pt-2">
                        <Button type="reset" className="px-md-5 b2 px-4" variant="black">Create Event</Button>
                    </Col>
                </Row>
            </Container>
        </>
     );
}

export default CreateEvent;