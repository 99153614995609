import { Button, Card, Col, Dropdown, Row, Stack, Table } from "react-bootstrap";
import Down from '../../asserts/IMAGES/down-danger.svg'
import Link from '../../asserts/IMAGES/external-link.svg'
// import Bar from "./BarChart";
import { useState,useEffect } from "react";
import { getNFTDetailsByAddressTypeSale } from "../../awsdatafile";
import { useLocation } from "react-router-dom";
// import {abiauction} from '../abi/Auctionnftcontract';
import { abiauctionNFT as abiauction } from "../../abi";
import {Web3, utils} from 'web3';
import web3Instance from '../../web3Inastance';
import { CircularProgress } from "@mui/material";
import { getNFTDetailsByAddressTypeSaleFirebase } from "../../FirebaseFunctions";



export default function Properties({web3State}) {


    const location = useLocation();
    const dataParam = new URLSearchParams(location.search).get('address');
    const[getData,setgetData]=useState([]);
    const[nftList, setNftList] = useState([]);
    // const[allAuction, setAllAuction] = useState([]);
    const inittotPrice = 0.0000;
    const[totalPrice, setTotalPrice]=useState(inittotPrice.toFixed(4));
    const[floorPrice, setFloorPrice]=useState(inittotPrice.toFixed(4));
    const[bestOffer, setBestOffer]=useState(inittotPrice.toFixed(4));
    const[mynftCount, setMynftCount] = useState(0);
    const[royaltyCount, setRoyaltyCount] = useState(0);
    const[auctionCount, setAuctionCount] = useState(0);



    // const connectToEthereum = async () => {
    //     try {
    //       if (window.ethereum) {
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         const web3 = new Web3(window.ethereum);
    //         return web3;
    //       } else {
    //         throw new Error('No Ethereum wallet found.');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       return null;
    //     }
    //   };
   

    // const dbcallEvents=async()=>{  
    //     const web3 = await connectToEthereum();   
        
    //     let nftDetails = [];
        
    //     let nftDetailsyes = await getNFTDetailsByAddressTypeSale(dataParam, "Auction", "yes");

    //     let nftDetailsno = await getNFTDetailsByAddressTypeSale(dataParam, "Auction", "no");
        
    //     nftDetails = [...nftDetailsyes?.data2, ...nftDetailsno?.data2];

    //     setAllAuction(nftDetails);
        
    // } 

    

    const getmyNft = async() =>{
        const web3 = web3Instance;   
        if(dataParam === null || dataParam === "" || dataParam === undefined || dataParam === "undefined"){

        }else{
            let allNfts = [];
            // let mynft = await getNFTDetailsByAddressTypeSale(dataParam,"Normal","yes");
            // // console.log("mynft.data2[0]", mynft.data2[0]);
            // if(mynft.data2[0])
            //     (mynft.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            // let mynft1 = await getNFTDetailsByAddressTypeSale(dataParam,"Normal","no");
            // let mynftDetails = [...mynft?.data2, ...mynft1?.data2]
            let mynft = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Normal","yes");
            // console.log("mynft.data2[0]", mynft.data2[0]);
            if(mynft[0])
                (mynft).map((r,i)=>{
                    allNfts.push(r);
                })
            let mynft1 = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Normal","no");
            let mynftDetails = [...mynft, ...mynft1]
            console.log("mynft",allNfts)


            // let royalty = await getNFTDetailsByAddressTypeSale(dataParam,"Royalty","yes");
            // // console.log("royalty.data2[0]", royalty.data2[0]);
            // if(royalty.data2[0])
            //     (royalty.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            // let royalty1 = await getNFTDetailsByAddressTypeSale(dataParam,"Royalty","no");
            // let royaltyDetails = [...royalty?.data2, ...royalty1?.data2]
            let royalty = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Royalty","yes");
            // console.log("royalty.data2[0]", royalty.data2[0]);
            if(royalty[0])
                (royalty).map((r,i)=>{
                    allNfts.push(r);
                })
            let royalty1 = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Royalty","no");
            let royaltyDetails = [...royalty, ...royalty1]
                     
            // let auction = await getNFTDetailsByAddressTypeSale(dataParam,"Auction","yes");
            // // console.log("auction.data2[0]", auction.data2[0]);
            // if(auction.data2[0])
            //     (auction.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })    
            // setNftList(allNfts);
            // console.log("allNFT", allNfts);
            // let auction1 = await getNFTDetailsByAddressTypeSale(dataParam,"Auction","no");
            // let auctionDetails = [...auction?.data2, ...auction1?.data2]
            let auction = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Auction","yes");
            // console.log("auction.data2[0]", auction.data2[0]);
            if(auction[0])
                (auction).map((r,i)=>{
                    allNfts.push(r);
                })    
            setNftList(allNfts);
            console.log("allNFT", allNfts);
            let auction1 = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Auction","no");
            let auctionDetails = [...auction, ...auction1]

            // Calculate the total price using reduce
            const totalNftPrice = allNfts.reduce((total, x) => total + parseFloat(x.nftPrice) / 1000000000000000000, 0);
            const totalPriceFormatted = totalNftPrice.toFixed(4);

            setTotalPrice(totalPriceFormatted);

            // Find the lowest nftPrice in the nftList array
            const lowestNftPrice = allNfts.reduce((lowestPrice, nft) => {
                const currentPrice = parseFloat(nft.nftPrice) / 1000000000000000000;
                if (lowestPrice === null || currentPrice < lowestPrice) {
                    return currentPrice;
                }
                return lowestPrice;
            }, null);
            const lowestNftPriceFormatted = lowestNftPrice !== null ? lowestNftPrice.toFixed(4) : inittotPrice.toFixed(4);;

            setFloorPrice(lowestNftPriceFormatted);

            let r =[];

            await Promise.all(allNfts.filter((x) => (x.nftType === "Auction")).map(async(x) => {
               
                const contract = new web3.eth.Contract(abiauction,x.nftAddress);
                let auctionItems =await contract.methods.auctionitems(x.escrowAddress).call()
    
                r.push(web3.utils.toNumber(parseFloat(auctionItems?.highestbidamount) / 1000000000000000000).toFixed(4));
            })
            );
    
              // Find the highest bid amount
             const highestNftPrice = r.reduce((highestPrice, currentPrice) => {
            if (highestPrice === null || currentPrice > highestPrice) {
                return currentPrice;
            }
            return highestPrice;
        }, null);
    
        // Convert to formatted string if needed
        const highestNftPriceFormatted = highestNftPrice !== null ? highestNftPrice : null;
        setBestOffer(highestNftPriceFormatted);
    
        console.log("highestNftPrice", highestNftPriceFormatted);
        setMynftCount(mynftDetails.length);
        setRoyaltyCount(royaltyDetails.length);
        setAuctionCount(auctionDetails.length);


        }
       
    }

    useEffect(() => {
        if(dataParam != undefined && dataParam != null && dataParam != "")
        // dbcallEvents();
        getmyNft();
    },[])



    return(
        <div className="border py-md-3 py-2 px-md-4 px-2">
            {mynftCount === 0 || auctionCount === 0 || royaltyCount === 0 ? <>
                <center><CircularProgress color="inherit" /></center>
            </> : <>
                <Row className="g-md-3 g-2" xs={1} sm={2} lg={4}>
                    <Col>
                        <div className="border p-3">
                            <p className="text-500 mb-2 text-13">Total price</p>
                            <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>{totalPrice}</span> <span>ETH</span></h6>
                        </div>
                    </Col>
                    <Col>
                        <div className="border p-3">
                            <p className="text-500 mb-2 text-13">Floor price</p>
                            <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>{floorPrice}</span> <span>ETH</span></h6>
                        </div>
                    </Col>
                    <Col>
                        <div className="border p-3">
                            <p className="text-500 mb-2 text-13">Best offer</p>
                            <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>{bestOffer}</span> <span>ETH</span></h6>
                        </div>
                    </Col>
                    <Col>
                        <div className="border p-3">
                            <p className="text-500 mb-2 text-13">Normal</p>
                            <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>{mynftCount}</span> <span>NFTs</span></h6>
                        </div>
                    </Col>
                    <Col>
                        <div className="border p-3">
                            <p className="text-500 mb-2 text-13">Royalty</p>
                            <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>{royaltyCount}</span> <span>NFTs</span></h6>
                        </div>
                    </Col>
                    <Col>
                        <div className="border p-3">
                            <p className="text-500 mb-2 text-13">Auction</p>
                            <h6 className="b2 text-black d-flex mb-0 justify-content-between lh-1"><span>{auctionCount}</span> <span>NFTs</span></h6>
                        </div>
                    </Col>
                </Row>
            </>}
        </div>
    )
}
