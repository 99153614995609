import { NavLink } from 'react-router-dom';
import BannerImage from '../../asserts/IMAGES/banner-card-image.png'
import FireIcon from '../../asserts/IMAGES/fire-icon.svg'
import { Col, Nav, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';

import BannerImageMob from '../../asserts/IMAGES/explore-banner-image.png'

import 'swiper/scss';
import 'swiper/scss/navigation';
import { CardViewVertical } from '../../Snippets/CardView';
import { useState,useEffect } from 'react';
import ExploreSingleFilter from '../../Snippets/ExploreSingleFilters';
import { getAlluserDetails, getAlluserDetailsNew } from '../../awsdatafile';
import { CircularProgress } from '@mui/material';
import { getAlluserDetailsNewFirebase } from '../../FirebaseFunctions';

function MarketExploreSingle() {
    const [star, setStar] = useState(false);
    const [userProfile, setuserProfile] = useState([""]);
    const [profile,setProfile]=useState("");
    const [sortby,setSortby]=useState("");
    const [sortProfile,setSortProfile]=useState([""]);
    
    const getProfile1=(val)=>{
        console.log(val)
        setProfile(val)
    }

    const sort_Profile1=(val)=>{
        console.log("sort "+val)
        setSortby(val)
        if(val=== "")
        {
            setuserProfile(userProfile.sort((a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime()))
        }
        if(val==="NtoO")
        {  const sortedArray = userProfile.sort((a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime());
            console.log(sortedArray);
            setSortProfile(sortedArray);

        }
        if(val==="OtoN")
        {  const sortedArray = userProfile.sort((a, b) => new Date(a.creationTime).getTime() - new Date(b.creationTime).getTime());
            console.log(sortedArray);
            setSortProfile(sortedArray);

        }
        if(val==="AtoZ")
        {  const sortedArray = userProfile.sort((a, b) => {
            if (a.firstName === null) return 1; 
            if (b.firstName === null) return -1;
            return a.firstName.localeCompare(b.firstName)});
            console.log(sortedArray);
            setSortProfile(sortedArray);

        }
        if(val==="ZtoA")
        { const sortedArray = userProfile.sort((a, b) =>{ 
            if (a.firstName === null) return 1; 
            if (b.firstName === null) return -1;
            return b.firstName.localeCompare(a.firstName)});
            console.log(sortedArray);
            setSortProfile(sortedArray);
        }
        

    }

    const getnormalNFtdetails = async() =>{
        // let userprofiles = await getAlluserDetailsNew();
        // console.log("userprofile",userprofiles?.data2)
        // console.log("reverse",(userprofiles?.data2).reverse())
        // let filteredUserProfile = userprofiles?.data2.filter((x) => (x.firstName))
        let userprofiles = await getAlluserDetailsNewFirebase();
        console.log("userprofile",userprofiles)
        console.log("reverse",(userprofiles).reverse())
        let filteredUserProfile = userprofiles?.filter((x) => (x.firstName))
        setuserProfile((filteredUserProfile))
    }
    useEffect(()=>{getnormalNFtdetails()},[]) 
    return ( 
        <>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">Marketplace</h1>
            </div>

            <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/market/explore">Explore</NavLink>
                    <NavLink className="nav-link" to="/market/create">Create</NavLink>
                    <NavLink className="nav-link" to="/market/mint">Mint</NavLink>
                    <NavLink className="nav-link" to="/market/crossmint">Crossmint</NavLink>
                    <NavLink className="nav-link" to="/market/explore/single">Trending Collection</NavLink>
                   
                    <NavLink className="nav-link" to="/market/explore/shop/heritage">Heritage</NavLink>
                    <NavLink className="nav-link" to="/market/explore/shop/royalty">Artifact</NavLink>
                  
                    <NavLink className="nav-link" to="/market/explore/shop/auction">Auction</NavLink>
                </div>
            </div>

            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-flex px-100">
                <img src={BannerImageMob} className='d-md-none' alt="image" />    
                {/* <div className='page-banner-card d-md-inline-block d-none'>
                    <div className='page-banner-card-image'>
                        <img src={BannerImage} className='img-fluid' alt='Banner Image' />

                        <button type='button' onClick={() => setStar(!star)} className='btn text-14 d-inline-flex align-items-center page-banner-card-rewards'>
                            {star ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-2" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                            ):(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-2" viewBox="0 0 16 16">
                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
                                </svg>
                            )}
                            Reward
                        </button>
                        
                        <div className='countdown d-flex align-items-center'>
                            <img src={FireIcon} alt="FireIcon" />
                            <Countdown date='2023-08-01T01:02:03' />
                        </div>
                    </div>
                    <div className='page-banner-card-info text-gray'>
                        <h4 className='b2 mb-0 text-white text-truncate'>Hamlet Contemplates Hamlet Contemplates</h4>
                        <p className='b2 mb-0'>5 rewards left</p>
                    </div>
                </div> */}

                <div className='my-auto pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col md={8} xl={7} sm={10}>
                            <h1>Trending Profiles <br />with Special Collections</h1>
                            <p className='mb-0'>The Ultimate Compilation: Unveiling the Multiverse of Legendary Creative NFT Variants!</p>
                        </Col>
                    </Row>
                </div>
            </div>

            <ExploreSingleFilter getProfile={getProfile1} sort_Profile={sort_Profile1}/>

            <div className='mb-5 overflow-xl-hidden'>
            {userProfile[0] === null || userProfile[0] === "" || userProfile[0] === "null" || userProfile[0] === undefined ?
                    (<>
                        <center><CircularProgress color="inherit" /></center>
                    </>):
                    (<>
                <Row className='gx-2 gx-md-3' xs={2} sm={3} lg={4} xl={5}>
                    {profile!==""?userProfile.filter(acc=> { if(acc.firstName!==null) return acc.firstName.toLowerCase().startsWith(profile)}).map((r,i) => {
                        return(<>
                        <Col className='mb-md-3 mb-2'>
                            <CardViewVertical img={r.profileImagePath} x ={r}  />
                        </Col>
                    </>)
                    }):sortby!==""?sortProfile.map((r,i) => {
                        return(<>
                        <Col className='mb-md-3 mb-2'>
                            <CardViewVertical img={r.profileImagePath} x ={r}  />
                        </Col>
                    </>)
                    }):userProfile.map((r,i) => {
                        return(<>
                        <Col className='mb-md-3 mb-2'>
                            <CardViewVertical img={r.profileImagePath} x ={r}  />
                        </Col>
                    </>)
                    })}
                    {/* <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewVertical />
                    </Col> */}
                </Row>
                </>) }
            </div>
        </>
     );
}

export default MarketExploreSingle;