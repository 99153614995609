import { Button, Card, Col, Dropdown, Row, Stack, Table } from "react-bootstrap";
import Link from '../../../asserts/IMAGES/external-link.svg'
import Website from '../../../asserts/IMAGES/website-icon.svg'
import Discord from '../../../asserts/IMAGES/discord-icon-gray.svg'
import Twitter from '../../../asserts/IMAGES/twitter-icon-gray.svg'
import LinkIcon from '../../../asserts/IMAGES/link-icon-gray.svg'
import { useState,useEffect} from "react";
import { getNFTLanuchpadTxn, getNFTLanuchpadTxnV2, getMyNFTLanuchpadTxnV2 } from "../../../awsdatafile";
import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers5/react';
import { getNFTLanuchpadTxnFirebase } from "../../../FirebaseFunctions";
export function Overview(props) {
    const name=props.name;
    const contractAddress=props.contractAddress;
    return(
        <Row className="gx-xl-5">
            <Col md="7" className="mb-md-0 mb-4">
                <h2 className="text-36 mb-20">{name}</h2>

                <Row className="mb-4">
                    <Col lg={8}>
                        <Stack direction="horizontal" className="flex-wrap" gap={2}>
                            {/* <a href="#" target="_blank" className="btn btn-sm d-flex align-items-center px-3 b3 border text-gray"><img className="me-2" src={Website} alt="Website" /> Website</a>
                            <a href="#" target="_blank" className="btn btn-sm d-flex align-items-center px-3 b3 border text-gray"><img className="me-2" src={Discord} alt="Discord" /> Discord</a>
                            <a href="#" target="_blank" className="btn btn-sm d-flex align-items-center px-3 b3 border text-gray"><img className="me-2" src={Twitter} alt="Twitter" /> Twitter</a> */}
                            <a href={`https://sepolia.basescan.org/address/${contractAddress}`} target="_blank" className="btn btn-sm d-flex align-items-center px-3 b3 border text-gray"><img className="me-2" src={LinkIcon} alt="LinkIcon" /> Contract {contractAddress}</a>
                        </Stack>
                    </Col>
                </Row>

                <div className="content-area pe-lg-5">
                    {/* <p>Freakz are the PFP collection of Subber, a Multichain community platform with free tools like: Allowlist allocation, Giveaway Bot, Collab Management.</p>
                    <p>Currently the #1 most used Collab tool for Solana and Polygon communities (over 6000+), and now expanding into Ethereum.</p>
                    <p>These 10,000 Freakz will lead the charge of our expansion into the ETH ecosystem. Subber already supports ETH communities, and with Freakz they will be able to unlock Boosts and Benefits as well.</p>

                    <h4>Upload file</h4>
                    <ul>
                        <li>Unlock access to a premium experience for NFT enthusiasts.</li>
                        <li>Personal Dashboard of all Giveaways you're eligible for</li>
                        <li>Subber Discord Community Access: WL Giveaways, Presales and more.</li>
                        <li>Increase chances in Subber’s exclusive giveaways by Boosting your Freakz</li>
                        <li>Get Boost Streak Rewards</li>
                    </ul>

                    <h4>Risks and Mitigations</h4>
                    <ul>
                        <li>There is a risk that we will not be able to grow and get more communities to join Subber. In this case, we will adapt and improve our tools by listening to their feedback and ensure we have a valuable product for them.</li>
                        <li>There is a risk that Subber runs out of money to be able to support the services as we grow. In this case Subber will improve the business model or may raise outside investment.</li>
                    </ul> */}
                </div>
            </Col>
            <Col md="5">
                <h3 className="text-28 mb-20 text-600">Description</h3>
                <div className="content-area">
                {/* <p>Freakz Boosting: Holders will be able to Boost their NFT and unlock benefits.</p>

                <p>Freakz art and community collabs with other Ethereum Communities.</p>

                <p>Marketing on multiple chains + Onboard ETH, Polygon, Ordinals, SUI and more chains onto Subber.</p>

                <p>Release Multichain Presales: Allowing projects to pre-sell portions of their mints at discounts to their supporters. <br />Become the #1 NFT community platform.</p> */}
                {props.description}
                </div>
            </Col>
        </Row>
    )
}

// export function Transactions({contract,phase}) {
//     const[getData,setgetData]=useState([]);   
//     const { address, chainId, isConnected } = useWeb3ModalAccount();
    
//     const dbcallcontractTxn=async()=>{        
//         if(contract  === null || contract  === "" || contract  === " " || contract === undefined || contract === ''){
//         }
//         else{            
//         // let activitys = await getNFTLanuchpadTxn(contract,phase)   ;
//         // setgetData(activitys.data2);
//         // console.log(activitys.data2)
//         let activitys = await getNFTLanuchpadTxnFirebase(contract,phase)   ;
//         setgetData(activitys);
//         console.log(activitys)
//     }        
// }      
// useEffect(()=>{
//     dbcallcontractTxn()
// });
    
//     return(
//         <Card className='mb-3'>
//             <Card.Header className='text-600'>Transactions</Card.Header>
//             {/* <Card.Body className="d-flex">
//                 <Dropdown>
//                     <Dropdown.Toggle variant="white" className="btn-link bg-transparent p-0 border-0" id="dropdown-basic">
//                         All Types
//                     </Dropdown.Toggle>

//                     <Dropdown.Menu className="p-3 dropdown-filter">
//                         <div className='filter-sidebar'>
//                             <div className="form-check">
//                                 <input className="form-check-input" type="checkbox" id="flexCheckChristians" />
//                                 <label className="form-check-label" htmlFor="flexCheckChristians">
//                                     All <span>(4200)</span>
//                                 </label>
//                             </div>
//                             <div className="form-check">
//                                 <input className="form-check-input" type="checkbox" id="flexCheckHindu" />
//                                 <label className="form-check-label" htmlFor="flexCheckHindu">
//                                 Hindu <span>(2000)</span>
//                                 </label>
//                             </div>
//                             <div className="form-check">
//                                 <input className="form-check-input" type="checkbox" id="flexCheckIslam" />
//                                 <label className="form-check-label" htmlFor="flexCheckIslam">
//                                 Islam <span>(400)</span>
//                                 </label>
//                             </div>
//                             <div className="form-check">
//                                 <input className="form-check-input" type="checkbox" id="flexCheckShinto" />
//                                 <label className="form-check-label" htmlFor="flexCheckShinto">
//                                 Christians <span>(200)</span>
//                                 </label>
//                             </div>
//                         </div>
//                     </Dropdown.Menu>
//                 </Dropdown>             
//             </Card.Body> */}
//             <Table striped responsive bordered={false} hover className="mb-0">
//                 <thead>
//                     <tr>
//                         <th>Key Id</th>
//                         <th>Sender Address</th>
//                         <th>Amount</th>
//                         <th>Tnx Time</th>
//                         <th>Tnx Hash</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                 {getData === null || getData === "" || getData === undefined || getData[0] === null || getData[0] === "" || getData[0] === undefined ?
//      ( 
//         <></>
//         // <tr>
//         //                 <td>null</td>
//         //                 <td>null</td>
//         //                 <td>0 ETH</td>
//         //                 <td>null</td>
//         //                 <td>
//         //                    <a href="#"><img src={Link} className="d-block" alt="link" /></a>
//         //                 </td>
//         //            </tr>      
//     ) : ( 
//     <>
//     {getData.map((x,index)=>{                
//      return(  
//         <>
//         {x && x.senderAddress === address?.toLowerCase() ? <>
//                     <tr>
//                         <td>{x.keyId==="" || x.keyId=== undefined || x.keyId=== null? "0":x.keyId}</td>
//                         <td>{x.senderAddress==="" || x.senderAddress=== undefined|| x.senderAddress=== null? "0x0000000000000...":x.senderAddress}</td>
//                         <td>{x.amount===""||x.amount===undefined||x.amount===null?0:x.amount/1000000000000000000} ETH</td>
//                         <td>{x.txnTime===""||x.txnTime===undefined||x.txnTime===null? "YYYY-MM-DD 00:00:00":x.txnTime}</td>
//                         <td>
//                            <a href={`https://sepolia.basescan.org/tx/${x.txnHash}`} target="_blank"><img src={Link} className="d-block" alt="link" /></a>
//                         </td>
//                    </tr>         
//         </> : <></>}
//         </>                          
//         )
//     })}  
//     </>
//     )}
//                 </tbody>
//                 </Table>
//         </Card>
//     )
// }

export function Transactions({ contract, phase, effectControl, price }) {
    const [transactions, setTransactions] = useState([]);

    const convertEpochToTimeAgo = (epoch) => {
        const milliseconds = new Date().getTime() - epoch * 1000;
        const timeUnits = [
            { unit: 'day', value: 24 * 60 * 60 },
            { unit: 'hour', value: 60 * 60 },
            { unit: 'minute', value: 60 },
            { unit: 'second', value: 1 }
        ];

        for (const { unit, value } of timeUnits) {
            const count = Math.floor(milliseconds / (1000 * value));
            if (count > 0) return `${count} ${unit}${count !== 1 ? 's' : ''} ago`;
        }

        return 'just now';
    };

    const fetchContractTransactions = async () => {
        if (!contract) return;

        try {
            const activity = await getNFTLanuchpadTxnV2(contract, phase);
            console.log("activity", activity);
            if (Array.isArray(activity.data2.result) && activity.data2.result.length > 0) {
                const updatedTransactions = activity.data2.result.map((transaction) => ({
                    ...transaction,
                    timeStamp: convertEpochToTimeAgo(transaction.timeStamp),
                    ethAmount: (transaction.gas / 1e18).toFixed(4)
                }));
                setTransactions(updatedTransactions);
            } else {
                console.error("data2.result is not an array or is empty:", activity.data2.result);
            }
        } catch (error) {
            console.error("Error in fetchContractTransactions:", error);
        }
    };

    useEffect(() => {
        fetchContractTransactions();
    }, [contract, phase, effectControl]);

    useEffect(() => {
        const intervalId = setInterval(fetchContractTransactions, 10000);
        return () => clearInterval(intervalId);
    });

    return (
        <Card className='mb-3'>
            <Card.Header className='text-600'>Transactions</Card.Header>
            <Table striped responsive bordered={false} hover className="mb-0">
                <thead>
                    <tr>
                        <th>Token Id</th>
                        <th>Contract address</th>
                        <th>Sender Address</th>
                        <th>Amount</th>
                        <th>Txn Time</th>
                        <th>Txn Hash</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.length === 0 ? (
                        <tr>
                            <td colSpan="6" className="text-center">
                                <p>No Data</p>
                            </td>
                        </tr>
                    ) : (
                        transactions.map((transaction, index) => (
                            <tr key={index}>
                                <td>{transaction.tokenID}</td>
                                <td>{contract}</td>
                                <td>{transaction.to || "0x0000000000000..."}</td>
                                <td>{ price ? price : "0.00"} USD </td>
                                <td>{transaction.timeStamp}</td>
                                <td>
                                    <a href={`https://sepolia.basescan.org/tx/${transaction.hash}`} target="_blank" rel="noopener noreferrer">
                                        <img src={LinkIcon} className="d-block" alt="link" />
                                    </a>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </Card>
    );
}

export function MyTransactions({ contract, phase, effectControl, price, account }) {

    const [transactions, setTransactions] = useState([]);

    const convertEpochToTimeAgo = (epoch) => {
        const milliseconds = new Date().getTime() - epoch * 1000;
        const timeUnits = [
            { unit: 'day', value: 24 * 60 * 60 },
            { unit: 'hour', value: 60 * 60 },
            { unit: 'minute', value: 60 },
            { unit: 'second', value: 1 }
        ];

        for (const { unit, value } of timeUnits) {
            const count = Math.floor(milliseconds / (1000 * value));
            if (count > 0) return `${count} ${unit}${count !== 1 ? 's' : ''} ago`;
        }

        return 'just now';
    };

    const fetchContractTransactions = async () => {
        if (!contract || !account) {
            return;
        }
        else{
        try {
            const activity = await getMyNFTLanuchpadTxnV2(contract, phase, account);
            console.log("activity", activity);
            if (Array.isArray(activity.data2.result) && activity.data2.result.length > 0) {
                const updatedTransactions = activity.data2.result.map((transaction) => ({
                    ...transaction,
                    timeStamp: convertEpochToTimeAgo(transaction.timeStamp),
                    ethAmount: (transaction.gas / 1e18).toFixed(4)
                }));
                setTransactions(updatedTransactions);
            } else {
                console.error("data2.result is not an array or is empty:", activity.data2.result);
            }
        } catch (error) {
            console.error("Error in fetchContractTransactions:", error);
        }
    }
    };

    useEffect(() => {
        fetchContractTransactions();
    }, [contract, phase, effectControl, account]);

    useEffect(() => {
        const intervalId = setInterval(fetchContractTransactions, 10000);
        return () => clearInterval(intervalId);
    }, [contract, phase, effectControl, account]);

    return (
        <Card className='mb-3'>
            <Card.Header className='text-600'>Transactions</Card.Header>
            <Table striped responsive bordered={false} hover className="mb-0">
                <thead>
                    <tr>
                        <th>Token Id</th>
                        <th>Contract address</th>
                        <th>Sender Address</th>
                        <th>Amount</th>
                        <th>Txn Time</th>
                        <th>Txn Hash</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.length === 0 ? (
                        <tr>
                            <td colSpan="6" className="text-center">
                                <p>No Data</p>
                            </td>
                        </tr>
                    ) : (
                        transactions.map((transaction, index) => (
                            <tr key={index}>
                                <td>{transaction.tokenID}</td>
                                <td>{contract}</td>
                                <td>{transaction.to || "0x0000000000000..."}</td>
                                <td>{ price ? price : "0.00"} USD </td>
                                <td>{transaction.timeStamp}</td>
                                <td>
                                    <a href={`https://sepolia.basescan.org/tx/${transaction.hash}`} target="_blank" rel="noopener noreferrer">
                                        <img src={LinkIcon} className="d-block" alt="link" />
                                    </a>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </Card>
    );
}