import {useEffect, useState } from 'react';
import { Badge, Button, ButtonGroup, Col, ProgressBar, Row, Stack, Tab, Tabs, Toast, Form, FloatingLabel, Modal } from 'react-bootstrap';
// import ShopImage from '../../asserts/IMAGES/shope-image.png'
import CardImageInfoDE from '../../asserts/IMAGES/info-card-de.png';
import CardImageInfoCA from '../../asserts/IMAGES/info-card.png';
import CardImageInfoWV from '../../asserts/IMAGES/info-card-wv.png';
import Loader from '../../asserts/IMAGES/loader-icon.svg'
import Refresh from '../../asserts/IMAGES/refresh-icon.svg'
import Ethereum from '../../asserts/IMAGES/ethereum-filled.svg.svg'
import Dime from '../../asserts/IMAGES/dime.png';
import { Overview, Transactions} from './Components/CustomTabContent';
import Countdown from 'react-countdown';
import Web3 from 'web3';
// import  {customStakeabi, customNftabi} from './CustomStakeContract';
import { customStakeabi, customNftabi } from '../../abi';
import { toast,ToastContainer , Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import {createStakingTable, getStakingbyaddress, updateStaketable} from '../../awsdatafile';
import bigInt from 'big-integer';
import {Ethereum as ConnectPopup} from '../../component/tabs/TabContent';
import { ethers, ContractFactory  } from 'ethers';
import web3Instance from '../../web3Inastance';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { createStakingTableFirebase, getStakingbyaddressFirebase, updateStaketableFirebase } from '../../FirebaseFunctions';
import { ChangeNetwork, ConnectWallet } from '../../GeneralFunctions';


function CustomStaking({web3State}) {
    const [like, setLike] = useState(false);
    const [show, setShow] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
   
    const [tokenaddr,setTokenaddr]=useState("");

    const [totalNFT,setTotalNFT]=useState(0);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const phase = queryParams.get('phase');
    const description = queryParams.get('description');
    const name = queryParams.get('name');
    const contract = queryParams.get('contract');
    const stake = queryParams.get('stake');
  
    const [NFTLaunchpadName,setNFTLaunchpadName]=useState("");
    const[getData,setgetData]=useState([]); 
   
    const [nft, setnft] = useState("");
    const [nft1, setnft1] = useState("");
    const [nft2, setnft2] = useState("");
    const [nftcontract, setNftcontract] = useState("");
    const [nftcontract1, setNftcontract1] = useState("");
    const [token, setToken] = useState("");
    const [token1, setToken1] = useState("");
    const [token2, setToken2] = useState("");
    const [totalnft, setTotalnft] = useState(0);
    const [todayreward, setTodayreward] = useState(0.0000000);
    const [totalreward, setTotalreward] = useState(0.0000000);
    const [globalStake, setGlobalStake] = useState(0);
    const [claimreward1, setclaimreward1] = useState(0.0000000);
    const [claimreward2, setclaimreward2] = useState("");
    const [ids, setIds] = useState([]);
    const [currentTime, setCurrentTime] = useState('');
    const [contractName, setContractName] = useState("");
    const [checkAccount, setCheckAccount] = useState(null);
    const [transChange, setTransChange] = useState(false);
    const [ispopup, setIspopup] = useState(false);
    const handleClose = () => setIspopup(false);
    
      
     // Function to update the current time and format it
     const updateCurrentTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
  
        const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(formattedTime)
        setCurrentTime(formattedTime);
        return formattedTime;
      };

    

  
      
    
    // const connectToEthereum = async () => {
    //     try {
    //       if (window.ethereum) {
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         const web3= new Web3(window.ethereum);
    //         if(localStorage.getItem("walletAddress") !== "" && localStorage.getItem("walletAddress") !== undefined && localStorage.getItem("walletAddress") !== null){
    //             setCheckAccount(true);
    //         }
    //         else{
    //             setCheckAccount(false);
    //         }
    //         return web3;
    //       } else {
    //         throw new Error('No Ethereum wallet found.');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       setCheckAccount(false);
    //       return null;
    //     }
    //   };


     const Stake = async() =>{
        try {
            let nftcontract11;
            const web3 = web3Instance;
            if (!web3) return;
            const accounts = await web3.eth.getAccounts()
            const account = accounts[0];
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            console.log("Connected Successfully",account)

            const Stakecontract = new ethers.Contract(stake,customStakeabi, signer);
            // const erc20 = new web3.eth.Contract("abi", "0x22575e983671fc6f147f204CbD1C25dFedF6Ce3B");

            
            
            const NftContract = new ethers.Contract(contract,customNftabi, signer);
            console.log("success");
            let approveres = await NftContract.approve(stake, parseInt(token));
            let approvereciept = await approveres.wait();

            let stakehash = await Stakecontract.stake(parseInt(token));
            let stakereciept = await stakehash.wait();
            
            let txnTime = updateCurrentTime();;
            toast.success("Staked Successfully")
            console.log(address, (web3.utils.toNumber(parseInt(token))), contract, txnTime, "Stake", contractName);
            // await createStakingTable( stakehash.hash, address, `${(web3.utils.toNumber(parseInt(token)))}`, contract, txnTime, "Stake", contractName);
            await createStakingTableFirebase( stakehash.hash, address, `${(web3.utils.toNumber(parseInt(token)))}`, contract, txnTime, "Stake", contractName);
            console.log("Data added to launchpad database!!");

            setTransChange(!transChange);

            fun();
            functio();

        }
        catch(error){
            toast.error(`Error: ${error.reason}`);
            console.log(error);
        }
     }

     const Withdraw = async() =>{
        try {
            let nftcontract21;
            const web3 = web3Instance;
            if (!web3) return;
            const accounts = await web3.eth.getAccounts()
            const account = accounts[0];
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            console.log("Connected Successfully",account)

            const Stakecontract = new ethers.Contract(stake,customStakeabi, signer);
            // const erc20 = new web3.eth.Contract("abi", "0x22575e983671fc6f147f204CbD1C25dFedF6Ce3B");

            

            // const NftContract = new web3.eth.Contract("abi", "StakingAddress");

            let stakehash = await Stakecontract.unstake(parseInt(token1));
            let stakereciept = await stakehash.wait();

            console.log("Withdraw", contract, `${(web3.utils.toNumber(parseInt(token1)))}`, address);
            toast.success("Successful Withdraw")
            // await updateStaketable( "Withdraw", contract, `${(web3.utils.toNumber(parseInt(token1)))}`, address);
            await updateStaketableFirebase( "Withdraw", contract, `${(web3.utils.toNumber(parseInt(token1)))}`, address);
            console.log("Data updated to staking database!!");

            fun();
            functio();
        }
        catch(error){
            toast.error(`Error: ${error.reason}`);
            console.log(error);
        }
     }

     const emergency_Withdraw = async() =>{
        try {
            let nftcontract31;
            const web3 = web3Instance;
            if (!web3) return;
            const accounts = await web3.eth.getAccounts()
            const account = accounts[0];
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            console.log("Connected Successfully",account)

            const Stakecontract = new ethers.Contract(stake,customStakeabi, signer);
            // const erc20 = new web3.eth.Contract("abi", "0x22575e983671fc6f147f204CbD1C25dFedF6Ce3B");

            

            // const NftContract = new web3.eth.Contract("abi", "StakingAddress");

            let stakehash = await Stakecontract.emergency_withdraw(parseInt(token2));
            let stakereciept = await stakehash.wait();

            console.log("Withdraw", contract, `${(web3.utils.toNumber(parseInt(token2)))}`, address);
            toast.success("Successful Withdraw")
            // await updateStaketable( "Withdraw", contract, `${(web3.utils.toNumber(parseInt(token2)))}`, address);
            await updateStaketableFirebase( "Withdraw", contract, `${(web3.utils.toNumber(parseInt(token2)))}`, address);
            console.log("Data updated to staking database!!");

            fun();
            functio();
        }
        catch(error){
            toast.error(`Error: ${error.reason}`);
            console.log(error);
        }
     }



    const handleChange = (event) =>{
        console.log(Web3.utils.toNumber(event.target.value));
        setnft(Web3.utils.toChecksumAddress(event.target.options[event.target.selectedIndex].getAttribute('value1')));
        setContractName(event.target.options[event.target.selectedIndex].getAttribute('value2'));
        setToken(Web3.utils.toNumber(event.target.value).toString());
    }

    const handleChange2 = (event) =>{
        console.log(Web3.utils.toNumber(event.target.value));
        setnft1(Web3.utils.toChecksumAddress(event.target.options[event.target.selectedIndex].getAttribute('value1')));
        setToken1(Web3.utils.toNumber(event.target.value).toString());
    }

    const handleChange3 = (event) =>{
        console.log(Web3.utils.toNumber(event.target.value));
        setnft2(Web3.utils.toChecksumAddress(event.target.options[event.target.selectedIndex].getAttribute('value1')));
        setToken2(Web3.utils.toNumber(event.target.value).toString());
    }

    const functio = async() => {

        try{
        let nftcontract1;
        const web3 = web3Instance;
            if (!web3) return;
            const accounts = await web3.eth.getAccounts()
            const account = accounts[0];
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const Stakecontract = new ethers.Contract(stake,customStakeabi, signer);
            console.log("checked");
            console.log(contract);
            let nftamt1 = web3.utils.toNumber((await Stakecontract.amountStaked1(account))._hex);
            

            let totalnft1 = nftamt1;
            setTotalnft(totalnft1);
            console.log(nftamt1,parseInt( web3.utils.toNumber((await Stakecontract._totalRewards())._hex)))
            let todayreward1 = (parseInt( web3.utils.toNumber((await Stakecontract._totalRewards())._hex)) / 10 ** 19).toFixed(7);
            setTodayreward(todayreward1);

            let totalreward1 = (parseInt( web3.utils.toNumber((await Stakecontract._totalRewards())._hex))/ 10 ** 18).toFixed(7);
            // console.log( web3.utils.toNumber(totalreward1) / 10 ** 18);
            setTotalreward(totalreward1);

            let globalStake1 = web3.utils.toNumber((await Stakecontract._totalStaked())._hex);
            setGlobalStake(globalStake1);


                let claimreward11 =  (parseInt(web3.utils.toNumber((await Stakecontract.claimAmount(parseInt(token1)))._hex)) / 10 ** 18).toFixed(7);
                console.log(claimreward11);
                setclaimreward1(claimreward11);

                

        }
            catch(e){
                console.log("error",e);
            }

    }

    const fun = async() =>{
        let nftcontract11;
        let name;
        let nftarray = [];
        let idshex = [];
        const web3 = web3Instance;
            if (!web3) return;
            const accounts = await web3.eth.getAccounts()
            const account = accounts[0];
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const Stakecontract = new ethers.Contract(stake,customStakeabi, signer);
            try {
                const NftContract1 = new ethers.Contract(contract,customNftabi, signer);
                const bal1 = web3.utils.toNumber((await NftContract1.balanceOf(address))._hex);
                const name1 = await NftContract1.name();
                console.log(name1);
                // const ids1 = await Stakecontract.getWalletIDFromContract("0xDf85ABF67E177A9B50b97c813035078934e5B70A", address, bal1);
                const ids1 = await Stakecontract.getWalletIDFromContract(contract, address, bal1);
                console.log(ids1);
                ids1.forEach(item => {
                    idshex.push(web3.utils.toNumber(item._hex));
                  });
                  console.log(idshex);
                nftcontract11 = contract;
                name = name1;
                const idNftPairs1 = idshex.map(id => ({ id, nftcontract11, name }));
                console.log(idNftPairs1)
                // setIds(idNftPairs1);

                
                // setIds(idNftPairs3);

                nftarray = [...idNftPairs1];
                setIds(nftarray);

                console.log("arrayset", nftarray);
                dbcallcontractTxn();
              } 
              catch (error) {
                console.error('Error fetching ids:', error);
              }
    }

    const dbcallcontractTxn=async()=>{        
        if(address  === null || address  === "" || address  === " " || address === undefined || address === ''){
        }
        else{            
        // let activitys = await getStakingbyaddress(address)   ;
        // setgetData(activitys.data2);
        // console.log(activitys.data2)
        let activitys = await getStakingbyaddressFirebase(address)   ;
        setgetData(activitys);
        console.log(activitys)
    }        
}      


    useEffect(()=>{
        functio();
     },[token1, nft1, address, isConnected]);

     useEffect(()=>{
        fun();
        dbcallcontractTxn();
     },[address, isConnected]);

     useEffect(() =>{
        if(address !== "" && address !== undefined && address !== null){
                setCheckAccount(true);
            }
            else{
                setCheckAccount(false);
            }
     },[address, isConnected])

     const refresh = () => {
        window.location.reload();
    }

    return ( 
        <>
            {/* <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 1 }}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    <Toast.Body className='b2 px-3 text-gray'>Copied Succesfully!</Toast.Body>
                </Toast>
            </ToastContainer> */}
            <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
            <div className="mb-lg-5 mb-4 mt-md-1 mt-4">
                <div className='d-md-none mb-3'>
                    <h1 className='text-36 text-black text-600'>{NFTLaunchpadName}</h1>

                    <Stack gap={3} direction='horizontal'>
                        <span className='btn btn-white-outline border p-2'><img style={{maxHeight: '24px'}} className='d-block' src={Ethereum} alt="Ethereum" /></span>
                        <span className='btn btn-white-outline text-gray b2 border'>Doxxed</span>
                        <span className='btn btn-white-outline text-gray b2 border'>Total items: &nbsp;{totalNFT}</span>
                    </Stack>
                </div>
                <div className='d-flex justify-content-end'>
                    <ButtonGroup aria-label="First group">
                        {/* <Button onClick={() => setLike(!like)} variant="white" className='d-flex align-items-center'>
                            {like ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                </svg>
                            ):(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                </svg>
                            )}
                            {like ? 1 : 0}
                        </Button>
                        <Button variant="white" className='d-flex align-items-center'>
                            <img src={Loader} className='d-block' alt="Loader" />
                        </Button> */}
                        <Button variant="white" className='d-flex align-items-center' onClick={refresh}>
                            <img src={Refresh} className='d-block' alt="Refresh" />
                        </Button>
                    </ButtonGroup>
                </div>
                <Row className='gx-lg-5 gx-md-4'>
                    {/* <Col md={5} xs={12} className='mb-md-0 mb-2'>
                        <div className='shop-frame d-flex border'>
                            <img src={img} alt="ShopImage" className='img-fluid m-auto' />
                        </div> 
                    </Col> */}
                    <div md={7} xs={12} className='pt-md-3'>
                        <div className='d-none mb-3 d-md-block'>
                            <h1 className='text-36 text-black text-600'>{name} NFT Stake</h1>

                            <Stack gap={3} direction='horizontal'>
                                <span className='btn py-2 btn-white-outline border'><img style={{maxHeight: '24px'}} className='d-block' src={Dime} alt="Dime" /></span>
                                <span className='btn py-2 btn-white-outline text-gray b2 border'>NFT</span>
                                {/* <span className='btn py-2 btn-white-outline text-gray b2 border'>Total items: &nbsp;{totalNFT-totalid}/{totalNFT}</span> */}
                            </Stack>
                        </div>
                        
                        <div className='border p-lg-3 p-2'>
                            <div className='border mb-2 p-2'>
                                <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                    {/* <Stack gap={2} direction='horizontal'> */}
                                        <p className='text-36 text-black text-600'>Stake</p><br/>
                                        

                            <Form.Select aria-label="Floating label select example" onChange={handleChange}> 
                            <option>Select NFT</option>
                            {ids.map((idpair) => (
                                <option value={Web3.utils.toNumber(idpair.id)} value1={idpair.nftcontract11} value2={idpair.name} >{idpair.name},{Web3.utils.toNumber(idpair.id)}</option>
                            ))}
                            </Form.Select><br/>
                            {/* </FloatingLabel> */}
                            {checkAccount ? <><Button type="submit" variant="black" className="d-block w-100 b2" onClick={Stake}>Stake</Button></> 
                            : <><Button type="submit" variant="black" className="d-block w-100 b2"  onClick={() => {setIspopup(true)}}> Stake </Button></>}
                                        
                                         
                                        {/* <Badge bg="primary">My Participation</Badge> */}
                                    {/* </Stack> */}
                                    {/* <Stack gap={3} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                        <span className='btn py-2 px-3 btn-white-outline text-gray b2 border'>Ended</span>
                                    </Stack> */}
                                </div>
                                <p className='b2 text-block'>User staked {totalnft}  Today's reward {todayreward}  Total reward {totalreward}  Staked {globalStake}</p>
                            </div>
                            <div className='border mb-2 p-2'>
                                <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                    {/* <Stack gap={3} direction='horizontal'> */}
                                        <p className='text-36 text-black text-600'>Withdraw</p><br/>
                                        
                                         {/* <FloatingLabel
                                                controlId="floatingInput.Email"
                                                label="Enter tokenId"
                                            >
                                                <Form.Control type="email" placeholder=" " onChange={handletokenChange2}/>
                                        </FloatingLabel><br/>
                                        <Form.Select aria-label="Floating label select example" onChange={handleContractChange2}> 
                                <option>Select category</option>
                                <option value="1">General</option>
                                <option value="2">Donation</option>
                                <option value="3">Construction</option> */}
                                {/* <option value="SpecialPermission">Special Permission</option> */}
                            {/* </Form.Select> */}
                            <Form.Select aria-label="Floating label select example" onChange={handleChange2}> 
                            <option>Select NFT</option>
                            {getData.filter((x) => x.action === "Stake" && (x.contractAddress).toLowerCase() === (contract).toLowerCase()).map((x) => (
                                <option value={Web3.utils.toNumber(x.tokenId)} value1={x.contractAddress}>{x.contractName},{Web3.utils.toNumber(x.tokenId)}</option>
                            ))}
                            </Form.Select>
                            {checkAccount ? <><Button type="submit" variant="black" className="d-block w-100 b2" onClick={Withdraw}>Withdraw</Button></> 
                            : <><Button type="submit" variant="black" className="d-block w-100 b2"  onClick={() => {setIspopup(true)}}> Withdraw </Button></>}
                                        
                                        {/* <Badge bg="primary">PER NFT</Badge> */}
                                    {/* </Stack> */}
                                    {/* <Stack gap={3} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                        <span className='btn py-2 px-3 btn-white-outline text-gray b2 border'>Ended</span>
                                    </Stack> */}
                                </div>
                                <p className='b2 text-block'> Claim nft :  {claimreward1}</p>
                            </div>
                            <div className='border mb-2 p-2'>
                                <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                    {/* <Stack gap={3} direction='horizontal'> */}
                                        <p className='text-36 text-black text-600'>Emergency Withdraw</p><br/>
                                        
                                         {/* <FloatingLabel
                                                controlId="floatingInput.Email"
                                                label="Enter tokenId"
                                            >
                                                <Form.Control type="email" placeholder=" " onChange={handletokenChange2}/>
                                        </FloatingLabel><br/>
                                        <Form.Select aria-label="Floating label select example" onChange={handleContractChange2}> 
                                <option>Select category</option>
                                <option value="1">General</option>
                                <option value="2">Donation</option>
                                <option value="3">Construction</option> */}
                                {/* <option value="SpecialPermission">Special Permission</option> */}
                            {/* </Form.Select> */}
                            <Form.Select aria-label="Floating label select example" onChange={handleChange3}> 
                            <option>Select NFT</option>
                            {getData.filter((x) => x.action === "Stake" && (x.contractAddress).toLowerCase() === (contract).toLowerCase()).map((x) => (
                                <option value={Web3.utils.toNumber(x.tokenId)} value1={x.contractAddress}>{x.contractName},{Web3.utils.toNumber(x.tokenId)}</option>
                            ))}
                            </Form.Select>
                            {checkAccount && (chainId == 84532) ? <><Button type="submit" variant="black" className="d-block w-100 b2" onClick={emergency_Withdraw}>Emergency Withdraw</Button></> 
                            : <>
                            {checkAccount  ? 
                                        <Button type="reset" className="d-block w-100 b2" variant="black"  onClick={() => ChangeNetwork(walletProvider)}> Change Network </Button>: 
                                        <Button type="reset" className="d-block w-100 b2" variant="black"  onClick={ConnectWallet}> Connect Wallet </Button> }
                            {/* <Button type="submit" variant="black" className="d-block w-100 b2"  onClick={chainId !== 84532 ? ChangeNetwork : ConnectWallet}> {chainId !== 84532 ? "Change Network" : "Connect Wallet"} </Button> */}
                            </>}
                                        
                                        {/* <Badge bg="primary">PER NFT</Badge> */}
                                    {/* </Stack> */}
                                    {/* <Stack gap={3} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                        <span className='btn py-2 px-3 btn-white-outline text-gray b2 border'>Ended</span>
                                    </Stack> */}
                                </div>
                                {/* <p className='b2 text-block'> {(claimreward2 == "1") ? <>Claim nft1</> : (claimreward2 == "2") ? <>Claim nft2</> : (claimreward2 == "3") ? <>Claim nft3</> : <></>}  {claimreward2 !== "" &&  <>: {claimreward1}</>}</p> */}
                            </div>
                            {/* <div className='border-dark bg-light mb-3 p-2'>
                                <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                    <Stack gap={3} direction='horizontal'>
                                        <Badge bg="primary">Public Mint</Badge>
                                        <Badge bg="success">Live</Badge>
                                    </Stack>
                                    <Stack gap={2} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                        Ends in 
                                        <span style={{minWidth: '110px'}} className='btn bg-white py-2 px-3 btn-white-outline text-gray b2 border'><Countdown date='2024-07-24T01:02:03' /></span>
                                    </Stack>
                                </div>
                                <p className='b2 text-block'>Minted {totalid} <span className='mx-1'>•</span> Price <img src={Ethereum} style={{maxHeight: '16px'}} className='mx-1' alt="Ethereum" /> {Math.round(Totalprice*10000)/10000} ETH</p>
                            </div> */}
                            {/* <div className='mb-2'>
                                <div className='d-flex b4 mb-1 text-gray align-items-center'>
                                    <span className='flex-grow-1'>Total Minted</span>
                                    <span className='b2 text-black me-2'>{Math.round(((totalid/totalNFT)*100)*100)/100}%</span>
                                    <span>({totalid}/{totalNFT})</span>
                                </div>
                                <ProgressBar now={(totalid/totalNFT)*100} variant="dark" />
                            </div>
                            <div className='border p-2'>
                                <Row className='gx-3 align-items-center'>
                                    <Col xs="auto" style={{width: '100px'}}>
                                        <div className='border d-flex align-items-center'> */}
                                            {/* <Button onClick={() => setCounter(count < 2 ? 1 : count - 1)} variant='reset' className='b4 border-0 text-gray'>-</Button> */}
                                            {/* <span style={{width: '22px'}} className='text-black mx-auto text-center'>{count}</span> */}
                                            {/* <Button onClick={() => setCounter(count + 1)} variant='reset' className='b4 border-0 text-gray'>+</Button> */}
                                        {/* </div>
                                    </Col>
                                    <Col>
                                        <Button type="submit" variant="black" className="d-block w-100 b2" onClick={MintNFT}>Mint NFT</Button>
                                    </Col>
                                </Row>
                            </div> */}
                            <Modal show={ispopup} size="lg" centered="true" onHide={handleClose}>
                        <Modal.Body className='p-md-4 p-3'>
                            <div className='p-md-2'>
                            <div className='text-end position-relative' style={{marginBottom: '-20px', zIndex: 2}}>
                                <Button variant='reset' onClick={handleClose} className='p-0 text-gray m-0'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="d-block" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                                </Button>
                            </div>
                            <Row className='justify-content-center'>
                                <Col md={9}>
                                 <div className='text-center mt-md-0 mt-3 mb-3'>
                                <h2>Connect wallet</h2>
                                <p className='b2 mx-md-4 px-md-5'>Choose how you want to connect. There are several wallet providers.</p>
                                </div>

                                <Tabs defaultActiveKey="ethereum"
                                      id="uncontrolled-tab-example"
                                      className='mb-md-4 mb-lg-4 mb-3 w-100'>
                                    <Tab eventKey="ethereum" title="Ethereum">
                                        <ConnectPopup/>
                                    </Tab>
                                </Tabs>
                                </Col>
                            </Row>
            
                            </div>
                        </Modal.Body>
                    </Modal>
                        </div>
                    </div>
                </Row>
            </div>

            <div className='mb-5'>
                <Tabs
                    defaultActiveKey="transactions"
                    id="uncontrolled-tab-example"
                    className='mb-md-4 mb-lg-5 mb-3 gap-md-4'
                >
                    {/* <Tab eventKey="overview" title="Overview">
                        <Overview name={NFTLaunchpadName} contractAddress={tokenaddr} description={description}/>
                    </Tab> */}
                    <Tab eventKey="transactions" title="Transactions">
                        <Transactions owner={address} phase={phase} contract={contract} effectControl={transChange}/>
                    </Tab>
                </Tabs>
            </div>
            
        </>
     );
}

export default CustomStaking;