import { useState } from 'react';
import Verified from '../../asserts/IMAGES/verified-yellow-icon.svg';
import { ToastContainer, Zoom, toast} from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, Button, Typography, Grid, Paper, } from '@mui/material';
import externalLink from '../../asserts/IMAGES/external-link.svg';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';

export function TabFundraising({count, status, x}) {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
      setModalIsOpen(true);
    };
  
    const closeModal = () => {
      setModalIsOpen(false);
    };

    return(
        <>
        <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
        <div className="table-stats px-0 py-3 d-flex align-items-center text-black text-uppercase text-500 text-14">
            <div className="table-stats-count d-flex">{count}</div>
            <div className="table-stats-collect flex-grow-1">
                <div className='d-flex table-stats-avatar align-items-center'>
                    <span className='position-relative me-3'>
                        <img src={x.imagePath} alt='Avatar' />
                        <img src={Verified} alt="Verified" />
                    </span>
                {/* {(x.name).slice(0, 20)}{x.name.length > 20 ? '...' : ''} */}
                {x.name}
                </div>
            </div>
            <div className="table-stats-cell d-flex"><span className='opacity-50'>{x.status} </span></div>
            <div className="table-stats-cell d-flex"><a href={`https://sepolia.basescan.org/address/${x.email}`} target='_blank'> <h6>{x.email && (x.email).substring(0, 5)}....{x.email && (x.email).substring((x.email).length - 5)}</h6></a></div>
            <div className="table-stats-cell d-flex"> <span className='opacity-50'>{x.approvalStatus  === "No" ? "Pending" : x.approvalStatus === "Yes" ? "Approved" : "Rejected"}</span></div>
            {/* <div className="table-stats-cell"
            //  style={{color: status === 'up' ? '#28B833' : '#E94949'}}
            >-23.9%</div> */}
            <div className="table-stats-cell d-flex"><a href={`https://sepolia.basescan.org/address/${x.walletAddress}`} target='_blank'> <h6>{x.walletAddress && (x.walletAddress).substring(0, 5)}....{x.walletAddress && (x.walletAddress).substring((x.walletAddress).length - 5)}</h6></a></div>
            <div className="table-stats-cell d-flex"><Button style={{fontSize: '12px'}} variant="outlined" color="secondary" onClick={openModal}>More info</Button></div>
            <div className="table-stats-cell d-flex"><Link to = {`/launchpad/custom?name=${x.name}&contract=${x.email}&image=${x.imagePath}&description=${x.description}`}><img src={externalLink} className="d-block" alt="link" /></Link></div>

        </div>

        <Dialog open={modalIsOpen} onClose={closeModal} maxWidth="md">
          <DialogTitle>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{x.name}</Typography>
              <Button onClick={closeModal} color="primary">
                Close
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <img src={x.imagePath} alt={x.name} style={{ maxWidth: '100%', height: 'auto' }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} style={{ padding: '20px', maxWidth: '500px', margin: 'auto' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">{x.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Created Date & Time:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.createdTime}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Creator Wallet Address:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                        {x.walletAddress}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Description:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                        {x.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Contract Address:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" style={{ wordWrap: 'break-word' }}>
                        {x.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Required Amount:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.rewards}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Minted Amount:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.tokenCount}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Starting Time:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.startTime}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Ending Time:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.endTime}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Time Left:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2"><Countdown date={`${x.counterTime}`} /></Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Category:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.status}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Crossmint Enabled:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">{x.crossmint}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        </>
    )
}