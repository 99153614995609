import { NavLink, Outlet } from "react-router-dom";

function MarketReligiousHindu() {
    return(
        <>
            <div className="d-flex mb-4 overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/explore/religious/hindu/people">People</NavLink>
                    <NavLink className="nav-link" to="/explore/religious/hindu/holy-places">Holy Places</NavLink>
                </div>
            </div>

            <Outlet />
        </>
    )
}

export default MarketReligiousHindu;