import { useState, useEffect } from 'react';
import { Badge, Button, ButtonGroup, Col, ProgressBar, Row, Stack, Tab, Tabs, Form, FloatingLabel, Modal} from 'react-bootstrap';
import ShopImage from '../../asserts/IMAGES/shope-image.png'
import Loader from '../../asserts/IMAGES/loader-icon.svg'
import Refresh from '../../asserts/IMAGES/refresh-icon.svg'
import Ethereum from '../../asserts/IMAGES/ethereum-filled.svg.svg'
import usdLogo from '../../asserts/IMAGES/icons8-us-dollar-32.png'
import Dime from '../../asserts/IMAGES/dime.png';
import { Overview, Transactions, MyTransactions} from './Components/TabsContent';
import Countdown from 'react-countdown';
//own imports
import {Web3, utils} from 'web3';
// import { abibytecodeLaunchpad2, abiLaunchpad2 }  from './LaunchpadBytesCode';
// import { abibytecodeLaunchpad4, abiLaunchpad4 }  from './LaunchpadBytesCodeOld';
import { abiLaunchpad2 } from '../../abi';
import { useLocation, useNavigate } from 'react-router-dom';
import {createLaunchpadTxnTable, createActivityTable} from '../../awsdatafile';
import {createLaunchpadTxnTableFirebase, createActivityTableFirebase} from '../../FirebaseFunctions';
import {Ethereum as ConnectPopup} from '../../component/tabs/TabContent';
import { ethers, ContractFactory  } from 'ethers';
import bigInt from 'big-integer';
import web3Instance from '../../web3Inastance';
import BackIcon from '../../asserts/IMAGES/back-icon.svg';
import { CrossmintPayButton } from '@crossmint/client-sdk-react-ui';
import { CircularProgress } from '@mui/material';
import { ClockLoader } from 'react-spinners';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { ChangeNetwork, ConnectWallet } from '../../GeneralFunctions';

const ETH_PRICE_API = "https://api.coingecko.com/api/v3/coins/ethereum";

function CustomLaunchpad({web3State}) {

    const navigate = useNavigate();

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const [like, setLike] = useState(false);
    const [show, setShow] = useState(false);
    const [count, setCounter] = useState(1);
    const [scroll, setScroll] = useState(false);    
    const [ethPrice, setEthPrice] = useState(null);
    const [transChange, setTransChange] = useState(false);
  
    useEffect(() => {
      fetch(ETH_PRICE_API)
        .then((response) => response.json())
        .then((data) => {
          setEthPrice(data.market_data.current_price.usd);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);
    

    useEffect(() => {
        if(!scroll)
        {
            window.scrollTo(0, 0);
            setScroll(true);
        }
    });

    //own code
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const name = queryParams.get('name');
    const contract = queryParams.get('contract');
    const number = queryParams.get('number');
    const image = queryParams.get('image');
    const description = queryParams.get('description');
    const Createdtime = queryParams.get('time');

    const [tokenCount, setTokenCount] = useState(0);
    const [tokenLimit, setTokenLimit] = useState(0);
    const [mintPercent, setMintPercent] = useState(0);
    const [loader, setLoader] = useState(false);
    // localStorage.setItem("tokenPercent", 5);
    const [isApproved, setIsApproved] = useState(false);
    const [amountCollected, setAmountCollected] = useState(0);
    const [isBalAvail, setIsBalAvail] = useState(false);
    const [time, setTime] = useState();
    const [isAvail, setIsAvail] = useState(false);
    const [currentTime, setCurrentTime] = useState('');
    const [mintbyWallet, setMintbyWallet] = useState(0);
    const [dimebyWallet, setDimebyWallet] = useState(0.00);
    const [mintAmount, setMintAmount] = useState();
    const [checkAccount, setCheckAccount] = useState(null);
    const [ispopup, setIspopup] = useState(false);
    const handleClose = () => setIspopup(false);
    const [contractAddress, setContractAddress] = useState(contract);
    const [buttonLoader, setButtonLoader] = useState(false);
    const handleHideLoad = () => setButtonLoader(false);
    const handleShowLoad = () => setButtonLoader(true);

    // const connectToEthereum = async () => {
    //     try {
    //       if (window.ethereum) {
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         const web3 = new Web3(window.ethereum);;
    //         if(localStorage.getItem("walletAddress") !== "" && localStorage.getItem("walletAddress") !== undefined && localStorage.getItem("walletAddress") !== null){
    //             setCheckAccount(true);
    //         }
    //         else{
    //             setCheckAccount(false);
    //         }
            
    //         return web3;
    //       } else {
    //         throw new Error('No Ethereum wallet found.');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       setCheckAccount(false);
    //       return null;
    //     }
    //   };

      // Function to update the current time and format it
      const updateCurrentTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
  
        const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        setCurrentTime(formattedTime);
      };     

    const createNft =async() =>{
        try {
            handleShowLoad();
            const web3 = web3Instance;
            let launchpad;
            let mintresult;
            if (!web3){
                handleHideLoad();
                return;
            }
      
            const account = localStorage.getItem("walletAddress");
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            // const contract = new web3.eth.Contract(abiLaunchpad);
      
            // const contractInstance = await contract
            //   .deploy({
            //     data: abibytecodeLaunchpad,
            //     arguments: ["Name","Base","https://gateway.pinata.cloud/ipfs/QmdLCS6mfnfxvhy9TKZ7h3MCNAJ6y3piz2M7MKMhp2zvej?_gl=1*1p8csnr*rs_ga*OWNkNDU4MjktNDA4My00MzBlLTljMTktYmNkNjYyMDUyZDFk*rs_ga_5RMPXG14TE*MTY4MTg5MTIzMy41LjEuMTY4MTg5MTMwOC41Ny4wLjA.","0xf35A6D2F0f08638e6f09473Dd5f40e69b4889a8C"] ,
            //   })
            //   .send({
            //     from: account,
            //     gas: 15571390,
            //   });
            // console.log(contractInstance);
            // console.log('Contract deployed at address:', contractInstance.options.address);
            // setContractAddress(contractInstance.options.address);

            // console.log("mintAmount", mintAmount);
            if(!mintAmount)
            {
                toast.error("Please enter amount");
                throw new Error('Please enter amount');
            }
            let val = mintAmount *1000000000000000000;
            // if(contract === "0x4930A272dFfcee5706825D698a43514DB4c33989"){
            // if(new Date(Createdtime) >= new Date("2023-09-12 15:05:56")){
            //     launchpad = new ethers.Contract(contractAddress, abiLaunchpad2, signer);
            //     mintresult = await launchpad.mint(account, web3.utils.toBigInt(parseInt(val)), {value: val});
            // }
            // else{
                // launchpad = new ethers.Contract(contractAddress,abiLaunchpad4, signer);
                launchpad = new ethers.Contract(contractAddress,abiLaunchpad2, signer);
                mintresult = await launchpad.mintNFT(web3.utils.toBigInt(parseInt(val)),image,{value: val});
            // }
            const minreciept = await mintresult.wait();
            console.log(minreciept);
            // .send({
            //     from: localStorage.getItem("walletAddress"),      
            //     value:web3.utils.toBigInt(parseInt(val))          
            //    })
               console.log("minted");

            let tokenCount1 =   web3.utils.toNumber((await launchpad.tokenCount())._hex);
            let tokenLimit1 =   web3.utils.toNumber((await launchpad.tokenLimit())._hex);
            console.log(tokenCount1);
            console.log(tokenLimit1);
            let mintPercent1 = ((tokenCount1*100)/tokenLimit1).toFixed(2);
            // localStorage.setItem(`tokenPercent${number}`,mintPercent1);
            // localStorage.setItem(`tokenCount${number}`,tokenCount1);
            // localStorage.setItem(`tokenLimit${number}`,tokenLimit1);
            setTokenCount(tokenCount1);
            setTokenLimit(tokenLimit1);
            setMintPercent(mintPercent1);
            console.log(mintPercent);
            updateCurrentTime();
            let txnTime = currentTime;
            let Mintamount = `${count}`
            console.log(mintresult.hash);
            console.log("mintAmount", mintAmount);
            // await createLaunchpadTxnTable(txnTime,mintresult.hash ,address,mintAmount,contractAddress,tokenCount1 );
            await createLaunchpadTxnTableFirebase(txnTime,mintresult.hash ,address,mintAmount,contractAddress,tokenCount1 );
            console.log("Data added to launchpad database!!");
            // await createActivityTable(localStorage.getItem("walletAddress"),"Launchpad Mint",contractAddress,mintresult.hash,`Launchpad`)
            await createActivityTableFirebase(localStorage.getItem("walletAddress"),"Launchpad Mint",contractAddress,mintresult.hash,`Launchpad`)
            console.log("Data added to activity database!!");
            fun();
            setMintAmount("");
            handleHideLoad();
            toast.success("Transaction Successful");
          } catch (error) {
            console.error('Contract error:', error);
            handleHideLoad();
          }
    }

    // const retrieveWeb3Instance = () => {
    //     const serializedWeb3 = localStorage.getItem('web3Instance');
    //     if (serializedWeb3) {
    //       const deserializedWeb3 = JSON.parse(serializedWeb3);
    //       const web3 = new Web3(deserializedWeb3.currentProvider);
    //       // Restore any other necessary properties
    //       web3.eth.accounts = deserializedWeb3.eth.accounts;
    //       return web3;
    //     }
    //     return null;
    //   };

    const fun = async() =>{
            // let web3;
        const web3 = web3Instance;
        let launchpad;
            if (!web3) return;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner(); 
        // const erc20token = new web3.eth.Contract(abierc20, "0xf35A6D2F0f08638e6f09473Dd5f40e69b4889a8C");
        // const erc20token = new web3.eth.Contract(abierc20, "0x19AcB98E4E2Ba4c39BDbEDE4706Cb37d4E0d4f18");
        
        // if(contract === "0x4930A272dFfcee5706825D698a43514DB4c33989"){
        // if(new Date(Createdtime) >= new Date("2023-09-12 15:05:56")){
            launchpad = new ethers.Contract(contractAddress,abiLaunchpad2, signer);
        // }
        // else{
        //     launchpad = new ethers.Contract(contractAddress,abiLaunchpad4, signer);
        // }
        // var amountCollected1 = web3.utils.toNumber((await erc20token.methods.balanceOf(contractAddress).call())/utils.toBigInt(1000000000000000000));
        // setAmountCollected(amountCollected1);

        // checkUserbal();

        let startTime1 = web3.utils.toNumber(await launchpad.start_time());
        console.log(startTime1);
        let startTime = EpochToDateTime(startTime1);
        console.log(startTime);
        // localStorage.setItem(`startTime${number}`,startTime)        

        let endTime1 = web3.utils.toNumber(await launchpad.end_time());
        console.log(endTime1);
        let endTime = EpochToDateTime(endTime1);
        console.log(endTime);
        // localStorage.setItem(`endTime${number}`,endTime)
        setTime(endTime);

        if(localStorage.getItem("walletAddress")!== null){
        let mintbyWallet1 = await launchpad.myDeposits(address);
        console.log(web3.utils.toNumber(mintbyWallet1.total_price._hex));
        setMintbyWallet(web3.utils.toNumber(mintbyWallet1.total_nft._hex));
        setDimebyWallet((web3.utils.toNumber(mintbyWallet1.total_price._hex)));
        // setAmountCollected(mintbyWallet1.total_nft);
        const weiBalance = await web3.eth.getBalance(contractAddress);
        const etherBalance = web3.utils.fromWei(weiBalance, 'ether');
        setAmountCollected(etherBalance);
        }

        let currentTime = Math.floor(Date.now() / 1000);
        if(((startTime1*1000)<= (currentTime*1000)) && ((endTime1*1000) >= (currentTime*1000))){
            setIsAvail(true);
            setLoader(true);
        }
        else{
            setIsAvail(false);    
            setLoader(true);        
        }
        


            let tokenCount1 =   web3.utils.toNumber((await launchpad.tokenCount())._hex);
            let tokenLimit1 =   web3.utils.toNumber((await launchpad.tokenLimit())._hex);
            let mintPercent1 = ((tokenCount1*100)/tokenLimit1).toFixed(2);
            // localStorage.setItem(`tokenPercent${number}`,mintPercent1);
            // localStorage.setItem(`tokenCount${number}`,tokenCount1);
            // localStorage.setItem(`tokenLimit${number}`,tokenLimit1);            

 
          setTokenCount(tokenCount1);
          setTokenLimit(tokenLimit1);
          setMintPercent(mintPercent1);
          const wallAddress = localStorage.getItem("walletAddress");
        
          console.log(name);
          console.log(contract);
   
    }


    function EpochToDateTime( epochTime ) {
        const date = new Date(epochTime * 1000); // Convert seconds to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      }

    useEffect(() => {
        fun();
        if(address !== "" && address !== undefined && address !== null){
            setCheckAccount(true);
        }
        else{
            setCheckAccount(false);
        }
      }, [address])

    const handlemintAmountChange = (event) =>{
        const newValue = parseFloat(event.target.value);
        setMintAmount(newValue);
        // console.log(newValue);
    }

    const handleSuccess = () => {
        // Update the state after the async operation completes successfully
        setTransChange(!transChange); // Replace with your actual state update
    };

    const refresh = () => {
        window.location.reload();
    }

    return ( 
        <>
        <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>

            <Button variant="reset" className="btn-back me-md-3 me-2 " onClick={() => navigate(-1)}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={BackIcon} alt="back" className="d-block" />
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </Button>

            <div className="mb-lg-5 mb-4 mt-md-1 mt-4">
                <div className='d-md-none mb-3'>
                    <h1 className='text-36 text-black text-600'>{name}</h1>

                    <Stack gap={3} direction='horizontal'>
                        <span className='btn btn-white-outline border p-2'><img style={{maxHeight: '24px'}} className='d-block' src={Ethereum} alt="DIME" /></span>
                        <span className='btn btn-white-outline text-gray b2 border'>NFT</span>
                        <span className='btn btn-white-outline text-gray b2 border'>Total items: &nbsp;{tokenLimit - tokenCount}</span>
                    </Stack>
                </div>
                <div className='d-flex justify-content-end'>
                    <ButtonGroup aria-label="First group">
                        {/* <Button onClick={() => setLike(!like)} variant="white" className='d-flex align-items-center'>
                            {like ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
                                </svg>
                            ):(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-2" viewBox="0 0 16 16">
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                </svg>
                            )}
                            {like ? 1 : 0}
                        </Button>
                        <Button variant="white" className='d-flex align-items-center'>
                            <img src={Loader} className='d-block' alt="Loader" />
                        </Button> */}
                        <Button variant="white" className='d-flex align-items-center' onClick={refresh}>
                            <img src={Refresh} className='d-block' alt="Refresh" />
                        </Button>
                    </ButtonGroup>
                </div>
                {!loader ? <>
                    <center><CircularProgress color="inherit" /></center>
                </> : <>
                    <Row className='gx-lg-5 gx-md-4'>
                        <Col md={5} xs={12} className='mb-md-0 mb-2'>
                            <div className='shop-frame d-flex border'>
                                <img src={image} alt="ShopImage" className='img-fluid m-auto' />
                            </div> 
                        </Col>
                        <Col md={7} xs={12} className='pt-md-3'>
                            <div className='d-none mb-3 d-md-block'>
                                <h1 className='text-36 text-black text-600'>{name}</h1>
                    
                                <Stack gap={3} direction='horizontal'>
                                    <span className='btn py-2 btn-white-outline border'><img style={{maxHeight: '24px'}} className='d-block' src={Ethereum} alt="Ethereum" /></span>
                                    <span className='btn py-2 btn-white-outline text-gray b2 border'>NFT</span>
                                    {/* <span className='btn py-2 btn-white-outline text-gray b2 border'>Total items: &nbsp;{tokenLimit - tokenCount}</span> */}
                                    <span className='btn py-2 btn-white-outline text-gray b2 border'>Remaining items: &nbsp;{(tokenLimit - tokenCount)}</span>
                                    <span className='btn py-2 btn-white-outline text-gray b2 border'>Total items: &nbsp;{tokenLimit}</span>
                                </Stack>
                            </div>
                            
                            <div className='border p-lg-3 p-2'>
                                <div className='border mb-2 p-2'>
                                    <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                        <Stack gap={3} direction='horizontal'>
                                            <Badge bg="primary">My Participation</Badge>
                                        </Stack>
                                        {/* <Stack gap={3} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                            <span className='btn py-2 px-3 btn-white-outline text-gray b2 border'>Ended</span>
                                        </Stack> */}
                                    </div>
                                    <p className='b2 text-block'>Minted {mintbyWallet} <span className='mx-1'>•</span> Donated Amount <img src={Ethereum} style={{maxHeight: '16px'}} className='mx-1' alt="Ethereum" /> {(dimebyWallet/1000000000000000000).toFixed(5)} ETH 
                                                                                                       <span className='mx-1'>•</span><img src={usdLogo} style={{maxHeight: '16px'}} alt="Ethereum" /> {((dimebyWallet/1000000000000000000) * ethPrice).toFixed(2)} USD</p>
                                </div>
                                <div className='border mb-2 p-2'>
                                    <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                        <Stack gap={3} direction='horizontal'>
                                            <Badge bg="primary">Sale price</Badge>
                                        </Stack>
                                        {/* <Stack gap={3} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                            <span className='btn py-2 px-3 btn-white-outline text-gray b2 border'>Ended</span>
                                        </Stack> */}
                                    </div>
                                    <p className='b2 text-block'>1 NFT <span className='mx-1'>•</span> Price <img src={Ethereum} style={{maxHeight: '16px'}} className='mx-1' alt="Ethereum" /> 0.001 ETH
                                    <span className='mx-1'>•</span><img src={usdLogo} style={{maxHeight: '16px'}} alt="Ethereum" /> {((0.001) * ethPrice).toFixed(2)} USD</p>
                                </div>
                                <div className='border-dark bg-light mb-3 p-2'>
                                    <div className='d-flex mb-2 align-items-center justify-content-between flex-wrap'>
                                        <Stack gap={3} direction='horizontal'>
                                            <Badge bg="primary">Collected so far</Badge>
                                            {isAvail && <Badge bg="success">Live</Badge>}
                                        </Stack>
                                        {isAvail ? <><Stack gap={2} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                            Ends in 
                                            <span style={{minWidth: '110px'}} className='btn bg-white py-2 px-3 btn-white-outline text-gray b2 border'><Countdown date={`${time}`} /></span>
                                        </Stack></> : <> <Stack gap={3} className='ms-auto mt-1 mt-sm-0' direction='horizontal'>
                                            <span className='btn py-2 px-3 btn-white-outline text-gray b2 border'>Ended</span>
                                        </Stack></>}
                                    </div>
                                    <p className='b2 text-block'>Collected amount <span className='mx-1'>•</span> <img src={Ethereum} style={{maxHeight: '16px'}} className='mx-1' alt="Ethereum" /> {amountCollected} ETH
                                    <span className='mx-1'>•</span><img src={usdLogo} style={{maxHeight: '16px'}} alt="Ethereum" /> {((amountCollected) * ethPrice).toFixed(2)} USD</p>
                                </div>
                                <div className='mb-2'>
                                    <div className='d-flex b4 mb-1 text-gray align-items-center'>
                                        <span className='flex-grow-1'>Total Minted</span>
                                        <span className='b2 text-black me-2'>{mintPercent}%</span>
                                        <span>({tokenCount}/{tokenLimit})</span>
                                    </div>
                                    <ProgressBar now={mintPercent} variant="dark" />
                                </div>
                                <div className='border p-2'>
                                    <Row className='gx-3 align-items-center'>
                                        {/* <Col xs="auto" style={{width: '125px'}}>
                                            <div className='border d-flex align-items-center'>
                                                <Button onClick={() => 
                                                    {checkUserbal();
                                                    setCounter((count < 2) ? 1 : (count - 1));}} variant='reset' className='b4 border-0 text-gray'>-</Button>
                                                <span style={{width: '22px'}} className='text-gray mx-auto text-center'>{count}</span>
                                                <Button onClick={() => 
                                                {setCounter(count + 1);
                                                checkUserbal();}} variant='reset' className='b4 border-0 text-gray'>+</Button>
                                            </div>
                                        </Col> */}
                                        <Col sm={4} xs={6} className="mb-3">
                            <FloatingLabel
                                controlId="floatingInput.Title"
                                label="Amount"
                            >
                                <Form.Control
                                    type='number'
                                    value={mintAmount}
                                    placeholder=" "
                                    onChange={handlemintAmountChange}/>
                            </FloatingLabel>
                        </Col>
                        {checkAccount && (chainId == 84532) ? <><Col>
                             {buttonLoader ? <>
                                <Button type="submit" variant="black" className="d-block w-100 b2"><center><ClockLoader color='#ffffff' size={25} /></center></Button>
                             </> : <>
                                <Button type="submit" variant="black" className="d-block w-100 b2" disabled={isAvail ? false : true} onClick={createNft}> { isAvail ? <>Participate</> : <>Contract Offline</> }</Button>
                             </>}
                             </Col></> : 
                             <><Col>
                             {checkAccount ? 
                            <Button type="reset" className="d-block w-100 b2" variant="black"  onClick={() => ChangeNetwork(walletProvider)}> Change Network </Button>: 
                            <Button type="reset" className="d-block w-100 b2" variant="black"  onClick={ConnectWallet}> Connect Wallet </Button> }
                             {/* <Button type="submit" variant="black" className="d-block w-100 b2"  onClick={chainId !== 84532 ? ChangeNetwork : ConnectWallet}> {chainId !== 84532 ? "Change Network" : "Connect Wallet"} </Button> */}
                            </Col></>}
                        {contract === "0x4930A272dFfcee5706825D698a43514DB4c33989" && localStorage.getItem("walletAddress") ? <>
                        <br /><b className='text-center mb-4'>(OR)</b>
                        <CrossmintPayButton
                          disabled={isAvail ? false : true}
                          projectId = "360ff4c1-781d-4b45-af7c-ba6e08543e92"
                          collectionId = "53b2ccd6-b49c-487a-b646-13c6561e6d1c"
                          environment = "staging"
                          mintConfig={{
                            type: "erc-721",
                            totalPrice: mintAmount?.toString(),
                            _quantity: "1",
                          }}
                          mintTo = {localStorage.getItem("walletAddress")}
                        //   onEvent={handleSuccess}
                        />
                        </> : <></>}
                      
                        <Modal show={ispopup} size="lg" centered="true" onHide={handleClose}>
                            <Modal.Body className='p-md-4 p-3'>
                                <div className='p-md-2'>
                                <div className='text-end position-relative' style={{marginBottom: '-20px', zIndex: 2}}>
                                    <Button variant='reset' onClick={handleClose} className='p-0 text-gray m-0'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="d-block" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                    </Button>
                                </div>
                                <Row className='justify-content-center'>
                                    <Col md={9}>
                                     <div className='text-center mt-md-0 mt-3 mb-3'>
                                    <h2>Connect wallet</h2>
                                    <p className='b2 mx-md-4 px-md-5'>Choose how you want to connect. There are several wallet providers.</p>
                                    </div>
                      
                                    <Tabs defaultActiveKey="ethereum"
                                          id="uncontrolled-tab-example"
                                          className='mb-md-4 mb-lg-4 mb-3 w-100'>
                                        <Tab eventKey="ethereum" title="Ethereum">
                                            <ConnectPopup/>
                                        </Tab>
                                    </Tabs>
                                    </Col>
                                </Row>
                      
                                </div>
                            </Modal.Body>
                        </Modal>
                                         
                                       
                                        
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>}
            </div>

            <div className='mb-5'>
                <Tabs
                    defaultActiveKey="overview"
                    id="uncontrolled-tab-example"
                    className='mb-md-4 mb-lg-5 mb-3 gap-md-4'
                >
                    <Tab eventKey="overview" title="Overview">
                        <Overview name= {name} contract={contract} description={description}/>
                    </Tab>
                    <Tab eventKey="transactions" title="Transactions">
                        <Transactions contract={contract} effectControl={transChange}/>
                    </Tab>
                    <Tab eventKey="my-transactions" title="My Transactions">
                        <MyTransactions contract={contract} effectControl={transChange} account={address}/>
                    </Tab>
                </Tabs>
            </div>
            
        </>
     );
}

export default CustomLaunchpad;