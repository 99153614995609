import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Col, Dropdown, FloatingLabel, Form, InputGroup, Nav, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import FireIcon from '../../asserts/IMAGES/fire-icon.svg'
import BannerImage from '../../asserts/IMAGES/banner-card-image-religious.png'
import filterIconWhite from '../../asserts/IMAGES/filter-icon-white.svg'
import BannerBG from '../../asserts/IMAGES/divine-banner-bg.png'
import ExploreShopFilter from '../../Snippets/ExploreShopFilter';
import { CardViewShopAuction } from '../../Snippets/CardView';
import { getNFTDetailsByTypeSale, getNFTDetailsByTypeSaleBycategory } from '../../awsdatafile';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { getNFTDetailsByTypeSaleBycategoryFirebase, getNFTDetailsByTypeSaleFirebase } from '../../FirebaseFunctions';

function MarketExploreShop() {
    const location = useLocation();
    const [star, setStar] = useState(false);
    const [saleImage, setsaleImage] = useState([""]);
    const [NFTName,setNFTName]=useState("");
    const [filterName,setFilterName]=useState("");
    const [sortby,setSortby]=useState(false);
    const [val,setVal]=useState(-1);
    const [order,setOrder]=useState("");
  

    const handleFilter1=(data)=>{
        let fn=data
        setFilterName(data);
        console.log("data "+fn);
        setSortby(false)
        setVal(-1)
        setNFTName("")
        setOrder("")
        setsaleImage(saleImage.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()));
    }
    const handleSort1=(val)=>{
        console.log(val);
        setSortby(val);
        setFilterName("")
        setVal(-1)
        setNFTName("")
        setOrder("")
        if(val===true)
        {console.log("Sorting...")
        setsaleImage(saleImage.sort((a, b) => new Date(b.createdTime).getTime() - new Date(a.createdTime).getTime()));
    }
    }
    const getValue1=(val)=>{
        console.log(parseFloat(val).toFixed(4))
        setVal(val);
        setNFTName("")
       setSortby(false)
       setFilterName("")
       setOrder("")
       setsaleImage(saleImage.sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime()));
     }
     const getOrder1=(ord)=>{
         console.log(ord)
         setOrder(ord);
         setVal(-1);
       setNFTName("")
       setSortby(false)
       setFilterName("")
         if(ord==="L2H")
      {console.log("Sorting...")
      setsaleImage(saleImage.sort((a, b) => a.nftPrice - b.nftPrice));
     }
      if(ord==="H2L")
     {   console.log("Sorting...")
     setsaleImage(saleImage.sort((a, b) => b.nftPrice - a.nftPrice));
          }
    
     }

    const getNftImage = async() =>{
        const queryParams = new URLSearchParams(location.search);
        const name = queryParams.get('category');
        if(name){
            // let SaleImage = await getNFTDetailsByTypeSaleBycategory("Auction","yes",name)
            // setsaleImage(SaleImage.data2)
            let SaleImage = await getNFTDetailsByTypeSaleBycategoryFirebase("Auction","yes",name)
            setsaleImage(SaleImage)
        }
        else{
        // let SaleImage = await getNFTDetailsByTypeSale("Auction","yes")
        // setsaleImage(SaleImage.data2)
        let SaleImage = await getNFTDetailsByTypeSaleFirebase("Auction","yes")
        setsaleImage(SaleImage)
    }

    }
    console.log("saleimgae",saleImage)
    useEffect(()=>{getNftImage()},[])    
    return ( 
        <>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">Marketplace</h1>
            </div>

            <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/market/explore">Explore</NavLink>
                    <NavLink className="nav-link" to="/market/create">Create</NavLink>
                    <NavLink className="nav-link" to="/market/mint">Mint</NavLink>
                    <NavLink className="nav-link" to="/market/crossmint">Crossmint</NavLink>
                    <NavLink className="nav-link" to="/market/explore/single">Trending Collection</NavLink>
                   
                    <NavLink className="nav-link" to="/market/explore/shop/heritage">Heritage</NavLink>
                    <NavLink className="nav-link" to="/market/explore/shop/royalty">Artifact</NavLink>
                  
                    <NavLink className="nav-link" to="/market/explore/shop/auction">Auction</NavLink>
                </div>
            </div>

            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-sm-flex py-sm-3 py-4 px-100">
                <img src={BannerBG} className='page-banner-bg' alt="image" />    
                <div className='my-auto text-sm-start text-center pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col md={8} xl={6} sm={8}>
                            <h1>Royalty Auction Collection</h1>
                            <p className='mb-0'>Embrace the Glamour: Unveil Extraordinary NFT Artistry, Join Elite Bidders, and Own Creative History in Our Royalty Auction Collections!</p>
                        </Col>
                    </Row>
                </div>
                {/* <div className='page-banner-card'>
                    <div className='page-banner-card-image'>
                        <img src={BannerImage} className='img-fluid' alt='Banner Image' />

                        <button type='button' onClick={() => setStar(!star)} className='btn text-14 d-inline-flex align-items-center page-banner-card-rewards'>
                            {star ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-2" viewBox="0 0 16 16">
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                </svg>
                            ):(
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-2" viewBox="0 0 16 16">
                                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
                                </svg>
                            )}
                            Reward
                        </button>
                        
                        <div className='countdown d-flex align-items-center'>
                            <img src={FireIcon} alt="FireIcon" />
                            <Countdown date='2023-08-01T01:02:03' />
                        </div>
                    </div>
                    <div className='page-banner-card-info text-gray'>
                        <h4 className='b2 mb-0 text-white text-truncate'>Hamlet Contemplates Hamlet Contemplates</h4>
                        <p className='b2 mb-0'>5 rewards left</p>
                    </div>
                </div> */}
            </div>

            <Row>
                <Col lg="auto" className="mb-lg-0 col-filter mb-2">
                    <h4 className="d-none d-lg-block text-600 mb-20">Find NFTs</h4>

                    <Row className="d-none d-lg-block">
                        <Col xs={6} md={12} className="mb-lg-3">
                            <FloatingLabel
                                controlId="floatingInput.Email"
                                label="Search"
                            >
                                <Form.Control type="text" placeholder=" " 
                                value={NFTName}
                                onChange={e=>setNFTName(e.target.value)}/>
                            </FloatingLabel>
                        </Col>
                        <Col xs={6} md={12}>
                            <ExploreShopFilter handleFilter={handleFilter1} handleSort={handleSort1} getValue={getValue1} getOrder={getOrder1}/>
                        </Col>
                    </Row>

                    <Row className="mb-2 gx-2 d-lg-none">
                        <Col>
                            <InputGroup>
                                <FloatingLabel
                                    controlId="floatingInput.Password"
                                    label="Search avatar"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder=" "
                                    />
                                </FloatingLabel>
                                <Button variant="reset" className="border-0" id="button-addon2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                </svg>
                                </Button>
                            </InputGroup>
                        </Col>
                        <Col xs={'auto'} style={{width: '70px'}}>
                            <Dropdown className="h-100">
                                <Dropdown.Toggle variant="black" className="btn-black-filter" id="dropdown-basic">
                                    <img src={filterIconWhite} alt="ico" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="p-3 dropdown-filter">
                                    <ExploreShopFilter handleFilter={handleFilter1} handleSort={handleSort1} getValue={getValue1} getOrder={getOrder1}/>                                    
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
                <Col>
                {saleImage[0] === "" ||saleImage[0] === null || saleImage[0] === undefined || saleImage[0] === "undefined" ?
                        (<>
                            <center><CircularProgress color="inherit" /></center>
                        </>):(<>
                    <Row className='gx-xl-4 gx-sm-3 gx-2' xs={2} md={3}>
                            {NFTName!==""?saleImage.filter(img=> img.nftName.toLowerCase().startsWith(NFTName.toLowerCase())).map((r,i)=>{
                                return(
                                <div>
                                    <Col className='mb-xl-4 mb-sm-3 mb-2'>
                                        <CardViewShopAuction x={r} />
                                    </Col>
                                </div>
                                )
                            }):filterName!==""?saleImage.filter(img=> img.valid===filterName).map((r,i)=>{
                                return(
                                <div>
                                    <Col className='mb-xl-4 mb-sm-3 mb-2'>
                                        <CardViewShopAuction x={r} />
                                    </Col>
                                </div>
                                )
                            }):sortby===true?saleImage.map((r,i)=>{
                                return(
                                <div>
                                    <Col className='mb-xl-4 mb-sm-3 mb-2'>
                                        <CardViewShopAuction x={r} />
                                    </Col>
                                </div>
                                )
                            }):val>-1?saleImage.filter(img=> parseFloat(img.nftPrice/1000000000000000000).toFixed(4)<=parseFloat(val).toFixed(4)).map((r,i)=>{
                                return(
                                <div>
                                    <Col className='mb-xl-4 mb-sm-3 mb-2'>
                                    <CardViewShopAuction x={r} />
                                    </Col>
                                </div>
                                )
                            }):order!==""?saleImage.map((r,i)=>{
                                return(
                                <div>
                                    <Col className='mb-xl-4 mb-sm-3 mb-2'>
                                    <CardViewShopAuction x={r} />
                                    </Col>
                                </div>
                                )
                            })
                        :saleImage.map((r,i)=>{
                                return(
                                <div>
                                    <Col className='mb-xl-4 mb-sm-3 mb-2'>
                                    <CardViewShopAuction x={r} />
                                    </Col>
                                </div>
                                )
                            })}
                        
                        {/* <Col className='mb-xl-4 mb-sm-3 mb-2'>
                            <CardViewShop />
                        </Col>
                        <Col className='mb-xl-4 mb-sm-3 mb-2'>
                            <CardViewShop />
                        </Col>
                        <Col className='mb-xl-4 mb-sm-3 mb-2'>
                            <CardViewShop />
                        </Col>
                        <Col className='mb-xl-4 mb-sm-3 mb-2'>
                            <CardViewShop />
                        </Col>
                        <Col className='mb-xl-4 mb-sm-3 mb-2'>
                            <CardViewShop />
                        </Col> */}
                        
                    </Row>
                    </>)}
                </Col>
            </Row>

            
        </>
     );
}

export default MarketExploreShop;