import { Col, Row } from "react-bootstrap";
import { CardViewVertical } from "../../Snippets/CardView";
import Image from '../../asserts/IMAGES/hindu-people.png'

function MarketReligiousHinduPeople() {
    return(
        <div className='mb-5 overflow-xl-hidden'>
            <Row className='gx-2 gx-md-3' xs={2} sm={3} md={4} lg={3} xl={4}>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical img={Image} />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical />
                </Col>
                <Col className='mb-md-3 mb-2'>
                    <CardViewVertical />
                </Col>
            </Row>
        </div>
    )
}

export default MarketReligiousHinduPeople;