import { useState, useEffect } from "react";


function EventFilter({filterfun, dbData}) {

    //     All
    // Art & Culture
    // Causes
    // Competition
    const[eventCount, setEventCount] = useState([]);

        const counts = () => {
        try{
                     // Create an object to store the counts of each type
    var typeCounts = dbData.reduce((counts, x) => {
    // Assuming the type property is named "type", change it if needed
    const eventType = x.status;
    
    // If the eventType exists in the counts object, increment its count
    if (eventType in counts) {
      counts[eventType]++;
    } else {
      // If the eventType doesn't exist, initialize it with count 1
      counts[eventType] = 1;
    }
    
    return counts;
  }, {});
  
  console.log(typeCounts);
  setEventCount(typeCounts);
        }catch(error){
            console.log(error);
        }

    }


    useEffect(()=>{
        // dbcallEvents();
        counts();

    },[dbData]);
    
        return ( 
            <div className='filter-sidebar'>
                <h4 className="text-600 d-lg-none mb-3">Category</h4>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckChristians" 
                    onChange={(event) => {(event.target.checked)? filterfun("General") : filterfun("")}}/>
                    <label className="form-check-label" htmlFor="flexCheckChristians">
                    General <span>({(eventCount.General !== undefined) ? eventCount.General : 0})</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckHindu" 
                    onChange={(event) => {(event.target.checked)? filterfun("Donation") : filterfun("")}}/>
                    <label className="form-check-label" htmlFor="flexCheckHindu">
                    Donation <span>({(eventCount.Donation !== undefined) ? eventCount.Donation : 0})</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckIslam" 
                    onChange={(event) => {(event.target.checked)? filterfun("Construction") : filterfun("")}}/>
                    <label className="form-check-label" htmlFor="flexCheckIslam">
                    Construction <span>({(eventCount.Construction !== undefined) ? eventCount.Construction : 0})</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckShinto" 
                    onChange={(event) => {(event.target.checked)? filterfun("SpecialPermission") : filterfun("")}}/>
                    <label className="form-check-label" htmlFor="flexCheckShinto">
                    Special Permission <span>({(eventCount.SpecialPermission !== undefined) ? eventCount.SpecialPermission : 0})</span>
                    </label>
                </div>
                {/* <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckBuddhism" />
                    <label className="form-check-label" htmlFor="flexCheckBuddhism">
                    Education <span>(4200)</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckSikhism" />
                    <label className="form-check-label" htmlFor="flexCheckSikhism">
                    Gambling <span>(2000)</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckSikhism" />
                    <label className="form-check-label" htmlFor="flexCheckSikhism">
                    Gaming <span>(400)</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckSikhism" />
                    <label className="form-check-label" htmlFor="flexCheckSikhism">
                    Health & Wellbeing <span>(200)</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckSikhism" />
                    <label className="form-check-label" htmlFor="flexCheckSikhism">
                    Music <span>(2000)</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckSikhism" />
                    <label className="form-check-label" htmlFor="flexCheckSikhism">
                    Networking <span>(400)</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckSikhism" />
                    <label className="form-check-label" htmlFor="flexCheckSikhism">
                    NFTs <span>(200)</span>
                    </label>
                </div> */}
    
    
                {/* <hr />
    
    
                <h4 className="text-600 mb-3">Type</h4>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckHorns" />
                    <label className="form-check-label" htmlFor="flexCheckHorns">
                    Recurring Event  <span>(4200)</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckSibire" />
                    <label className="form-check-label" htmlFor="flexCheckSibire">
                    One time event <span>(2000)</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckPointed" />
                    <label className="form-check-label" htmlFor="flexCheckPointed">
                    Hybrid <span>(2000)</span>
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckBlunt" />
                    <label className="form-check-label" htmlFor="flexCheckBlunt">
                    Virtual <span>(2000)</span>
                    </label>
                </div>
     */}
            </div>
         );
    }
    
    export default EventFilter;