import { Col, Row } from "react-bootstrap";
import { CardViewNFTS } from "../../../Snippets/CardView";
import { getNFTDetailsByAddressTypeSale } from "../../../awsdatafile";
import { useEffect,useState } from "react";
import NoFound from "./NoFound";
import { CircularProgress } from "@mui/material";
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { getNFTDetailsByAddressTypeSaleFirebase } from "../../../FirebaseFunctions";

export default function Nfts() {
    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const[nftList, setNftList] = useState([]);

    const getmyNft = async() =>{
        if(address === null || address === "" || address === undefined || address === "undefined"){

        }else{
            let allNfts = [];
            // let mynft = await getNFTDetailsByAddressTypeSale(address,"Normal","NO");
            // console.log("mynft.data2[0]", mynft.data2[0]);
            // if(mynft.data2[0])
            //     (mynft.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            let mynft = await getNFTDetailsByAddressTypeSaleFirebase(address,"Normal","NO");
            console.log("mynft.data2[0]", mynft[0]);
            if(mynft[0])
                (mynft).map((r,i)=>{
                    allNfts.push(r);
                })
                

            console.log("mynft",allNfts)
            // let royalty = await getNFTDetailsByAddressTypeSale(address,"Royalty","NO");
            // // console.log("royalty.data2[0]", royalty.data2[0]);
            // if(royalty.data2[0])
            //     (royalty.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            let royalty = await getNFTDetailsByAddressTypeSaleFirebase(address,"Royalty","NO");
            // console.log("royalty.data2[0]", royalty.data2[0]);
            if(royalty[0])
                (royalty).map((r,i)=>{
                    allNfts.push(r);
                })
                     
            // let auction = await getNFTDetailsByAddressTypeSale(address,"Auction","NO");
            // console.log("auction.data2[0]", auction.data2[0]);
            // if(auction.data2[0])
            //     (auction.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })    
            let auction = await getNFTDetailsByAddressTypeSaleFirebase(address,"Auction","NO");
            console.log("auction.data2[0]", auction[0]);
            if(auction[0])
                (auction).map((r,i)=>{
                    allNfts.push(r);
                })    
            setNftList(allNfts);
            console.log("allNFT", allNfts);
        }
       
    }

    useEffect(()=>{
        getmyNft();
    },[address, isConnected])  
    return(
        <>
        {nftList === "" || nftList === "undefined"|| nftList === null || nftList === undefined || nftList.length === 0 ?
            (<>
                <center><CircularProgress color="inherit" /></center>
            </>):(<>
            <Row className="g-md-3 g-2" xs={2} sm={3} lg={4}>
                {nftList.map((r,i) =>{
                    return(<div>
                        <Col>
                            <CardViewNFTS x={r} address={address}/>
                        </Col>
                    </div>)
                })}
            </Row>
        </>)}
        </>
    )   
}