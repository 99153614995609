import { Button, Badge, Col, Dropdown, Row, Stack } from 'react-bootstrap';
import ImageIcon from '../../asserts/IMAGES/image-icon.svg';
import AvatarPic from '../../asserts/IMAGES/avatar-pic.png';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { getuserDetailsbywalletNew } from '../../awsdatafile';
import { useEffect, useState } from 'react';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import BackIcon from '../../asserts/IMAGES/back-icon.svg';
import { CircularProgress } from '@mui/material';
import { getuserDetailsbywalletNewFirebase } from '../../FirebaseFunctions';

export default function Profile() {
    const navigate = useNavigate();
    const location = useLocation();
    const dataParam = new URLSearchParams(location.search).get('address');
    const[nftList, setNftList] = useState([]);
    const [images, setImages] = useState([]);
    const [imageValue, setImageValue] = useState(false);
    const [imgCover, setImgCover] = useState();
    const [imgProfile, setImgProfile] = useState();
    const [getFileCover,setFileCover] = useState("");
    const [getFileProfile,setFileProfile] = useState(""); 
    const [Imgname,setImgname] = useState("");
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [twitter, setTwitter] = useState("");
    const [youtube, setYoutube] = useState("");
    const [discord, setDiscord] = useState("");

    console.log(imgCover);

    const getImages = async () => {
        try{
            // let coverAndProfileImages = await getuserDetailsbywalletNew(dataParam);
            // console.log("coverAndProfileImages", coverAndProfileImages);
            // setImgCover(coverAndProfileImages.data2[0].coverImagePath);
            // setImgProfile(coverAndProfileImages.data2[0].profileImagePath);
            // setWebsite(coverAndProfileImages.data2[0].website);
            // setDescription(coverAndProfileImages.data2[0].description);
            // setTwitter(coverAndProfileImages.data2[0].twitter);
            // setYoutube(coverAndProfileImages.data2[0].youtube);
            // setDiscord(coverAndProfileImages.data2[0].discord);
            // setImages(coverAndProfileImages.data2[0]);
            let coverAndProfileImages = await getuserDetailsbywalletNewFirebase(dataParam);
            console.log("coverAndProfileImages", coverAndProfileImages);
            setImgCover(coverAndProfileImages[0].coverImagePath);
            setImgProfile(coverAndProfileImages[0].profileImagePath);
            setWebsite(coverAndProfileImages[0].website);
            setDescription(coverAndProfileImages[0].description);
            setTwitter(coverAndProfileImages[0].twitter);
            setYoutube(coverAndProfileImages[0].youtube);
            setDiscord(coverAndProfileImages[0].discord);
            setImages(coverAndProfileImages[0]);
        }catch(error){
            console.error(error);
        }
    }

    useEffect(() => {
        if(dataParam != undefined && dataParam != null && dataParam != "")
            getImages();
    },[])

    return(
        <>
            <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
            <Button variant="reset" className="btn-back me-md-3 me-2 " onClick={() => navigate(-1)}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={BackIcon} alt="back" className="d-block" />
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </Button>
            <>
            {images.length === 0 ? <>
                <center><CircularProgress color="inherit" /></center>
            </> : <>
                {images === null || images === "" || images === undefined || images === "undefined" ?
                (<>
                {/* <input type='file' id='uploadFile' hidden /> */}
                <label htmlFor='uploadFile' className='border-dark mb-20 text-center justify-content-center d-flex b1' style={{height: '280px'}}>
                    <div className='m-auto'>
                        <span className='border d-inline-block p-3'><img src={ImageIcon} alt="image_icon" className='d-block' /></span>
                        <p className='text-black mt-3'>Browse your computer <br /><span className='text-gray'>PNG, JPG. Max 500 KB.</span></p>
                    </div>
                </label>  
                </>):(<>
                {/* <input type='file' id='uploadFile' hidden /> */}
                <label htmlFor='uploadFile' className='border-dark mb-20 text-center justify-content-center d-flex b1' style={{height: '280px'}}>
                    <img src={imgCover} alt="imgCover" className='img-fluid w-100 h-100 object-cover w-90 rounded-16' />
                </label>
                </>)}
                <Row>
                    <Col lg={9} className='ps-3 ps-lg-5 mb-lg-5 pb-lg-4 pb-3'>
                        <Stack className='profile-stack align-items-end mb-3' direction='horizontal' gap={2}>

                        {images === null || images === "" || images === undefined || images === "undefined" ?
                        (<>
                            {/* <input type='file' id='uploadProfileFile' hidden /> */}
                            <label htmlFor='uploadProfileFile' className='border-dark'>
                                <img src={AvatarPic} className='profile-pic' alt='Avatar' /> 
                            </label> 
                        </>):(<>
                            {/* <input type='file' id='uploadProfileFile' hidden /> */}
                            <label htmlFor='uploadProfileFile' className='border-dark'>
                                <img src={imgProfile} className='profile-pic' alt='imgProfile' /> 
                            </label>
                        </>)}
                        {website ? <a href={website} className='btn py-2 b3 btn-white'>Go to Website</a> : <></>}
                        {twitter ? <a href={`https://twitter.com/${twitter}`} target="_blank" rel='noreferrer' className="twitter social-icon"></a> : <></>}
                            {youtube ? <a href={`https://www.youtube.com/${youtube}`} target="_blank" rel='noreferrer' className="youtube social-icon"></a> : <></>}
                            {/* <img src={AvatarPic} className='profile-pic' alt='Avatar' /> */}
                            {/* <Link to="edit/setting" className='btn py-2 b3 btn-black'>Edit profile</Link> */}
                            {/* <Link to="sell" className='btn py-2 b3 btn-white'>Sell</Link> */}
                            {/* <label htmlFor='uploadFile' className='btn py-2 b3 btn-white'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                </svg>
                            </label> */}
                            {/* <Dropdown>
                                <Dropdown.Toggle variant="white" className='btn py-2 h-100 b3 dropdown-noarray' id="dropdown-basic">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                                    </svg>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </Stack>
                        <b className='mb-3'>About</b><p>{description}</p>
                    </Col>    
                    <Col lg={3}>
                        <div className='border p-3'>
                            <Stack direction='vertical' className='b2' gap={3}>
                                {/* <div className='d-flex align-items-center'>
                                    <span className='flex-grow-1 text-gray'>Followers</span>
                                    <span className='text-black'>0</span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <span className='flex-grow-1 text-gray'>Following</span>
                                    <span className='text-black'>0</span>
                                </div> */}
                                <hr className='my-0' style={{opacity: '.1'}} />
                                <div className='d-flex align-items-center'>
                                    <span className='flex-grow-1 text-gray'>Address</span>
                                    <span className='text-black'>{dataParam && dataParam.substring(0, 5)}....{dataParam && dataParam.substring((dataParam).length - 5)}</span>
                                    {/* <Dropdown>
                                        <Dropdown.Toggle variant="white" className='p-0 bg-transparent border-0 b2'>
                                            0x69155...4a63
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">0x691554a63</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">0x691554a63</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">0x691554a63</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                </div>
                            </Stack>
                        </div>
                    </Col>    
                </Row> 
                                
                <Row className='mb-md-4 mb-lg-5 mb-3 mt-lg-0 mt-4'>
                    <Col lg={9}>
                        <div className="d-flex profile-nav overflow-auto">
                            <div className="inner-links w-100 flex-nowrap text-nowrap navbar-nav me-0 d-flex flex-row">
                                <NavLink className="nav-link" to={`/users/nfts/all?address=${dataParam}`}>NFTs Collected <Badge bg="secondary"></Badge></NavLink>
                                <NavLink className="nav-link" to={`/users/sales?address=${dataParam}`}>For Sale</NavLink>
                                <NavLink className="nav-link" to={`/users/properties?address=${dataParam}`}>NFT Properties</NavLink>
                                <NavLink className="nav-link" to={`/users/activity?address=${dataParam}`}>Activity</NavLink>
                               <NavLink className="nav-link" to={`/users/fundraising?address=${dataParam}`}>Fundraising</NavLink>
                                 {/* <NavLink className="nav-link" to="/profile/wallet">My Wallet</NavLink>
                                <NavLink className="nav-link" to="/profile/collections">Collections</NavLink> */}
                            </div>
                        </div>
                    </Col>
                </Row>

                <Outlet />
            </>}
        </> 
        </>
    )
}