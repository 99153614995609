import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import LayoutBeforeLogin from "../component/LayoutBL";
import Preview from "../asserts/IMAGES/loginImage.jpg";
import { Link, useNavigate } from "react-router-dom";
import LoginIcon from '../asserts/IMAGES/logo-icon.svg'
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { useState } from "react";
import { ResetPassword } from "../awsdatafile";
import { ResetPasswordFirebase } from "../FirebaseFunctions";
//Forgot password
function CreateAccount() {    
    const [email, setEmail] = useState ();
    const [captcha, setCaptcha] = useState();

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    let navigate = useNavigate();

    function onChange(value) {
        console.log("Captcha value:", value);
        setCaptcha(value);
    }
    const verifyMail = async() =>
    {
        if(captcha)
        {
        // let result = await ResetPassword(email);
        let result = await ResetPasswordFirebase(email);
        // console.log("result", result);
        if(result === true)
        {
            toast.success("Please check your E-mail");
            await sleep(5000);
            navigate("/");
        }
        else
            toast.error("This E-mail does not have an account, Please create an account");
        }
        else{
            toast.error("Please verify with captcha");
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    

    return ( 
        <LayoutBeforeLogin>
            <Container>
            <ToastContainer position='bottom-right' draggable = {false} transition={Zoom} autoClose={4000} closeOnClick = {false}/>
                <Row className="app-blogin-row align-items-center">
                    <Col md={6}>
                        <div className="app-blogin-inner">
                            <div className="d-md-none text-center mb-4">
                                <img src={LoginIcon} alt="logo icon" />
                            </div>
                            <div className="mb-4 row text-center text-md-start">
                                <Col md={8}>
                                    <h1 className="text-28 text-600 mb-1">Forgot Password!</h1>
                                    {/* <p className="text-500">Create your account using your email address or try via other available options</p> */}
                                </Col>
                            </div>

                            <Form>
                                <FloatingLabel
                                    controlId="floatingInput.Email"
                                    label="Email"
                                    className="mb-3" 
                                >
                                    <Form.Control type="email" placeholder=" " onChange={handleEmailChange}/>
                                </FloatingLabel>
                                <div className='d-flex mb-4 justify-content-center'>
                                    <ReCAPTCHA
                                        sitekey="6LdQ6q0mAAAAACT7MyMX6Cl_wZn1AVL4goqxF-oO"
                                        onChange={onChange}
                                    />
                                </div>
                                <Button variant="black" className="d-block w-100 mb-2" onClick={verifyMail}>Continue</Button>
                                {/* <p className="px-md-5 px-4 text-center mb-3">By clicking on log in you agreed with the divine dimension’s terms of service & privacy guidelines</p>
                                <div className="divider d-flex align-items-center text-nowrap"><span className="px-3">Or sign up using</span></div>

                                <Button variant="white" className="btn-icon icon-wallet w-100 mb-3">Login via your Wallet</Button>
                                <Button variant="white" className="btn-icon icon-metamask w-100 mb-3">Login via metamask</Button> */}

                                <div className="text-center">
                                    <Link className="btn-link" style={{textDecoration: "underline"}} to="/">Go back to Log In</Link>
                                </div>
                            </Form>

                        </div>
                    </Col>
                    <Col md={6} className="d-none d-md-block text-center text-md-end">
                            <img src={Preview} alt="Preview" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </LayoutBeforeLogin>
    )
}

export default CreateAccount;