import { Col, Row } from "react-bootstrap";
import { CardViewNFTS } from "../../../Snippets/CardView";
import { getNFTDetailsByAddressTypeSale } from "../../../awsdatafile";
import { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { getNFTDetailsByAddressTypeSaleFirebase } from "../../../FirebaseFunctions";

export default function Nfts() {
    const location = useLocation();
    const dataParam = new URLSearchParams(location.search).get('address');
    const[nftList, setNftList] = useState([]);

    const { address, chainId, isConnected } = useWeb3ModalAccount();


    const getmyNft = async() =>{
        if(dataParam === null || dataParam === "" || dataParam === undefined || dataParam === "undefined"){

        }else{
            let allNfts = [];
            // let mynft = await getNFTDetailsByAddressTypeSale(dataParam,"Normal","NO");
            // console.log("mynft.data2[0]", mynft.data2[0]);
            // if(mynft.data2[0])
            //     (mynft.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            let mynft = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Normal","NO");
            console.log("mynft.data2[0]", mynft[0]);
            if(mynft[0])
                (mynft).map((r,i)=>{
                    allNfts.push(r);
                })
                

            console.log("mynft",allNfts)
            // let royalty = await getNFTDetailsByAddressTypeSale(dataParam,"Royalty","NO");
            // // console.log("royalty.data2[0]", royalty.data2[0]);
            // if(royalty.data2[0])
            //     (royalty.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            let royalty = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Royalty","NO");
            // console.log("royalty.data2[0]", royalty.data2[0]);
            if(royalty[0])
                (royalty).map((r,i)=>{
                    allNfts.push(r);
                })
                     
            // let auction = await getNFTDetailsByAddressTypeSale(dataParam,"Auction","NO");
            // console.log("auction.data2[0]", auction.data2[0]);
            // if(auction.data2[0])
            //     (auction.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })    
            let auction = await getNFTDetailsByAddressTypeSaleFirebase(dataParam,"Auction","NO");
            console.log("auction.data2[0]", auction[0]);
            if(auction[0])
                (auction).map((r,i)=>{
                    allNfts.push(r);
                })    
            setNftList(allNfts);
            console.log("allNFT", allNfts);
        }
       
    }

    useEffect(()=>{
        getmyNft();
    },[dataParam]);  
    return(
        <>
        {nftList === "" || nftList === "undefined"|| nftList === null || nftList === undefined || nftList.length === 0 ?
        (<>
            <center><CircularProgress color="inherit" /></center>
        </>):(<>
        <Row className="g-md-3 g-2" xs={2} sm={3} lg={4}>
            {nftList.map((r,i) =>{
                return(<div>
                    <Col>
                        <CardViewNFTS x={r} address={address}/>
                    </Col>
                </div>)
            })}         
        </Row>
        </>)}
        </>
    )   
}