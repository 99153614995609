import { NavLink, useNavigate } from 'react-router-dom';
import { Button, Col, Modal, Tab, Tabs, Nav, OverlayTrigger, Row, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from 'react-bootstrap';
import FixedIcon from '../../asserts/IMAGES/fixed-price-icon.svg'
import TimedAuction from '../../asserts/IMAGES/time-action.svg'
import Royalty from '../../asserts/IMAGES/royalty-icon.svg'
import BackIcon from '../../asserts/IMAGES/back-icon.svg';
// import Ethereum from '../../asserts/IMAGES/ethereum-filled.svg.svg';
import ImageIcon from '../../asserts/IMAGES/image-icon.svg';
import Plus from '../../asserts/IMAGES/plus-icon.svg';
import Ricon from '../../asserts/IMAGES/r-icon.png';
import { ToastContainer, Zoom, toast} from 'react-toastify';
import Compress from "react-image-file-resizer";
import React,{useEffect,useState,useContext} from 'react';
// import {abibytecode} from "../abi/datasbytecodeNormalNFT";
import Web3 from 'web3';
// import { abi } from '../abi/Normalnftcontract';
// import {abibytecodeauction} from '../abi/datasbytecodeAuctionNFT';
// import {abiauction} from '../abi/Auctionnftcontract';
// import {abibytecoderoyalty} from '../abi/datasbytecodeRoyaltyNFT';
// import {abiroylaty} from '../abi/Royaltynftcontract';
import { abibytecoderoyaltyNft as abibytecoderoyalty, abibytecodenormalNft as abibytecode, abibytecodeauctionNft as  abibytecodeauction} from '../../abiBytecode';
import { abiroylatyNFT as  abiroylaty, abinormalNFT as abi, abiauctionNFT as abiauction} from '../../abi';
import '../../toast-style-override.css';
import axios from 'axios';
import { TajmahalJSONCM, PalaceOfArtsJSONCM, TajmahalImageCM, PalaceOfArtsImageCM, collectionIdTajMahal, collectionIdPalaceofArts,  } from '../../address';
import {createActivityTable, createNFTDetails, getuserDetailsbywalletNew, getNFTDetailsByAddressTypeSale} from '../../awsdatafile';
import { ClockLoader } from 'react-spinners';
import { CardViewNFTS } from "../../Snippets/CardView";
import _ from 'lodash';
import NoFound from '../Profile/components/NoFound';
import { CrossmintPayButton } from '@crossmint/client-sdk-react-ui';
import web3Instance from '../../web3Inastance';
import { Ethereum } from '../../component/tabs/TabContent';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { createActivityTableFirebase, createNFTDetailsFirebase, getNFTDetailsByAddressTypeSaleFirebase, getuserDetailsbywalletNewFirebase } from '../../FirebaseFunctions';

function MarketMintCrossmint({web3State}) {
    const navigate = useNavigate();

    const { address, chainId, isConnected } = useWeb3ModalAccount();

    const[loader, setLoader] = useState(false);
    const handleShowLoad = () => setLoader(true);
    const handleHideLoad = () => setLoader(false);   
    const [getFile,setFile] = useState("") 
    const [Img,setImg] = useState("")
    const [Imgname,setImgname] = useState("")
    const[toggelValue,settoggelValue] = useState(1);
    const[price,setPrice] = useState("");
    const[month,setmonth] = useState("");
    const [selectValue2,setSelectValue2] = useState("Buddhism"); 
    const [Name,setName] = useState("");
    const [Description,setDescription] = useState("");
    const [selectValue311,setSelectValue311] = useState("1");   
    const [contractAddress, setContractAddress] = useState('');
    const [mintNftLoader, setMintNftLoader] = useState(false);
    const [mintNftSwitch, setMintNftSwitch] = useState(false);
    const [existingNftDetails, setExistingNftDetails] = useState([]);
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleMintNftLoad = () => setMintNftLoader(true);
    const handleMintNftHide = () => setMintNftLoader(false);

    const handleMintNftSwitchLoad = () => setMintNftSwitch(true);
    const handleMintNftSwitchHide = () => setMintNftSwitch(false);

    const[nftList, setNftList] = useState([]);

    console.log("existingNftDetails", existingNftDetails);

    const captureFile =async(event) => {
        event.stopPropagation()
        event.preventDefault()
        const file = event.target.files[0]
        setImgname(file.name)
        setFile(file)
        const MIN_FILE_SIZE = 1024 // 1KB
        const MAX_FILE_SIZE = 500120 // 500KB
        let fileSizeKiloBytes = file.size 
        let c=0;
        if(fileSizeKiloBytes < MIN_FILE_SIZE){
          toast.dismiss();
          toast.error("File size is less than minimum limit",{autoClose:3000});          
          c=c+1;
          handleHideLoad()                               
          await sleep(4000);
        //   window.location.reload(false)
        }
        if(fileSizeKiloBytes > MAX_FILE_SIZE){
          toast.dismiss();
          toast.error("File size is greater than maximum limit",{autoClose:3000});      
          c=c+1;
          handleHideLoad()  
          await sleep(4000);                             
        //   window.location.reload(false)
        }        
        if(c===0){
        let reader = new window.FileReader()
        try{
        Compress.imageFileResizer(file, 500, 500, 'JPEG', 200, 0,
        uri => {          
            setImg(uri)          
        },
        'base64'
        );
        reader.readAsArrayBuffer(file)        
        }catch (err) {      
        }
        }else{
          toast.dismiss();
          toast.error("Support file size: 1 kb to 500 kb ",{autoClose:3000});                
          handleHideLoad()                               
          await sleep(4000);
        //   window.location.reload(false)
          
        }
        
    }; 

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    

    // const connectToEthereum = async () => {
    //     try {
    //       if (window.ethereum) {
    //         await window.ethereum.request({ method: 'eth_requestAccounts' });
    //         const web3 = new Web3(window.ethereum);
    //         return web3;
    //       } else {
    //         throw new Error('No Ethereum wallet found.');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       return null;
    //     }
    //   };

    const createNft =async() =>{
        handleMintNftLoad(); 
        if (existingNftDetails.length != 0) {
            try {
                const web3 = web3Instance;
                if (!web3) return;
          
                const account = address;
          
               
                // let ipfsurl = "https://gateway.pinata.cloud/ipfs/QmdLCS6mfnfxvhy9TKZ7h3MCNAJ6y3piz2M7MKMhp2zvej?_gl=1*1p8csnr*rs_ga*OWNkNDU4MjktNDA4My00MzBlLTljMTktYmNkNjYyMDUyZDFk*rs_ga_5RMPXG14TE*MTY4MTg5MTIzMy41LjEuMTY4MTg5MTMwOC41Ny4wLjA.";
                let contractInstance;
                let type;
                let txhash;
                if(existingNftDetails.nftType === "Normal"){
                    //Normal NFT Mint
                    const contractInstance = new web3.eth.Contract(abi, existingNftDetails.nftAddress);

                    await contractInstance.methods.mintWithTokenURI(address, existingNftDetails.imagePath).send({
                        from: address,      
                        //gas: 21000            
                       }).on('transactionHash',function(hash){      
                        console.log("hashget",hash)
                        txhash = hash;
                     });
                    type = "Normal";
                }
                else if(existingNftDetails.nftType === "Auction"){
                      //Auction NFT Mint
                      const contractInstance = new web3.eth.Contract(abiauction, existingNftDetails.nftAddress);
                    await contractInstance.methods.mintWithTokenURI(address, existingNftDetails.imagePath, '0').send({
                        from: address,      
                        //gas: 21000            
                       }).on('transactionHash',function(hash){      
                        console.log("hashget",hash)    
                        txhash = hash;
                        })
                        type = "Auction";
                        console.log("minted");
                }else{
                    const contractInstance = new web3.eth.Contract(abiroylaty, existingNftDetails.nftAddress);
                    await contractInstance.methods.mintWithTokenURI(address, existingNftDetails.imagePath).send({
                        from: address,      
                        //gas: 21000            
                       }).on('transactionHash',function(hash){      
                        console.log("hashget",hash)    
                        txhash = hash;  
                    })
                    type = "Royalty";
                }
                
                // let coverAndProfileImages = await getuserDetailsbywalletNew(address);
                let coverAndProfileImages = await getuserDetailsbywalletNewFirebase(address);


                // await createNFTDetails(address,existingNftDetails.nftAddress, (parseInt(existingNftDetails.escrowAddress) + 1).toString(), existingNftDetails.nftType, existingNftDetails.nftDescription, existingNftDetails.nftName, "", existingNftDetails.imagePath, existingNftDetails.valid, coverAndProfileImages.data2[0].profileImagePath);
                // await createActivityTable(address, "Mint NFT", existingNftDetails.nftAddress, txhash, existingNftDetails.valid)
                await createNFTDetailsFirebase(address,existingNftDetails.nftAddress, (parseInt(existingNftDetails.escrowAddress) + 1).toString(), existingNftDetails.nftType, existingNftDetails.nftDescription, existingNftDetails.nftName, "", existingNftDetails.imagePath, existingNftDetails.valid, coverAndProfileImages[0].profileImagePath);
                await createActivityTableFirebase(address, "Mint NFT", existingNftDetails.nftAddress, txhash, existingNftDetails.valid)
                // console.log('Contract deployed at address:', contractInstance.options.address);
                // setContractAddress(contractInstance.options.address);
                navigate('/profile/nfts');
                handleMintNftHide();
              } catch (error) {
                console.error('Minting error:', error);
                handleMintNftHide();
              }
        }
        else
        {
            console.error("NftDetails not fetched properly")
        }
    }
    
    const getmyNft = async() =>{
        if(address === null || address === "" || address === undefined || address === "undefined"){

        }else{
            let allNfts = [];
            // let mynft = await getNFTDetailsByAddressTypeSale(address,"Normal","YES");
            // let myNftNonSale = await getNFTDetailsByAddressTypeSale(address,"Normal","NO");
            // console.log("mynft.data2[0]", mynft.data2);
            // if(mynft.data2[0])
            //     (mynft.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            //     if(myNftNonSale.data2[0])
            //     (myNftNonSale.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            let mynft = await getNFTDetailsByAddressTypeSaleFirebase(address,"Normal","YES");
            let myNftNonSale = await getNFTDetailsByAddressTypeSaleFirebase(address,"Normal","NO");
            console.log("mynft.data2[0]", mynft);
            if(mynft[0])
                (mynft).map((r,i)=>{
                    allNfts.push(r);
                })
                if(myNftNonSale[0])
                (myNftNonSale).map((r,i)=>{
                    allNfts.push(r);
                })
                

            console.log("mynft",allNfts)
            // let royalty = await getNFTDetailsByAddressTypeSale(address,"Royalty","YES");
            // let royaltyNonSale = await getNFTDetailsByAddressTypeSale(address,"Royalty","NO");
            // // console.log("royalty.data2[0]", royalty.data2[0]);
            // if(royalty.data2[0])
            //     (royalty.data2).map((r,i)=>{
            //         allNfts.push(r);
            // })
            // if(royaltyNonSale.data2[0])
            //     (royaltyNonSale.data2).map((r,i)=>{
            //         allNfts.push(r);
            // })
            let royalty = await getNFTDetailsByAddressTypeSaleFirebase(address,"Royalty","YES");
            let royaltyNonSale = await getNFTDetailsByAddressTypeSaleFirebase(address,"Royalty","NO");
            // console.log("royalty.data2[0]", royalty.data2[0]);
            if(royalty[0])
                (royalty).map((r,i)=>{
                    allNfts.push(r);
            })
            if(royaltyNonSale[0])
                (royaltyNonSale).map((r,i)=>{
                    allNfts.push(r);
            })
                     
            // let auction = await getNFTDetailsByAddressTypeSale(address,"Auction","YES");
            // let auctionNonSale = await getNFTDetailsByAddressTypeSale(address,"Auction","NO");
            // console.log("auction.data2[0]", auction.data2[0]);
            // if(auction.data2[0])
            //     (auction.data2).map((r,i)=>{
            //         allNfts.push(r);
            //     })
            // if(auctionNonSale.data2[0])
            //     (auctionNonSale.data2).map((r,i)=>{
            //         allNfts.push(r);
            // })     
            let auction = await getNFTDetailsByAddressTypeSaleFirebase(address,"Auction","YES");
            let auctionNonSale = await getNFTDetailsByAddressTypeSaleFirebase(address,"Auction","NO");
            console.log("auction.data2[0]", auction[0]);
            if(auction[0])
                (auction).map((r,i)=>{
                    allNfts.push(r);
                })
            if(auctionNonSale[0])
                (auctionNonSale).map((r,i)=>{
                    allNfts.push(r);
            })     
            const filteredAndSortedData = _.chain(allNfts)
            .groupBy('nftAddress')
            .map((group) => _.maxBy(group, 'escrowAddress'))
            .value();

            setNftList(filteredAndSortedData);
            console.log("allNFT", filteredAndSortedData);
        }
       
    }

    useEffect(()=>{
        getmyNft();
    },[address, isConnected])

    const MintSwitcher = async () => {
      let jsonMetadata = {
        "name": "My First NFT",
        "description": "This is my first NFT!",
        "image": "https://gateway.pinata.cloud/ipfs/QmQp4AWVyLzHNVFuLRtE3695X7We9vkH9AduSYLurXJb7J"
        };
      const blob = new Blob([JSON.stringify(jsonMetadata, null, 2)], { type: 'application/json' });
      const file = new File([blob], 'metadata.txt', { type: 'text/plain' });
  
      // Create FormData
      const formData = new FormData();
      formData.append('file', file);
      const resFile = await axios({
        method: "post",
        url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
        data: formData,
        headers: {
            'pinata_api_key': "a4454043d8776ac1d3b7",
            'pinata_secret_api_key': '903a589e02512750f7cc3bb7b3fa9031c9428ac40bbfe9778c01394f65813aaf',                        
            "Content-Type": "multipart/form-data"
        },
      });                
      const ipfsurl = `https://gateway.pinata.cloud/ipfs/${resFile.data.IpfsHash}`;                                  
      console.log("Pinata updated",ipfsurl)
    }

    return ( 
        <>
            <div className="d-flex overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/market/explore">Explore</NavLink>
                    <NavLink className="nav-link" to="/market/create">Create</NavLink>
                    <NavLink className="nav-link" to="/market/mint">Mint</NavLink>
                    <NavLink className="nav-link" to="/market/crossmint">Crossmint</NavLink>
                    <NavLink className="nav-link" to="/market/explore/single">Trending Collection</NavLink>
                   
                    <NavLink className="nav-link" to="/market/explore/shop/heritage">Heritage</NavLink>
                    <NavLink className="nav-link" to="/market/explore/shop/royalty">Artifact</NavLink>
                  
                    <NavLink className="nav-link" to="/market/explore/shop/auction">Auction</NavLink>
                </div>
            </div>
                {!address ? <>
                  <Row className="g-md-3 g-2 mt-4" xs={2} sm={3} lg={4}>
                      <div>
                        <Col>
                          <div className="card-shop">
                            <img
                              src = "https://gateway.pinata.cloud/ipfs/QmVtyi37xhjw8j7thyKYCppRSKDbLej7vYYDeoMu7UYWX8"
                              className = "card-shop-image rounded-clip"
                              alt = "Card Shop Image"
                            />
                            <div className="card-shop-info pb-2">
                              <h4 className="b1 mb-2">Taj Mahal</h4>
                              {/* <h6 className="b1 mb-2">
                                {r.nftName} #{r.escrowAddress}
                              </h6> */}
                              <p className="b2 mb-3 text-black">The Taj Mahal is an ivory-white marble mausoleum on the south bank of the Yamuna river in the Indian city of Agra. It was commissioned in 1632 by the Mughal emperor, Shah Jahan (reigned from 1628 to 1658), to house the tomb of his favourite wife, Mumtaz Mahal.</p>
                              <center><Button variant="black" onClick={handleShow}>Connect Wallet</Button></center>
                            </div>
                          </div>
                        </Col>
                      </div>
                      <div>
                        <Col>
                          <div className="card-shop">
                            <img
                              src = "https://gateway.pinata.cloud/ipfs/QmWowJRub7ayaxkp9dBPNYySHii52LTSpHzTPdoB51aTdJ"
                              className = "card-shop-image rounded-clip"
                              alt = "Card Shop Image"
                            />
                            <div className="card-shop-info pb-2">
                              <h4 className="b1 mb-2">The Palace of Fine Arts</h4>
                              {/* <h6 className="b1 mb-2">
                                {r.nftName} #{r.escrowAddress}
                              </h6> */}
                              <p className="b2 mb-3 text-black">The Palace of Fine Arts is a monumental structure located in the Marina District of San Francisco, California, originally constructed for the 1915 Panama–Pacific International Exposition to exhibit works of art. Completely rebuilt from 1964 to 1974, it is the only structure from the exposition that survives on site.</p>
                              <center><Button variant="black" onClick={handleShow}>Connect Wallet</Button></center>
                            </div>
                          </div>
                        </Col>
                      </div>
                  </Row>
                </> : <>
                <Row className="g-md-3 g-2 mt-4" xs={2} sm={3} lg={4}>
                    <div>
                      <Col>
                        <div className="card-shop">
                          <img
                            src = {TajmahalImageCM}
                            className = "card-shop-image rounded-clip"
                            alt = "Card Shop Image"
                          />
                          <div className="card-shop-info pb-2">
                            <h4 className="b1 mb-2">Taj Mahal</h4> 
                            {/* <h6 className="b1 mb-2">
                              {r.nftName} #{r.escrowAddress}
                            </h6> */}
                            <p className="b2 mb-3 text-black">The Taj Mahal is an ivory-white marble mausoleum on the south bank of the Yamuna river in the Indian city of Agra. It was commissioned in 1632 by the Mughal emperor, Shah Jahan (reigned from 1628 to 1658), to house the tomb of his favourite wife, Mumtaz Mahal.</p>
                            <center><CrossmintPayButton
                              projectId="360ff4c1-781d-4b45-af7c-ba6e08543e92"
                              // collectionId="dbccd417-ec53-4810-937e-02464afca0ac"
                              collectionId={collectionIdTajMahal}
                              environment="staging"
                              mintConfig={{
                                type: "erc-721",
                                totalPrice: (0.0025).toString(),
                                _quantity: 1,
                                tokenURI: {TajmahalJSONCM}
                              }}
                              mintTo={address}
                            /></center>
                          </div>
                        </div>
                        {/* <Button onClick={MintSwitcher}>MintSwitch</Button> */}
                      </Col>
                    </div>
                    <div>
                      <Col>
                        <div className="card-shop">
                          <img
                            src = {PalaceOfArtsImageCM}
                            className = "card-shop-image rounded-clip"
                            alt = "Card Shop Image"
                          />
                          <div className="card-shop-info pb-2">
                            <h4 className="b1 mb-2">The Palace of Fine Arts</h4>
                            {/* <h6 className="b1 mb-2">
                              {r.nftName} #{r.escrowAddress}
                            </h6> */}
                            <p className="b2 mb-3 text-black">The Palace of Fine Arts is a monumental structure located in the Marina District of San Francisco, California, originally constructed for the 1915 Panama–Pacific International Exposition to exhibit works of art. Completely rebuilt from 1964 to 1974, it is the only structure from the exposition that survives on site.</p>
                            <center><CrossmintPayButton
                              projectId="360ff4c1-781d-4b45-af7c-ba6e08543e92"
                              // collectionId="1178c535-dc71-4109-9fb5-1323827a648d"
                              collectionId={collectionIdPalaceofArts}
                              environment="staging"
                              mintConfig={{
                                type: "erc-721",
                                totalPrice: (0.0025).toString(),
                                _quantity: 1,
                                tokenURI: {PalaceOfArtsJSONCM}
                              }}
                              mintTo={address}
                            /></center>
                          </div>
                        </div>
                      </Col>
                    </div>
                </Row>
                </>}
                <Modal show={show} size="lg" centered="true" onHide={handleClose}>
                  <Modal.Body className='p-md-4 p-3'>
                    <div className='p-md-2'>
                      <div className='text-end position-relative' style={{marginBottom: '-20px', zIndex: 2}}>
                        <Button variant='reset' onClick={handleClose} className='p-0 text-gray m-0'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="d-block" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                          </svg>
                        </Button>
                      </div>
                                          
                      <Row className='justify-content-center'>
                        <Col md={9}>
                          <div className='text-center mt-md-0 mt-3 mb-3'>
                            <h2>Connect wallet</h2>
                            <p className='b2 mx-md-4 px-md-5'>Choose how you want to connect. There are several wallet providers.</p>
                          </div>
                                          
                          <Tabs
                            defaultActiveKey="ethereum"
                            id="uncontrolled-tab-example"
                            className='mb-md-4 mb-lg-4 mb-3 w-100'
                        >
                            <Tab eventKey="ethereum" title="Ethereum">
                                <Ethereum />
                            </Tab>
                            {/* <Tab eventKey="tezos" title="Tezos">
                                <NoFound />
                            </Tab>
                            <Tab eventKey="immutable" title={<TabTitleWithDot />}>
                                <NoFound />
                            </Tab>
                            <Tab eventKey="polygon" title="Polygon">
                                <NoFound />
                            </Tab> */}
                          </Tabs>
                        </Col>
                      </Row>
                          
                    </div>
                  </Modal.Body>
                </Modal>
        </>
     );
}

export default MarketMintCrossmint;