import { Link, NavLink, useNavigate } from 'react-router-dom';
import Banner from '../../asserts/IMAGES/nft-banner-image.png'
import BannerBG from '../../asserts/IMAGES/nft-banner-bg.png'
import RocketIcon from '../../asserts/IMAGES/rocket-icon.svg'
import { Button, Col, Nav, Row } from 'react-bootstrap';
import BackIcon from '../../asserts/IMAGES/back-icon.svg';

import { CardViewHorizontalWv } from '../../Snippets/CardView';
import { useEffect, useState } from 'react';
// import  {abi1,tokenaddress1De} from './NFTLaunchpadcontracts';
import  {nftLaunchpadABI as abi1} from '../../abi';
import  {tokenaddress1Wv} from '../../address';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { ethers, ContractFactory  } from 'ethers';
import web3Instance from '../../web3Inastance';
import { CircularProgress } from '@mui/material';

function NFTLaunchpadDe() {

    const navigate = useNavigate();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const [endtime, setEndtime] = useState();

    let desc = "The World Voyager NFT launchpad is your gateway to a global expedition within our metaverse platform. This exceptional NFT grants you access to a virtual world where you can embark on journeys to explore and discover renowned tourist destinations from across the globe.";

    const updateCurrentTime = (epochtime) => {
        const now = new Date(epochtime * 1000);
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
  
        const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;   
        setEndtime(formattedTime);
        console.log("time", formattedTime, "||", endtime);
      };

    const fun = async() => {
        if(isConnected){
            const web3 = web3Instance;
        if (!web3) return;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const NFTLaunchpadcontract = new ethers.Contract(tokenaddress1Wv, abi1, signer);
        const endTime = web3.utils.toNumber((await NFTLaunchpadcontract.end_time())._hex);
        updateCurrentTime(endTime)
        }  
        else{
            console.log("skip")
        }   
    }

    useEffect(() => {
        fun();
    }, [address, isConnected])
    return ( 
        <>
            <Button variant="reset" className="btn-back me-md-3 me-2 " onClick={() => navigate(-1)}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={BackIcon} alt="back" className="d-block" />
                <p style={{ margin: 0 }}>Back</p>
              </div>
            </Button>
            <div className="mb-20 d-flex d-md-none align-items-center justify-content-between">
                <h1 className="text-600 mb-0">NFT Launchpad</h1>
            </div>

            <div className="mb-lg-5 mb-4 mt-4 page-banner text-white page-banner-dark d-flex px-100" style={{backgroundColor: 'rgba(4, 11, 32, 1)'}}>
                <img src={BannerBG} className='page-banner-bg' alt="image" /> 
                <img src={Banner} className='page-banner-image-sm-expand' alt="image" />

                <div className='my-5 my-sm-auto text-sm-start text-center pb-sm-0 pb-4 w-100'>
                    <Row>
                        <Col xs={12} md={8} xl={7} sm={10}>
                            <h1 className='text-500'>Discover, and collect <br />Monster NFTs extraordinary</h1>
                            <p>The Arcadians: Reloaded collection is inspired by classic arcade games. Each Arcadian is custom-made by the minter from a pool of traits. </p>

                            <Link to="/market/explore" className='btn btn-small mb-2 b1 btn-outline-white me-md-3 me-2 d-inline-flex align-items-center justify-content-center'><img className='me-2' src={RocketIcon} alt="RocketIcon" /> Explore more</Link>
                            <Link to="/nft-launchpad" className='btn btn-small mb-2 b1 btn-white'>Go back to NFT launchpad</Link>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* <div className="d-flex mb-40 overflow-auto">
                <div className="inner-links flex-nowrap text-nowrap navbar-nav d-flex flex-row">
                    <NavLink className="nav-link" to="/nft-launchpad">Completed</NavLink>
                    <Nav.Link className="nav-link ms-md-4" to="#Live">Live</Nav.Link>
                    <Nav.Link className='ms-md-4' to="#">Upcoming</Nav.Link>
                </div>
            </div> */}

            <div className='overflow-xl-hidden'>
                <h2 className='h1 mb-20'>Explore</h2>
                
                <Row className='gx-2 gx-md-3' xs={1} sm={2} md={3} lg={2} xl={3}>
                {!endtime ? <>
                    <center><CircularProgress color="inherit" /></center>
                </> : <>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontalWv size='sm' text="Phase 1" phase="1" date={`End Date: ${endtime}`} description={desc}/>
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontalWv size='sm' text="Phase 2" phase="2" date="Coming Soon..." description={desc}/>
                    </Col>
                    <Col className='mb-md-3 mb-2'>
                        <CardViewHorizontalWv size='sm' text="Phase 3" phase="3" date="Coming Soon..." description={desc}/>
                    </Col>
                    </>}
                </Row>
            </div>
        </>
     );
}

export default NFTLaunchpadDe;